import React from "react"
import { Redirect } from "react-router-dom"

//Dashboard
import Dashboard from "../pages/Dashboard/index";

// Calendar
import Calendar from "../pages/Calendar/index";

//Chat 
import Chat from "../pages/Chat/Chat";

//Email 
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

//Invoice
import InvoicesList from "../pages/Invoices/invoices-list";
import InvoiceDetail from "../pages/Invoices/invoices-detail"

//Contact
import ContactsGrid from "../pages/Contacts/contacts-grid";
import ContactsList from "../pages/Contacts/ContactList/contacts-list";
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile";

//Utility
import PagesStarter from "../pages/Utility/StarterPage";
import PageMaintenance from "../pages/Utility/PageMaintenance";
import PagesComingsoon from "../pages/Utility/PageComingsoon";
import PageTimeline from "../pages/Utility/PageTimeline";
import PageFaqs from "../pages/Utility/PageFaqs";
import PagePricing from "../pages/Utility/PagePricing/index";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// Ui Components
import UiAlert from "../pages/UiElements/UiAlert";
import UiButton from "../pages/UiElements/UiButton";
import UiCard from "../pages/UiElements/UiCard";
import UiCarousel from "../pages/UiElements/UiCarousel";
import UiDropdowns from "../pages/UiElements/UiDropdowns";
import UiGrid from "../pages/UiElements/UiGrid";
import UiImages from "../pages/UiElements/UiImages";
import UiModal from "../pages/UiElements/UiModals";
import UiDrawer from "../pages/UiElements/UiDrawer";
import UiProgressbar from "../pages/UiElements/UiProgressbar";
import UiTabsAccordions from "../pages/UiElements/UiTabsAccordions";
import UiTypography from "../pages/UiElements/UiTypography";
import UiVideo from "../pages/UiElements/UiVideo";
import UiGeneral from "../pages/UiElements/UiGeneral";
import UiColors from "../pages/UiElements/UiColors";

//Extended pages
import Lightbox from "../pages/Extended/Lightbox";
import Rangeslider from "../pages/Extended/Rangeslider";
import SweetAlert from "../pages/Extended/SweetAlert";
import SessionTimeout from "../pages/Extended/SessionTimeout";
import UiRating from "../pages/Extended/UiRating";
import Notifications from "../pages/Extended/Notifications";

//Forms
import FormElements from "../pages/Forms/FormElements/index";
import FormValidation from "../pages/Forms/FormValidation/";
import AdvancedPlugins from "../pages/Forms/AdvancedPlugins";
import FormEditors from "../pages/Forms/FormEditors";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormMask from "../pages/Forms/FormMask";

//Tables
import BasicTable from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

//Charts
import Apexchart from "../pages/Charts/Apexcharts";
import EChart from "../pages/Charts/EChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import KnobCharts from "../pages/Charts/KnobCharts";
import SparklineChart from "../pages/Charts/SparklineChart";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconFontawesome from "../pages/Icons/IconFontawesome";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"
import MapsLeaflet from "../pages/Maps/MapsLeaflet"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import RegisterSucces from "../pages/Authentication/RegisterCheck"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import GoToEmail from "../pages/Authentication/GoToEmail"

//AuthenticationInner related pages
import PageLogin from "../pages/AuthenticationInner/PageLogin";
import PageRegister from "../pages/AuthenticationInner/PageRegister";
import RecoverPassword from "../pages/AuthenticationInner/RecoverPassword";
import LockScreen from "../pages/AuthenticationInner/LockScreen";
import ConfirmMail from "../pages/AuthenticationInner/ConfirmMail";
import EmailVerification from "../pages/AuthenticationInner/EmailVerification";
import TwoStepVerfication from "../pages/AuthenticationInner/TwoStepVerfication";
import userProfile from "../pages/Authentication/user-profile";
import UserProfile from "../pages/Contacts/ContactsProfile/userProfile";
import ProfileTab from "../pages/Contacts/ContactsProfile/ProfileTab";

import RegisterCheck from "../pages/Authentication/RegisterCheck";
import RegisterSuccess from "../pages/Authentication/RegisterSuccess";
import Dashboard2 from "../pages/Dashboard/Dashboard2";
import Testlogin from "../pages/Authentication/Testlogin";
import CheckForgotPassToken from "../pages/Authentication/CheckForgotPassToken";
import ProfileMenu from "../components/CommonForBoth/TopbarDropdown/ProfileMenu";
// import Customer from "../pages/Features/Customer/Auditee";
import UserList from "../pages/Features/userList/UserList";
import CheckList from "../pages/Features/CheckList/CheckList";
import CheckListTemplate from "../pages/Features/CheckList/CheckListTemplate";
import CheckListReport from "../pages/Features/CheckList/CheckListReport";
import CheckListCertificate from "../pages/Features/CheckList/CheckListCertificate";
import AddUsers from "../pages/Features/Users/AddUsers";
import Project from "../pages/Features/projects/Project";
import Auditee from "../pages/Features/Auditee/Auditee";
import Audit from "../pages/Features/projects/Audit";
import Temp from "../pages/Features/projects/Temp";
import Audit_report_certificate from "../pages/Features/projects/Audit_report_certificate";


const adminRoutes = [
  { path: "/dashboard", component: Dashboard2 },
  // { path: "/", exact: true, component: () => <Redirect to="/login" /> },
  //profile
  { path: "/myprofile", component: UserProfile },

  // Features
  // { path: "/auditee", component: Auditee },
  // { path: "/checklist", component: CheckList },
  { path: "/users", component: UserList },
  { path: "/project", component: Project },

  // users Details 
  // { path: "/auditee/users/:organizationId" , component: UserList },
  // { path: "/checklist/template/:organizationId", component: CheckListTemplate },
  // { path: "/checklist/report/:organizationId", component: CheckListReport },
  // { path: "/checklist/certificate/:organizationId", component: CheckListCertificate },
  // { path: "/project/:projectID", component: Audit },
  { path: "/project/:projectID", component: Audit_report_certificate },
  { path: "/report/:projectID", component: Audit_report_certificate },
  { path: "/certificate/:projectID", component: Audit_report_certificate },
  { path: "/login", component: () => <Redirect to="/project" /> },
  { path: "/", exact: true, component: () => <Redirect to="/project" /> },
  // { path : "*" ,  component: () => <Redirect to="/auditor/dashboard" />}
]


const finance = [
  { path: "/dashboard", component: Dashboard2 },
  // { path: "/", exact: true, component: () => <Redirect to="/login" /> },
  //profile
  { path: "/myprofile", component: UserProfile },

  // Features
  // { path: "/auditee", component: Auditee },
  // { path: "/checklist", component: CheckList },
  { path: "/project", component: Project },
  // { path: "/users", component: AddUsers },

  // users Details 
  // { path: "/auditee/users/:organizationId" , component: UserList },
  // { path: "/checklist/template/:organizationId", component: CheckListTemplate },
  // { path: "/checklist/report/:organizationId", component: CheckListReport },
  // { path: "/checklist/certificate/:organizationId", component: CheckListCertificate },
  // { path: "/project/:projectID", component: Audit },
  { path: "/project/:projectID", component: Audit_report_certificate },
  { path: "/report/:projectID", component: Audit_report_certificate },
  { path: "/certificate/:projectID", component: Audit_report_certificate },
  { path: "/login", component: () => <Redirect to="/project" /> },
  { path: "/", exact: true, component: () => <Redirect to="/project" /> },
  // { path : "*" ,  component: () => <Redirect to="/auditor/dashboard" />}
]


const technical = [
  { path: "/dashboard", component: Dashboard2 },
  // { path: "/", exact: true, component: () => <Redirect to="/login" /> },
  //profile
  { path: "/myprofile", component: UserProfile },

  // Features
  // { path: "/auditee", component: Auditee },
  // { path: "/checklist", component: CheckList },
  { path: "/project", component: Project },
  // { path: "/users", component: AddUsers },

  // users Details 
  // { path: "/auditee/users/:organizationId" , component: UserList },
  // { path: "/checklist/template/:organizationId", component: CheckListTemplate },
  // { path: "/checklist/report/:organizationId", component: CheckListReport },
  // { path: "/checklist/certificate/:organizationId", component: CheckListCertificate },
  // { path: "/project/:projectID", component: Audit },
  { path: "/project/:projectID", component: Audit_report_certificate },
  { path: "/report/:projectID", component: Audit_report_certificate },
  { path: "/certificate/:projectID", component: Audit_report_certificate },
  { path: "/login", component: () => <Redirect to="/project" /> },
  { path: "/", exact: true, component: () => <Redirect to="/project" /> },
  // { path : "*" ,  component: () => <Redirect to="/auditor/dashboard" />}
]


const procurement = [
  { path: "/dashboard", component: Dashboard2 },
  // { path: "/", exact: true, component: () => <Redirect to="/login" /> },
  //profile
  { path: "/myprofile", component: UserProfile },

  // Features
  // { path: "/auditee", component: Auditee },
  // { path: "/checklist", component: CheckList },
  { path: "/project", component: Project },
  // { path: "/users", component: AddUsers },

  // users Details 
  // { path: "/auditee/users/:organizationId" , component: UserList },
  // { path: "/checklist/template/:organizationId", component: CheckListTemplate },
  // { path: "/checklist/report/:organizationId", component: CheckListReport },
  // { path: "/checklist/certificate/:organizationId", component: CheckListCertificate },
  // { path: "/project/:projectID", component: Audit },
  { path: "/project/:projectID", component: Audit_report_certificate },
  { path: "/report/:projectID", component: Audit_report_certificate },
  { path: "/certificate/:projectID", component: Audit_report_certificate },
  { path: "/login", component: () => <Redirect to="/project" /> },
  { path: "/", exact: true, component: () => <Redirect to="/project" /> },
  // { path : "*" ,  component: () => <Redirect to="/auditor/dashboard" />}
]


const OtherRoutes = [
  { path: "/login", component: Login },
  { path: "/dashboard", exact: true, component: () => <Redirect to="/login" /> },
  { path: "/", component: () => <Redirect to="/login" /> },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/account-verify-message", component: RegisterSucces },
  { path: "/account-password-reset/:resettoken", component: CheckForgotPassToken },
]


export { adminRoutes, procurement, finance, technical, OtherRoutes, ForgetPwd, RegisterSucces, CheckForgotPassToken }
