
import React, { useState, useEffect } from "react";
import { BsFileEarmarkPdf } from 'react-icons/bs';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import CountUp from "react-countup";


import FeatherIcon from "feather-icons-react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import "react-table/react-table.css";
import {
  Badge,
  CardBody,
  Col,
  Modal,
  Row,
  Card,
  Input,
  Button,
  Form, Label, CardHeader,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select"
import { AvForm, AvField } from "availity-reactstrap-validation";

import MetaTags from "react-meta-tags";
import swal from 'sweetalert';
import axios from "axios"


//import images
import Breadcrumbs from "../../../components/Common/Breadcrumb";


import { CKEditor } from '@ckeditor/ckeditor5-react';
// import { Lines } from "react-preloaders"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Loader from "./Loader";
const Certificate = (props) => {
  useEffect(() => {
    console.log("I called of report")
    getUser()
    handle_get_report_data()
  }, [])
  const [productData, setProductData] = useState([])
  const [role, setRole] = useState()
  // to get userLSit 
  async function getUser(event) {
    // event.preventDefault();
    // console.log("token passes " , userCheck)
    const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}user-profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      },
      body: JSON.stringify({
        userCheck:  localStorage.getItem("AuditorID"),
        orgID: localStorage.getItem("orgID")
      }),
    });
    const data = await response.json();
    if (data) {
      const newdata = data.user;
      // console.log("data retrived", newdata);
      setRole(newdata.Role)

      // setphone(newdata.Phone);
      // setjobTitle(newdata.JobTitle);
      // console.log("main profile", newdata.FirstName);
    } else {
      alert("data retreival error");
    }
  }
  // Columns of DataTable
  const columns = [
    {
      dataField: "CertificateID",
      text: "CertificateID ",
      sort: true,
      formatter: (cellContent, productData) => (
        <>
          {(() => {
            if (productData.CertificateID === null) {
              return <>-</>;
            } else {
              return <>
                <Badge className="me-2 bg-primary p-1 fs-6">
                  {productData.CertificateID}
                </Badge>
              </>;
            }
          })()}
        </>
      ),
    },
    {
      dataField: "CreatedByName",
      text: "Created By",
      sort: true,
      formatter: (cellContent, productData) => (
        <>
          {(() => {
            if (productData.CreatedByName === null) {
              return <>-</>;
            } else {
              return <>{productData.CreatedByName}</>;
            }
          })()}
        </>
      ),
    },
    {
      dataField: "ReviewedByName",
      text: "Reviewed By ",
      sort: true,
      formatter: (cellContent, productData) => (
        <>
          {(() => {
            if (productData.ReviewedByName === null) {
              return <>-</>;
            } else {
              return <>{productData.ReviewedByName}</>;
            }
          })()}
        </>
      ),
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      formatter: (cellContent, productData) => (
        <>
          {(() => {
            if (productData.Status === null) {
              return <>-</>;
            } else if (productData.Status === "Approved") {
              return <> <Badge pill className="badge-soft-success ms-1  fs-6">{productData.Status}</Badge></>;
            } else if (productData.Status === "Rejected") {
              return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{productData.Status}</Badge>

              </>;
            } else if (productData.Status === "Not Checked") {
              return <> <Badge pill className="badge-soft-info ms-1  fs-6">{productData.Status}</Badge>

              </>;
            } else if (productData.Status === "Draft") {
              return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{productData.Status}</Badge>

              </>;
            }
          })()}
        </>
      ),
    },
    
    {
      dataField: "Date",
      text: "Date",
      sort: true,
      formatter: (cellContent, productData) => (
        <>
          {(() => {
            if (productData.Date === null) {
              return <>-</>;
            } else {
              
            const dateString = productData.Date;
            const date = new Date(dateString);
            const formattedDate = date.toLocaleDateString("en-US", { day: "numeric", month: "long", year: "numeric" });
              return <>{formattedDate} </>;
            }
          })()}
        </>
      ),
    },
    {
      dataField: "View More",
      text: "View More ",
      sort: true,
      formatter: (cellContent, productData) => (
        <>
          <Button
            color="primary"
            className="btn-sm me-2"
            // disabled={role === "Admin" ? false : true}
            onClick={() => {
              set_generate_pdf_loader(true)
              tog_standard_generate_report()
              handle_generate_certificate(productData.CertificateID)
              setCertificateID(productData.CertificateID)
              setcreatedBy(productData.CreatedBy)
            }}
          >
            <BsFileEarmarkPdf className="text-white fs-2 pe-2" />  View Report
          </Button>
        </>
      ),
    },
  ];
  // Sorting of Data Table
  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  // Page Options in Data Table 
  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };
  const { SearchBar } = Search; // Search Bar in Data TAble


  const auditorID = localStorage.getItem("AuditorID");
  const projectID = props.projectID
  const handle_get_report_data = async () => {
    const reportData = await fetch(`${process.env.REACT_APP_DEFAULTPATH}get-auditor-project-certificate-data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      },
      body: JSON.stringify({
        auditorID, projectID,
        userCheck:  localStorage.getItem("AuditorID"),
        orgID: localStorage.getItem("orgID")
      }),
    })
    const data = await reportData.json();
    console.log("data", data)
    setProductData(data.data)
  }

  const [report_generated, set_report_generated] = useState(false)
  const [generate_pdf_loader, set_generate_pdf_loader] = useState(false)
  const [updated_blob, set_updated_blob] = useState()
  const [modal_generate_report, setmodal_generate_report] = useState(false); // View More button Modal of ExposedCredentials
  function tog_standard_generate_report() {
    setmodal_generate_report(!modal_generate_report);
    // toggle for view more button 
  }
  const handle_generate_certificate = (certificateID) => {
    console.log("certificateID", certificateID)
    set_report_generated(false)
    axios.post(`${process.env.REACT_APP_DEFAULTPATH}create-auditor-project-audit-generate-certificate`, { projectID,
      userCheck:  localStorage.getItem("AuditorID"),
      orgID: localStorage.getItem("orgID"), certificateID }, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf',
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then((res) => {
        set_generate_pdf_loader(false)
        console.log(res.data)
        set_report_generated(true)
        const blob = new Blob([res.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        var link_href = URL.createObjectURL(blob)
        set_updated_blob(link_href)
        // window.open(link_href , "_blank");
      })
  }
  const [certificateID, setCertificateID] = useState()
  const [createdBy, setcreatedBy] = useState()

  const handle_edit_certificate_table = async (status) => {
    console.log("auditorID", auditorID)
    const certificateData = await fetch(`${process.env.REACT_APP_DEFAULTPATH}edit-auditor-project-certificate-table`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auditorID, projectID, certificateID, status, createdBy
      }),
    })
    const data = await certificateData.json();
    if (data.status) {
      await handle_get_report_data()
    }
  }
  return (
    <>
      {/* TAble  */}
      <Row className="content">
        <Col
          lg={4}
          md={5}
          className="col-xxl-9 userData credentialData"
        >
          <div className="auth-full-page-content d-flex p-sm-5 p-4">
            <div className="w-100">
              <div className="d-flex flex-column">
                <div className="  text-center">
                  <Link
                    to="/dashboard"
                    className="d-block auth-logo"
                  >
                  </Link>
                </div>
                <div className="auth-content my-auto">

                  <div className="mainTable">
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory(
                          pageOptions
                        )}
                      >
                        {({
                          paginationProps,
                          paginationTableProps,
                        }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={productData}
                            search
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col>
                                    <div className="search-box me-2 mb-2 d-flex justify-content-between">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={
                                          defaultSorted
                                        }
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={
                                          "thead-light"
                                        }
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>

                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/* </Row> */}


      {/* Generate PDf Modal  */}
      <Modal
        isOpen={modal_generate_report}
        toggle={() => {
          tog_standard_generate_report();
        }}
        className="modal-xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Generated Report
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_generate_report(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>


        <div className="modal-body d-flex justify-content-center align-items-center"

          style={{ minHeight: "80vh" }}>
          {report_generated ? <iframe id="displayZone" src={updated_blob} type="application/pdf" style={{ width: "100%", height: "80vh" }}></iframe> : <div className=""><Loader /></div>}
        </div>
        <div className="modal-footer">


          {(() => {
            console.log("role", role)
            if (role != "Auditor") {
              return (<>
                {/* <button
                  type="button"
                  onClick={() => {
                    tog_standard_generate_report();
                    setmodal_generate_report(false);
                    handle_edit_certificate_table("Approved")
                  }}
                  className="btn btn-success "
                  data-dismiss="modal"
                >
                  Approve
                </button>
                <button
                  type="button"
                  onClick={() => {
                    tog_standard_generate_report();
                    setmodal_generate_report(false);
                    handle_edit_certificate_table("Rejected")
                  }}
                  className="btn btn-danger "
                  data-dismiss="modal"
                >
                  Reject
                </button> */}
              </>)
            }
            else {
              return (<button
                type="button"
                onClick={() => {
                  // tog_standard_generate_report();
                  // setmodal_generate_report(false);
                }}
                className="btn btn-success "
                data-dismiss="modal"
              >
                Close
              </button>)
            }
          })()}
        </div>
      </Modal>
    </>
  )
}

export default Certificate