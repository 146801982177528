import React, { useState, useEffect, useRef } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import CountUp from "react-countup";

import Carousel_OpenPOC from "./Carousel_OpenPOC";
import Dropzone from "react-dropzone"

import { Circle2 } from "react-preloaders2";
import FeatherIcon from "feather-icons-react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import "react-table/react-table.css";
import {
    Badge,
    CardBody,
    ModalBody,
    Col,
    Modal,
    Row,
    Card,
    Spinner,
    Input,
    Button,
    Form, Label, CardHeader,
    CardTitle,
    CardSubtitle,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select"
import { AvForm, AvField } from "availity-reactstrap-validation";

import MetaTags from "react-meta-tags";
import swal from 'sweetalert';
import axios from "axios"

//import images


import { CKEditor } from '@ckeditor/ckeditor5-react';
// import {AiOutlineSafetyCertificate} from "react-icons/ai"
// import { Lines } from "react-preloaders"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Loader from "./Loader";
import OpenPocDropZone from "./OpenPocDropZone";
import ClosedPocDropZone from "./ClosedPocDropzone";
const Audit = (props) => {


    const OpenPOCdropzoneRef = useRef(null) // openPOC dropzone is inside ref hooks to get child data at parent function
    const ClosedPOCdropzoneRef = useRef(null)

    const [AuditlistPreloader, setAuditlistPreloader] = useState(true)
    const [startAuditPreloader, setstartAuditPreloader] = useState(false)
    const [startAuditPreloader2, setstartAuditPreloader2] = useState(false)
    const [productData, setProductData] = useState([]);
    const [modal_standard4, setmodal_standard4] = useState(false); // View More button Modal of 
    // const [oldPOCimages, setoldPOCimages] = useState([])

    const [modal_add_auditee, setmodal_add_auditee] = useState(false); // View More button Modal of 
    const [modal_generate_report, setmodal_generate_report] = useState(false); // View More button Modal of 

    const [orgNum, setOrgNum] = useState(0);
    // const [activeOrg, setActiveOrg] = useState(0);
    // const [InActiveOrg, setInActiveOrg] = useState(0);
    const [updated_blob, set_updated_blob] = useState()


    // values stored of form
    // const [OrgName, setOrgName] = useState("");
    // const [status, setstatus] = useState("");

    // validation state error 
    const [emailError, setemailError] = useState("");
    const [OrgNameError, setOrgNameError] = useState("");
    const [OrganizaionID, setOrganizaionID] = useState("");
    const [UserCount, setUserCount] = useState("");
    const [formOrganizationID, setformOrganizationID] = useState(""); // used to send userID of row to backend
    const [detailBtn, setdetailBtn] = useState(true); // to disable the button on inital


    // function to get uniques ID
    function uniqueIdGenerator() {
        var seq = (Math.floor(Math.random() * 100) + 100).toString().substring(1);
        const id = Date.now() + seq;
        return id;
    }
    const userCheck = localStorage.getItem("AuditorID");
    const projectID = props.projectID
    // to store userId from path 

    const [reportID, setReportID] = useState(localStorage.getItem("reportId"));
    const [findvulnerability, setfindvulnerability] = useState();
    const [rating, setrating] = useState()
    const [vulnerabilityDescription, setvulnerabilityDescription] = useState();
    const [vulnerabilityImpact, setvulnerabilityImpact] = useState();
    const [vulnerabilityRemediation, setvulnerabilityRemediation] = useState();
    const [vulnerabilityAffectedURL, setvulnerabilityAffectedURL] = useState();
    // const [vulnerabilityAffectedURL , setvulnerabilityAffectedURL] =  useState();
    const [vulnerabilityImg1, setvulnerabilityImg1] = useState();
    const [vulnerabilityList, setVulnerabilityList] = useState();


    const [generate_pdf_loader, set_generate_pdf_loader] = useState(false)



    const [Title, setTitle] = useState();
    const [Severity, setSeverity] = useState();
    // const [empSize, setempSize] = useState();
    const [CheckListTag, setCheckListTag] = useState();
    const [description, setdescription] = useState();
    const [impact, setimpact] = useState();
    const [remediation, setremediation] = useState();
    const [reference, setreference] = useState();
    const [checkListTemplateStatus, setcheckListTemplateStatus] = useState();
    const [pincode, setpincode] = useState();
    const [otherDetails, setotherDetails] = useState();
    const [CheckListTemplateID, setCheckListTemplateID] = useState();

    const [status_empSize, setstatus_empSize] = useState("");
    const [view_more_modal, setview_more_modal] = useState(false);
    const [auditor_review_modal, setauditor_review_modal] = useState(false);
    const [team_leader_review_modal, setteam_leader_review_modal] = useState(false);
    const [statusCountry, setstatusCountry] = useState("");
    const [companyStatus, setcompanyStatus] = useState();
    const [checklistType, setchecklistType] = useState();

    const [preSelectedfiles, setpreSelectedfiles] = useState([])
    // const [closedPOC_des, setopenPOC_des] = useState();

    // open POC file State 
    const [openPOC_file_one, setopenPOC_File_one] = useState(null)
    const [openPOC_file_two, setopenPOC_File_two] = useState(null)
    const [openPOC_file_three, setopenPOC_File_three] = useState(null)
    const [openPOC_file_four, setopenPOC_File_four] = useState(null)
    const [openPOC_file_five, setopenPOC_File_five] = useState(null)

    const [openPOC_file_List, setopenPOC_file_List] = useState([])
    const [closedPOC_file_List, setclosedPOC_file_List] = useState([])

    // Opne POC description state 
    const [openPOC_desc_one, setopenPOC_desc_one] = useState(null)
    const [openPOC_desc_two, setopenPOC_desc_two] = useState(null)
    const [openPOC_desc_three, setopenPOC_desc_three] = useState(null)
    const [openPOC_desc_four, setopenPOC_desc_four] = useState(null)
    const [openPOC_desc_five, setopenPOC_desc_five] = useState(null)

    const [openPOC_desc_List, setopenPOC_desc_List] = useState([])
    const [closedPOC_desc_List, setclosedPOC_desc_List] = useState([])


    // Close POC file State 
    const [closedPOC_file_one, setclosedPOC_File_one] = useState(null)
    const [closedPOC_file_two, setclosedPOC_File_two] = useState(null)
    const [closedPOC_file_three, setclosedPOC_File_three] = useState(null)
    const [closedPOC_file_four, setclosedPOC_File_four] = useState(null)
    const [closedPOC_file_five, setclosedPOC_File_five] = useState(null)


    // Close POC description state 
    const [closedPOC_desc_one, setclosedPOC_desc_one] = useState(null)
    const [closedPOC_desc_two, setclosedPOC_desc_two] = useState(null)
    const [closedPOC_desc_three, setclosedPOC_desc_three] = useState(null)
    const [closedPOC_desc_four, setclosedPOC_desc_four] = useState(null)
    const [closedPOC_desc_five, setclosedPOC_desc_five] = useState(null)

    const [report_generated, set_report_generated] = useState(false)
    const [printOpenPOC, set_printOpenPOC] = useState([])
    const [printClosePOC, set_printClosePOC] = useState([])
    const [CVSS_vector, setCVSS_vector] = useState()


    const [currentCarouseIndex, setCurrentCarouseIndex] = useState(0)
    const [ImageArr, setImageArr] = useState(0)
    const [openPOC_file_one_name, setopenPOC_File_one_name] = useState("")

    const [carousel_modal, setcarousel_modal] = useState(false)
    function toggle_carousel_modal() {
        setcarousel_modal(!carousel_modal);
        // toggle for view more button 
    }
    const [openPOC_file, setopenPOC_file] = useState([])

    const [status_vulnerability_status, setstatus_vulnerability_status] = useState("");
    const [selectedOptions_vulnerability_status, setselectedOptions_vulnerability_status] = useState(null);


    const optionGroup_severity = [
        { label: "Critical", value: "Critical" },
        { label: "High", value: "High" },
        { label: "Medium", value: "Medium" },
        { label: "Low", value: "Low" },
        { label: "Informational", value: "Informational" }
    ];

    const optionGroup_country = [
        { label: "India", value: "India" },
        { label: "USA", value: "USA" },
        { label: "Medium", value: "Medium" },
        { label: "Low", value: "Low" },
        { label: "Informational", value: "Informational" }
    ];

    const optionGroup_checklIstType = [
        { label: "Technical Audit ", value: "Technical Audit " },
        { label: "Casual", value: "Inactive" },
    ];
    const optionGroup_status = [
        { label: "Open", value: "Open" },
        { label: "Closed", value: "Closed" },
        { label: "Not Applicable", value: "Not Applicable" },
    ];
    const optionGroup_CompanyStatus = [
        { label: "Medium", value: "Medium" },
        { label: "Low", value: "Low" },
        { label: "Informational", value: "Informational" }
    ];

    // form validatioon checkuo end ------------->>>>>>>>>>>>>>>>>>>>>>>>>>

    // function to handle change in status dropdown
    //  function handleSelectOptions_empSize(selectedOptions_empSize) {
    //     setselectedOptions_empSize(selectedOptions_empSize);
    // }

    // function to handle change in status dropdown
    function handleSelectOptions_severity(selectedOptions_severity) {
        // setselectedOptions_severity(selectedOptions_severity);
    }
    // function to handle change in status dropdown
    function handleSelectOptions_vulnerability_status(selectedOptions_vulnerability_status) {
        setselectedOptions_vulnerability_status(selectedOptions_vulnerability_status);
    }


    async function addVulnerabilities() {
        var formData = new FormData();
        formData.append("Title", Title);
        // formData.append("status_severity", status_severity);
        formData.append("Severity", Severity);
        formData.append("CheckListTag", CheckListTag);
        formData.append("description", description);
        formData.append("impact", impact);
        formData.append("remediation", remediation);
        formData.append("reference", reference);
        formData.append("projectID", projectID);
        formData.append("checkListTemplateStatus", checkListTemplateStatus);
        formData.append("checklistType", checklistType);
        // formData.append("description", description);
        // formData.append("description", description);

        formData.append("auditorID", userCheck)
        // formData.append("status_vulnerability_status", status_vulnerability_status);
        // formData.append("vulnerabilityImg1", vulnerabilityImg1[0], "vulnerability_status_img.png");
        // console.log(checkListName,checkListType , checkListYear , checkLIstStatus)

        try {
            // const response = await fetch('http://localhost:1338/add-auditee', {
            //     method : "POST",
            //     headers : {
            //         "content-Type" : "application/json"
            //     },
            //     body:formData
            // })
            await axios.post(`${process.env.REACT_APP_DEFAULTPATH}add-auditor-checklist-template`, formData).
                then(() => {
                    getCustomers()
                    setTitle(" ")
                    setSeverity(null);
                    setCheckListTag(" ")
                    setdescription(" ")
                    setimpact(" ")
                    setremediation(" ")
                    setreference(" ")
                    // tog_standard_add_auditee()
                    // tog_standard_add_auditee()
                    // window.location.reload();
                });

        } catch (error) {
            console.log(error)
        }
    }

    // Team - LEader function to approve and reject checklist using view more for team leader
    async function changeChecklistStatus(title, p_severity, p_tags, p_vul_status, p_desc, p_remed, p_impact, p_affects, p_vul_id, p_apr_status) {
        // console.log("titile ", checkListTemplateStatus);
        // console.log("titile ", CheckListTag);
        // console.log("titile ", ApproveStatus);
        // console.log("titile ", remediation);
        // console.log("titile ", impact);
        // console.log("titile ", vulnerabilityID);
        // console.log("titile ", description);

        await fetch(`${process.env.REACT_APP_DEFAULTPATH}edit-auditor-project-audit-vulnerability-status`, {
            method: "POST",
            headers: {
                "Content-Type": "application/Json"
            },
            body: JSON.stringify({
                userCheck,
                projectID,
                Title: title,
                Severity: p_severity,
                checkListTemplateStatus: p_vul_status,
                CheckListTag: p_tags,
                ApproveStatus: p_apr_status,
                impact: p_impact,
                affects: p_affects,
                remediation: p_remed,
                vulnerabilityID: p_vul_id,
                description: p_desc
            })
        }).then((res) => res.json())
            .then((data) => {
                // console.log("ssave data", data)
                // getChecklistForAudit()
                // setteam_leader_review_modal(false)
                getChecklistForAudit()
                // toggle_team_leader_review_modal()
                if (data.status) {
                    console.log("notchecked fatda", notCheckedList)
                    const incremented_Index = globalIndex + 1;
                    setglobalIndex(incremented_Index > notCheckedList.length ? setNotChekedList([]) : incremented_Index)
                    if (notCheckedList[globalIndex]) {
                        setTitle(notCheckedList[globalIndex].Title)
                        setSeverity(notCheckedList[globalIndex].Severity)
                        setcheckListTemplateStatus(notCheckedList[globalIndex].VulnerabilityStatus)
                        setCheckListTag(notCheckedList[globalIndex].Tags)
                        setdescription(notCheckedList[globalIndex].Description)
                        setimpact(notCheckedList[globalIndex].Impact)
                        setremediation(notCheckedList[globalIndex].Remediation)
                        setvulnerabilityID(notCheckedList[globalIndex].VulnerabilityID)
                        setLable_status(notCheckedList[globalIndex].Status)
                        setcheckListID(notCheckedList[globalIndex].CheckListID)
                        setModifierName(notCheckedList[globalIndex].Modifier_Name)
                        setteam_leader_review_modal(true)
                        set_printClosePOC(notCheckedList[globalIndex].ClosedPOC)
                        set_printOpenPOC(notCheckedList[globalIndex].OpenPOC)
                    }
                }
                // setTitle(notCheckedList[globalIndex].Title)
                // setSeverity(notCheckedList[globalIndex].Severity)
                // setcheckListTemplateStatus(notCheckedList[globalIndex].VulnerabilityStatus)
                // setCheckListTag(notCheckedList[globalIndex].Tags)
                // setdescription(notCheckedList[globalIndex].Description)
                // setimpact(notCheckedList[globalIndex].Impact)
                // setremediation(notCheckedList[globalIndex].Remediation)
                // setvulnerabilityID(notCheckedList[globalIndex].VulnerabilityID)
                // setLable_status(notCheckedList[globalIndex].Status)
                // setcheckListID(notCheckedList[globalIndex].CheckListID)
                // setModifierName(notCheckedList[globalIndex].Modifier_Name)
                // setteam_leader_review_modal(true);
            })
    }


    // Swwet alert or modal to confirm detials  should modify for perosnal Details 
    function changeChecklistStatus_sweetAlert(title, p_severity, p_tags, p_vul_status, p_desc, p_remed, p_impact, p_affects, p_vul_id, p_apr_status) {
        // console.log("selected GRoup", status)
        swal({
            title: "Are you sure?",
            text: "Once done, you will update your personal details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    setview_more_modal(false)
                    if (changeChecklistStatus(title, p_severity, p_tags, p_vul_status, p_desc, p_remed, p_impact, p_affects, p_vul_id, p_apr_status)) {
                        swal("Poof! Your perosnal details has been updated!", {
                            icon: "success",
                            buttons: true
                        }).then((reload) => {
                            if (reload) {
                                getChecklistForAudit()
                                // window.location.reload(false);
                                // getCustomers()
                            }
                        })
                    }
                    else {
                        swal("Reload operation has been aborted!");
                    }
                } else {
                    swal("Operation has been aborted!");
                }
            });
    }



    const [selectedOptions, setselectedOptions] = useState(null)
    const [start_audit, setStart_audit] = useState(false)
    // const animatedComponents = makeAnimated()
    // const orgID = localStorage.getItem("orgID");
    const token = localStorage.getItem("token");
    const [Lable_status, setLable_status] = useState();

    const optionGroup = [
        { label: "Active", value: "Active" },
        { label: "InActive", value: "InActive" },
    ]
    // Columns of DataTable
    const columns = [
        {
            dataField: "VulnerabilityID",
            text: "VulnerabilityID ",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.VulnerabilityID === null) {
                            return <>-</>;
                        } else {
                            return <>
                                <Badge className="me-2 bg-primary p-1 fs-6">
                                    {productData.VulnerabilityID}
                                </Badge>
                            </>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Title",
            text: "Title ",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Title === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.Title}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Severity",
            text: "Severity",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Severity === null) {
                            return <>-</>;
                        } else if (productData.Severity === "Critical") {
                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#ff0000" }}>{productData.Severity}</Badge></>;
                        } else if (productData.Severity === "High") {
                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#dc3545" }}>{productData.Severity}</Badge></>;
                        } else if (productData.Severity === "Low") {
                            return <> <Badge pill className="me-2  ms-1  fs-6" style={{ backgroundColor: "#198754" }}>{productData.Severity}</Badge></>;
                        } else if (productData.Severity === "Medium") {
                            return <> <Badge pill className="me-2  ms-1 fs-6 " style={{ backgroundColor: "#ffc107" }}>{productData.Severity}</Badge></>;
                        } else if (productData.Severity === "Informational") {
                            return <> <Badge pill className="me-2  ms-1 fs-6 " style={{ backgroundColor: "#0dcaf0" }}>{productData.Severity}</Badge>

                            </>;
                        }
                    })()}
                </>
            ),
        },
        // {
        //     dataField: "Tags",
        //     text: "Tags ",
        //     sort: true,
        //     formatter: (cellContent, productData) => (
        //         <>
        //             {(() => {
        //                 if (productData.Tags === null) {
        //                     return <>-</>;
        //                 } else {
        //                     return <>  <Badge className="me-2 bg-primary p-1">
        //                         {productData.Tags}
        //                     </Badge></>;
        //                 }
        //             })()}
        //         </>
        //     ),
        // },
        {
            dataField: "Vulnerability Status",
            text: "Vulnerability Status ",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.VulnerabilityStatus === null) {
                            return <>-</>;
                        } else if (productData.VulnerabilityStatus === "Open") {
                            return <> <Badge pill className="badge-soft-success ms-1  fs-6">{productData.VulnerabilityStatus}</Badge></>;
                        } else if (productData.VulnerabilityStatus === "Closed") {
                            return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{productData.VulnerabilityStatus}</Badge>

                            </>;
                        } else if (productData.VulnerabilityStatus === "Not Applicable") {
                            return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{productData.VulnerabilityStatus}</Badge>

                            </>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Status",
            text: "Status ",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Status === null) {
                            return <>-</>;
                        } else if (productData.Status === "Approved") {
                            return <> <Badge pill className="badge-soft-success ms-1  fs-6">{productData.Status}</Badge></>;
                        } else if (productData.Status === "Rejected") {
                            return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{productData.Status}</Badge>

                            </>;
                        } else if (productData.Status === "Not Checked") {
                            return <> <Badge pill className="badge-soft-info ms-1  fs-6">{productData.Status}</Badge>

                            </>;
                        } else if (productData.Status === "Draft") {
                            return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{productData.Status}</Badge>

                            </>;
                        }
                    })()}
                </>
            ),
        },
        // {
        //     dataField: "Value",
        //     text: "Users Count",
        //     sort: true,
        //     formatter: (cellContent, productData) => (
        //         <>
        //             {(() => {
        //                 if (productData.UserCount === null) {
        //                     return <>-</>;
        //                 } else {
        //                     return <>
        //                         <td>  <Badge pill className="me-2 bg-danger ">{productData.UserCount.length}</Badge></td></>;
        //                 }
        //             })()}
        //         </>
        //     ),
        // },
        // {
        //     dataField: "Status",
        //     text: "Status",
        //     sort: true,
        //     formatter: (cellContent, productData) => (
        //         <>
        //             {(() => {
        //                 if (productData.Status === null) {
        //                     return <>-</>;
        //                 } else if (productData.Status === "Active") {
        //                     return <> <Badge pill className="badge-soft-success ms-1  ">{productData.Status}</Badge></>;
        //                 } else if (productData.Status === "InActive") {
        //                     return <> <Badge pill className="badge-soft-danger ms-1  ">{productData.Status}</Badge></>;
        //                 }
        //             })()}
        //         </>
        //     ),
        // },
        {
            dataField: "Action",
            text: "Action",
            sort: true,
            formatter: (cellContent, productData) => (

                <>
                    {/* {
                        (() => {
                            if (role === "Admin") {
                                return (
                                    <>
                                        <Button
                                            color="warning"
                                            className="btn-sm me-2"
                                            // disabled={role === "Admin" ? false : true}
                                            onClick={() => {
                                                tog_standard4();
                                                setCheckListTemplateID(productData.CheckID)
                                                setTitle(productData.Title);
                                                setSeverity(productData.Severity);
                                                setCheckListTag(productData.Tags);
                                                setcheckListTemplateStatus(productData.Status);
                                                setdescription(productData.Description);
                                                setimpact(productData.Impact);
                                                setremediation(productData.Remediation);
                                                setreference(productData.Reference);
                                            }}
                                        >
                                            Edit
                                        </Button> 
                                       <Button
                                            color="danger"
                                            className="btn-sm me-2"
                                            // disabled={role === "Admin" ? false : true}
                                            onClick={() => {
                                                setCheckListTemplateID(productData.CheckID)
                                                updatePersonalDetails_sweetAlert(productData.CheckID)
                                                // setcheckListDbId(productData.ChecklistDatabaseID)
                                                // updatePersonalDetails_sweetAlert(productData.ChecklistDatabaseID)
                                                // console.log("cliecekd", [productData]);
                                                // tog_standard4();
                                                // // setmodalPopupData(productData.UserCount)
                                                // setOrganizaionID(productData.OrganizationID)
                                                // setUserCount(productData.UserCount.length)
                                                // setOrgName(productData.OrganizationName)
                                            }}
                                        >
                                            Delete
                                        </Button> 
                                    </>
                                )
                            }
                        })()
                    } */}

                    {(() => {
                        if (role === "Technical" || role === "Finance" || role === "Procurement" || role === "Admin") {
                            return (
                                <Button
                                    color="primary"
                                    className="btn-sm me-2"
                                    // disabled={role === "Admin" ? false : true}
                                    onClick={() => {
                                        console.log("cliecekd", productData);
                                        toggle_view_more_modal();
                                        setSeverity(productData.Severity)
                                        set_printClosePOC(productData.ClosedPOC || [])
                                        set_printOpenPOC(productData.OpenPOC || [])
                                        setCheckListTag(productData.Tags)
                                        setCVSS_vector(productData.CVSS_Vector)
                                        setcheckListTemplateStatus(productData.VulnerabilityStatus)
                                        setdescription(productData.Description)
                                        setremediation(productData.Remediation)
                                        setimpact(productData.Impact)
                                        setTitle(productData.Title)
                                        setreference(productData.Reference)
                                        setvulnerabilityID(productData.VulnerabilityID)
                                        setLable_status(productData.Status)
                                        setcheckListID(productData.CheckListID)
                                        setModifierName(productData.Modifier_Name)
                                        setAffects(productData.Affects)
                                        // setmodalPopupData(productData.UserCount)
                                        // setOrganizaionID(productData.OrganizationID)
                                        // setUserCount(productData.UserCount.length)
                                        // setOrgName(productData.OrganizationName)
                                    }}
                                >

                                    View More

                                </Button>)
                        }
                    })()}
                    {/* {(() => {
                        if (role === "Auditor") {
                            return (
                                <Button
                                    color="warning"
                                    className="btn-sm me-2"
                                    disabled={role === "Auditor" && productData.Status === "Approved" ? true : false}
                                    onClick={() => {
                                        console.log("cliecekd now", productData);
                                        tog_standard4();
                                        setTitle(productData.Title)
                                        setSeverity(productData.Severity)
                                        setCheckListTag(productData.Tags)
                                        setcheckListTemplateStatus(productData.VulnerabilityStatus)
                                        setdescription(productData.Description)
                                        setremediation(productData.Remediation)
                                        setimpact(productData.Impact)
                                        setLable_status(productData.Status)
                                        setvulnerabilityID(productData.VulnerabilityID)
                                        setAffects(productData.Affects)
                                        setpreSelectedfiles(productData)
                                        seteditOldimagesOpenPOC([])
                                        seteditOldimagesClosedPOC([])
                                        setselectedFiles([])
                                        setselectedFiles_closedPOC([])
                                        if (productData.OpenPOC != null && productData.OpenPOC != "null") {
                                            // console.log("me called")
                                            seteditOldimagesOpenPOC(productData.OpenPOC)
                                        }
                                        if (productData.ClosedPOC != null && productData.ClosedPOC != "null") {
                                            // console.log("me called")
                                            seteditOldimagesClosedPOC(productData.ClosedPOC)
                                        }






                                        // setopenPOC_File_one("")
                                        // setopenPOC_File_two("")
                                        // setopenPOC_File_three("")
                                        // setopenPOC_File_four("")
                                        // setopenPOC_File_five("")

                                        // // console.log("opos", (productData.OpenPOC[0])[1].POCDescription)
                                        // setopenPOC_desc_one(productData.OpenPOC[0] ? productData.OpenPOC[0].POCDescription : "")
                                        // setopenPOC_desc_two(productData.OpenPOC[1] ? productData.OpenPOC[1].POCDescription : "")
                                        // setopenPOC_desc_three(productData.OpenPOC[2] ? productData.OpenPOC[2].POCDescription : "")
                                        // setopenPOC_desc_four(productData.OpenPOC[3] ? productData.OpenPOC[3].POCDescription : "")
                                        // setopenPOC_desc_five(productData.OpenPOC[4] ? productData.OpenPOC[4].POCDescription : "")



                                        // setclosedPOC_File_one("")
                                        // setclosedPOC_File_two("")
                                        // setclosedPOC_File_three("")
                                        // setclosedPOC_File_four("")
                                        // setclosedPOC_File_five("")


                                        // setclosedPOC_desc_one(productData.ClosedPOC[0] ? productData.ClosedPOC[0].POCDescription : "")
                                        // setclosedPOC_desc_two(productData.ClosedPOC[1] ? productData.ClosedPOC[1].POCDescription : "")
                                        // setclosedPOC_desc_three(productData.ClosedPOC[2] ? productData.ClosedPOC[2].POCDescription : "")
                                        // setclosedPOC_desc_four(productData.ClosedPOC[3] ? productData.ClosedPOC[3].POCDescription : "")
                                        // setclosedPOC_desc_five(productData.ClosedPOC[4] ? productData.ClosedPOC[4].POCDescription : "")


                                        // setopenPOC_File_one_name((productData.OpenPOC[0])[0] ? (productData.OpenPOC[0])[0].POCFilename : "")
                                        // setopenPOC_desc_two((productData.OpenPOC[0])[1] ? (productData.OpenPOC[0])[1].POCDescription : "")
                                        // setopenPOC_desc_three((productData.OpenPOC[0])[2] ? (productData.OpenPOC[0])[2].POCDescription : "")
                                        // setopenPOC_desc_four((productData.OpenPOC[0])[3] ? (productData.OpenPOC[0])[3].POCDescription : "")
                                        // setopenPOC_desc_five((productData.OpenPOC[0])[4] ? (productData.OpenPOC[0])[4].POCDescription : "")


                                        // setreference(productData.Reference)
                                        // setmodalPopupData(productData.UserCount)
                                        // setOrganizaionID(productData.OrganizationID)
                                        // setUserCount(productData.UserCount.length)
                                        // setOrgName(productData.OrganizationName)
                                    }}
                                >

                                    Edit

                                </Button>
                            )
                        }
                    })()} */}


                    {/* {(() => {
                        if (role === "Auditor") {
                            return (<>

                                <Button
                                    color="primary"
                                    className="btn-sm me-2"
                                    // disabled={role === "Admin" ? false : true}
                                    onClick={() => {
                                        console.log("cliecekd", productData);
                                        toggle_view_more_modal();
                                        setSeverity(productData.Severity)
                                        set_printClosePOC(productData.ClosedPOC)
                                        set_printOpenPOC(productData.OpenPOC)
                                        setCheckListTag(productData.Tags)
                                        setcheckListTemplateStatus(productData.VulnerabilityStatus)
                                        setdescription(productData.Description)
                                        setremediation(productData.Remediation)
                                        setimpact(productData.Impact)
                                        setTitle(productData.Title)
                                        setreference(productData.Reference)
                                        setvulnerabilityID(productData.VulnerabilityID)
                                        setLable_status(productData.Status)
                                        setcheckListID(productData.CheckListID)
                                        setModifierName(productData.Modifier_Name)
                                        setAffects(productData.Affects)
                                        // setmodalPopupData(productData.UserCount)
                                        // setOrganizaionID(productData.OrganizationID)
                                        // setUserCount(productData.UserCount.length)
                                        // setOrgName(productData.OrganizationName)
                                    }}
                                >

                                    View More

                                </Button>
                            </>)
                        } if (role === "Team Leader" || role === "Project Manager" || role === "Admin") {
                            return (<>

                                <Button
                                    color="primary"
                                    className="btn-sm me-2"
                                    // disabled={role === "Admin" ? false : true}
                                    onClick={() => {
                                        console.log("cliecekd", productData);
                                        toggle_view_more_modal();
                                        setSeverity(productData.Severity)
                                        set_printClosePOC(productData.ClosedPOC)
                                        set_printOpenPOC(productData.OpenPOC)
                                        setCheckListTag(productData.Tags)
                                        setcheckListTemplateStatus(productData.VulnerabilityStatus)
                                        setdescription(productData.Description)
                                        setremediation(productData.Remediation)
                                        setimpact(productData.Impact)
                                        setTitle(productData.Title)
                                        setreference(productData.Reference)
                                        setvulnerabilityID(productData.VulnerabilityID)
                                        setLable_status(productData.Status)
                                        setcheckListID(productData.CheckListID)
                                        setModifierName(productData.Modifier_Name)
                                        setAffects(productData.Affects)
                                        // setmodalPopupData(productData.UserCount)
                                        // setOrganizaionID(productData.OrganizationID)
                                        // setUserCount(productData.UserCount.length)
                                        // setOrgName(productData.OrganizationName)
                                    }}
                                >

                                    View More

                                </Button>
                            </>)
                        }
                    })()} */}

                </>
            ),
        },
    ];
    // Sorting of Data Table
    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    // Page Options in Data Table 
    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    };
    const { SearchBar } = Search; // Search Bar in Data TAble

    var mainData = [];
    var name_regx = /^[A-Za-z ]{3,20}$/;

    useEffect(async () => {

        getUser(); // this will set user role and action buttons are based in it 
        getchecklistType();
        getChecklistForAudit();
        // getCustomers();
    }, []);


    function tog_standard4() {
        setmodal_standard4(!modal_standard4);
        // toggle for view more button 
    }
    function tog_standard_add_auditee() {
        setmodal_add_auditee(!modal_add_auditee);
        // toggle for view more button 
    }
    function tog_standard_generate_report() {
        setmodal_generate_report(!modal_generate_report);
        // toggle for view more button 
    }
    function toggle_view_more_modal() {
        setview_more_modal(!view_more_modal);
        // toggle for view more button 
    }
    function toggle_auditor_review_modal() {
        setauditor_review_modal(!auditor_review_modal);
        // toggle for view more button 
    }
    function toggle_team_leader_review_modal() {
        setteam_leader_review_modal(!team_leader_review_modal);
        // toggle for view more button 
    }


    function tog_standard_start_audit() {
        setStart_audit(!start_audit);
        // toggle for view more button 
    }
    const [role, setRole] = useState()
    const [ApproveStatus, setApproveStatus] = useState()

    // get checklist for unique project ID 
    async function getChecklistForAudit() {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}get-auditor-project-auditlist`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({
                    projectID,
                    userCheck: localStorage.getItem("AuditorID"),
                    orgID: localStorage.getItem("orgID")
                }),
            }
        );
        const data = await response.json();
        if (data.status) {
            setAuditlistPreloader(false)
            console.log("project checklists", data.data)
            setProductData(data.data)
        }
        else {
            setAuditlistPreloader(false)
            // setemailError("Email already exist")
        }
    }


    // to get userLSit 
    async function getUser(event) {
        // event.preventDefault();
        // console.log("token passes " , userCheck)
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}user-profile`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({
                userCheck: localStorage.getItem("AuditorID"),
                orgID: localStorage.getItem("orgID")
            }),
        });
        const data = await response.json();
        if (data) {
            const newdata = data.user;
            // console.log("data retrived", newdata);
            setRole(newdata.Role)

            // setphone(newdata.Phone);
            // setjobTitle(newdata.JobTitle);
            // console.log("main profile", newdata.FirstName);
        } else {
            alert("data retreival error");
        }
    }


    // to get userLSit 
    async function getchecklistType() {
        // event.preventDefault();
        // console.log("token passes " , userCheck)
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}get-auditor-project-checkListType`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({
                projectID,
                userCheck: localStorage.getItem("AuditorID"),
                orgID: localStorage.getItem("orgID")
            }),
        });
        const data = await response.json();
        if (data) {
            const newdata = data;
            setchecklistType(newdata.data)

            // setphone(newdata.Phone);
            // setjobTitle(newdata.JobTitle);
            // console.log("main profile", newdata.FirstName);
        } else {
            alert("data retreival error");
        }
    }


    // ApI to hit table DATa
    async function getCustomers(event) {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}get-auditor-checkList-template`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    // checkListID
                })
            }
        );
        const data = await response.json();
        if (data) {
            console.log("response-", data.data[0].Checklist);


            // mainData = await data.data[0].data.entries;
            mainData = await data;
            setProductData(data.data[0].Checklist);

            setOrgNum(mainData.data.length)
            // console.log(mainData)
            var ACtiveCounter = 0;
            var InactiveCounter = 0
            for (var i = 0; i < mainData.data.length; i++) {
                if (mainData.data[i].Status === "Active") {
                    ACtiveCounter += 1;
                }
                else {
                    InactiveCounter += 1;
                }
            }
            // setActiveOrg(ACtiveCounter);
            // setInActiveOrg(InactiveCounter)
            // console.log("tempData", TempData)
            // setProductData(TempData);
            // setIpCardPrice(TempData[1].Value.length)
            // setdomainCard(TempData[0].Value.length)
            // setSubdomainCardPrice(TempData[2].Value.length)
        } else {
            // alert("data retreival error");
        }
    }

    // function to send data to backend 
    async function deleteChecklistTemplate(id) {
        // console.log("id", id)
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}delete-auditor-checklist-template`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    CheckID: id,
                    // checkListID
                }),
            }
        );
        const data = await response.json();
        if (data.status) {
        }
        else {
            // setemailError("Email already exist")
        }

    }


    // Swwet alert or modal to confirm detials  should modify for perosnal Details 
    function updatePersonalDetails_sweetAlert(id) {
        // console.log("selected GRoup", status)
        swal({
            title: "Are you sure?",
            text: "Once done, you will update your personal details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (deleteChecklistTemplate(id)) {
                        swal("Poof! Your perosnal details has been updated!", {
                            icon: "success",
                            buttons: true
                        }).then((reload) => {
                            if (reload) {
                                // window.location.reload(false);
                                getCustomers()
                            }
                        })
                    }
                    else {
                        swal("Reload operation has been aborted!");
                    }
                } else {
                    swal("Operation has been aborted!");
                }
            });
    }

    // description popup for OPen POC
    function descPopup_openPOC(number) {
        swal({
            title: "Empty!",
            text: `Enter Description in open POC !`,
            icon: "warning",
            button: "Ok!",
        });

        setstartAuditPreloader(false)
    }


    // description popup for Close POC
    function descPopup_closePOC(number) {
        swal({
            title: "Empty!",
            text: `Enter Description in close POC ${number}!`,
            icon: "warning",
            button: "Ok!",
        });

        setstartAuditPreloader(false)
    }

    async function editChecklistTemplate(id) {

        // OpenPOCdropzoneRef.current.temp()

        var formData = new FormData();
        formData.append("userCheck", userCheck);

        for (let i = 0; i < selectedFiles.length; i++) {
            for (let j = 0; j < descOPEN_POC.length; j++) {
                if (selectedFiles[i].uid === descOPEN_POC[j].uid) {
                    if (descOPEN_POC[j].desc === "") {
                        // console.log("i am called")
                        descPopup_openPOC(i)
                        return 0;
                    }
                    formData.append(`openPOC_file`, selectedFiles[i], descOPEN_POC[j].desc);
                }
            }
        }

        for (let i = 0; i < selectedFiles_closedPOC.length; i++) {
            for (let j = 0; j < descClosed_POC.length; j++) {
                if (selectedFiles_closedPOC[i].uid === descClosed_POC[j].uid) {
                    if (descClosed_POC[j].desc === "") {
                        // console.log("i am called")
                        descPopup_openPOC(i)
                        return 0;
                    }
                    formData.append(`ClosedPOC_file`, selectedFiles_closedPOC[i], descClosed_POC[j].desc);
                }
            }
        }
        // for (let i = 0; i < editOldimagesOpenPOC.length; i++) {
        //     console.log("this", editOldimagesOpenPOC[i])
        formData.append(`oldOpenPOCimages`, JSON.stringify(editOldimagesOpenPOC));
        formData.append(`oldClosedPOCimages`, JSON.stringify(editOldimagesClosedPOC));
        // }

        // formData.append(`oldPOCimages`, JSON.stringify(editOldimagesOpenPOC), "old_mages");
        // formData.append(`oldPOCimages$`, selectedFiles, "selectedfiles");

        // await fetch(`${process.env.REACT_APP_DEFAULTPATH}edit-auditor-project-audit-vulnerability`, {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/Json"
        //     },
        //     body: JSON.stringify({
        //         userCheck,
        //         projectID,
        //         Title,
        //         Severity,
        //         checkListTemplateStatus,
        //         CheckListTag,
        //         impact,
        //         remediation,
        //         vulnerabilityID,
        //         description,
        //         affects
        //     })
        // }).then((res) => res.json())
        //     .then((data) => {
        //         // console.log("ssave data", data)
        //         getChecklistForAudit()
        //         tog_standard4()
        //     })


        // var formData = new FormData();
        // formData.append("userCheck", userCheck);

        // if (openPOC_file_one) {
        //     formData.append("openPOC_file_one", openPOC_file_one, openPOC_file_one.name);
        // }
        // if (openPOC_file_two) {
        //     formData.append("openPOC_file_two", openPOC_file_two, openPOC_file_two.name);
        // }
        // if (openPOC_file_three) {
        //     formData.append("openPOC_file_three", openPOC_file_three, openPOC_file_three.name);
        // }
        // if (openPOC_file_four) {
        //     formData.append("openPOC_file_four", openPOC_file_four, openPOC_file_four.name);
        // }
        // if (openPOC_file_five) {
        //     formData.append("openPOC_file_five", openPOC_file_five, openPOC_file_five.name);
        // }


        // if (openPOC_file_one) {
        //     if (!openPOC_desc_one) {
        //         descPopup_openPOC(1)
        //         return 0;
        //     }
        //     formData.append("openPOC_file_one", openPOC_file_one, openPOC_desc_one);
        // }
        // if (openPOC_file_two) {
        //     if (!openPOC_desc_two) {
        //         descPopup_openPOC(2)
        //         return 0;
        //     }
        //     formData.append("openPOC_file_two", openPOC_file_two, openPOC_desc_two);
        // }
        // if (openPOC_file_three) {
        //     if (!openPOC_desc_three) {
        //         descPopup_openPOC(3)
        //         return 0;
        //     }
        //     formData.append("openPOC_file_three", openPOC_file_three, openPOC_desc_three);
        // }
        // if (openPOC_file_four) {
        //     if (!openPOC_desc_four) {
        //         descPopup_openPOC(4)
        //         return 0;
        //     }
        //     formData.append("openPOC_file_four", openPOC_file_four, openPOC_desc_four);
        // }
        // if (openPOC_file_five) {
        //     if (!openPOC_desc_five) {
        //         descPopup_openPOC(5)
        //         return 0;
        //     }
        //     formData.append("openPOC_file_five", openPOC_file_five, openPOC_desc_five);
        // }

        // // states for closed POC 

        // if (closedPOC_file_one) {
        //     if (!closedPOC_desc_one) {
        //         descPopup_closePOC(1)
        //         return 0;
        //     }
        //     formData.append("closedPOC_file_one", closedPOC_file_one, closedPOC_desc_one);
        // }
        // if (closedPOC_file_two) {
        //     if (!openPOC_desc_two) {
        //         descPopup_closePOC(2)
        //         return 0;
        //     }
        //     formData.append("closedPOC_file_two", closedPOC_file_two, closedPOC_desc_two);
        // }
        // if (closedPOC_file_three) {
        //     if (!openPOC_desc_three) {
        //         descPopup_closePOC(3)
        //         return 0;
        //     }
        //     formData.append("closedPOC_file_three", closedPOC_file_three, closedPOC_desc_three);
        // }
        // if (closedPOC_file_four) {
        //     if (!openPOC_desc_four) {
        //         descPopup_closePOC(4)
        //         return 0;
        //     }
        //     formData.append("closedPOC_file_four", closedPOC_file_four, closedPOC_desc_four);
        // }
        // if (closedPOC_file_five) {
        //     if (!openPOC_desc_five) {
        //         descPopup_closePOC(5)
        //         return 0;
        //     }
        //     formData.append("closedPOC_file_five", closedPOC_file_five, closedPOC_desc_five);
        // }

        // if (openPOC_file_one) {
        //     formData.append("openPOC_file_one", openPOC_file_one, openPOC_desc_one);
        // }
        // if (openPOC_file_two) {
        //     formData.append("openPOC_file_two", openPOC_file_two, openPOC_desc_two);
        // }
        // if (openPOC_file_three) {
        //     formData.append("openPOC_file_three", openPOC_file_three, openPOC_desc_three);
        // }
        // if (openPOC_file_four) {
        //     formData.append("openPOC_file_four", openPOC_file_four, openPOC_desc_four);
        // }
        // if (openPOC_file_five) {
        //     formData.append("openPOC_file_five", openPOC_file_five, openPOC_desc_five);
        // }

        // // states for closed POC 

        // if (closedPOC_file_one) {
        //     formData.append("closedPOC_file_one", closedPOC_file_one, closedPOC_desc_one);
        // }
        // if (closedPOC_file_two) {
        //     formData.append("closedPOC_file_two", closedPOC_file_two, closedPOC_desc_two);
        // }
        // if (closedPOC_file_three) {
        //     formData.append("closedPOC_file_three", closedPOC_file_three, closedPOC_desc_three);
        // }
        // if (closedPOC_file_four) {
        //     formData.append("closedPOC_file_four", closedPOC_file_four, closedPOC_desc_four);
        // }
        // if (closedPOC_file_five) {
        //     formData.append("closedPOC_file_five", closedPOC_file_five, closedPOC_desc_five);
        // }

        // formData.append("openPOC_desc_one", openPOC_desc_one);
        // formData.append("openPOC_desc_two", openPOC_desc_two);
        // formData.append("openPOC_desc_three", openPOC_desc_three);
        // formData.append("openPOC_desc_four", openPOC_desc_four);
        // formData.append("openPOC_desc_five", openPOC_desc_one);

        // formData.append("status_severity", status_severity);
        formData.append("projectID", projectID);
        formData.append("Title", Title);
        formData.append("Severity", Severity);
        formData.append("checkListTemplateStatus", checkListTemplateStatus);
        formData.append("CheckListTag", CheckListTag);
        formData.append("impact", impact);
        formData.append("remediation", remediation);
        formData.append("vulnerabilityID", vulnerabilityID);
        formData.append("description", description);
        formData.append("affects", affects);
        // formData.append("oldPOC", edit);
        // formData.append("status_vulnerability_status", status_vulnerability_status);
        // formData.append("vulnerabilityImg1", vulnerabilityImg1[0], "vulnerability_status_img.png");
        // console.log(companyName, companyLogo[0], companyPhone, companyWebsite, city, state, pincode, statusCountry, companyStatus, otherDetails)


        try {
            // const response = await fetch('http://localhost:1338/add-auditee', {
            //     method : "POST",
            //     headers : {
            //         "content-Type" : "application/json"
            //     },
            //     body:formData
            // })

            await axios.post(`${process.env.REACT_APP_DEFAULTPATH}edit-auditor-project-audit-vulnerability-latest`, formData).
                then(() => {
                    console.log("I calleed an edit api")
                    getChecklistForAudit()
                    tog_standard4()
                    // window.location.reload();
                });

        } catch (error) {
            console.log(error)
        }
    }

    // functions to validate regex OrgName
    function checkOrgNameValidation(OrgName_val) {
        if (OrgName_val === " ") {
            setOrgNameError("enter first name")
            setdetailBtn(true)
        }
        else if (name_regx.test(OrgName_val)) {
            setOrgNameError("")
            setdetailBtn(false)
        }
        else if (OrgName_val.length < 3) {
            setOrgNameError(" must contain 3 characters")
            setdetailBtn(true)
        }
        else {
            setOrgNameError(`Organization doesn't contain "0-9  , & @ "`)
            setdetailBtn(true)
        }
    }

    // function to handle change in status dropdown
    function handleSelectOptions(selectedOptions) {
        setselectedOptions(selectedOptions)
        setdetailBtn(false)
    }

    const [notCheckedList, setNotChekedList] = useState([]);
    const [globalIndex, setglobalIndex] = useState(1);
    const [checkListID, setcheckListID] = useState();
    const [vulnerabilityID, setvulnerabilityID] = useState()
    const [modifier_Name, setModifierName] = useState()
    const [affects, setAffects] = useState()
    const [auditID, setauditID] = useState()


    // function to get all not checked status vulnerabikities 
    async function get_Not_Checked_List() {
        await fetch(`${process.env.REACT_APP_DEFAULTPATH}get-auditor-project-auditlist-notChecked`, {
            method: "POST",
            headers: {
                "Content-Type": "application/Json"
            },
            body: JSON.stringify({
                projectID
            })
        }).then((res) => res.json())
            .then((data) => {
                console.log("fetch Data", data.data)

                if (data.data != [] && data.data != null && data.data.length > 0) {
                    setNotChekedList(data.data)
                    setTitle(data.data[0].Title)
                    setSeverity(data.data[0].Severity)
                    setcheckListTemplateStatus(data.data[0].VulnerabilityStatus)
                    setLable_status(data.data[0].Status)
                    setCheckListTag(data.data[0].Tags)
                    setdescription(data.data[0].Description)
                    setimpact(data.data[0].Impact)
                    setAffects(data.data[0].Affects)
                    setremediation(data.data[0].Remediation)
                    setvulnerabilityID(data.data[0].VulnerabilityID)
                    setstartAuditPreloader(false)
                    setstartAuditPreloader2(false)
                    tog_standard_start_audit();
                    setopenPOC_file_List([])
                    setclosedPOC_file_List([])
                }
                else {
                    setstartAuditPreloader(false)
                    setstartAuditPreloader2(false)
                    swal({
                        title: "Empty!",
                        text: "No vulnerability for Audit !",
                        icon: "warning",
                        button: "Ok!",
                    });
                }
            })
    }


    // function to get vulnerabilities list to review for auditor 
    async function get_complete_list_for_auditor_review() {
        await fetch(`${process.env.REACT_APP_DEFAULTPATH}get-auditor-project-auditlist-for-auditor`, {
            method: "POST",
            headers: {
                "Content-Type": "application/Json"
            },
            body: JSON.stringify({
                projectID
            })
        }).then((res) => res.json())
            .then((data) => {
                console.log("fetch Data", data.data)
                setNotChekedList(data.data)
                setTitle(data.data[0].Title)
                setSeverity(data.data[0].Severity)
                setcheckListTemplateStatus(data.data[0].VulnerabilityStatus)
                setLable_status(data.data[0].Status)
                setCheckListTag(data.data[0].Tags)
                setdescription(data.data[0].Description)
                setimpact(data.data[0].Impact)
                setremediation(data.data[0].Remediation)
                setvulnerabilityID(data.data[0].VulnerabilityID)
                setcheckListID(data.data[0].CheckListID)
                setModifierName(data.data[0].Modifier_Name)
                setAffects(data.data[0].Affects)
                toggle_auditor_review_modal();
            })
    }


    // function to get vulnerabilities list to review for Team Leader 
    async function get_complete_list_for_teamLeader_review() {
        await fetch(`${process.env.REACT_APP_DEFAULTPATH}get-auditor-project-auditlist-for-team-leader`, {
            method: "POST",
            headers: {
                "Content-Type": "application/Json"
            },
            body: JSON.stringify({
                projectID
            })
        }).then((res) => res.json())
            .then((data) => {
                // console.log("fetch Data", data.data)
                if (data.data != [] && data.data != null && data.data.length > 0) {
                    console.log("test ", data.data)
                    set_printOpenPOC(data.data[0].OpenPOC)
                    set_printClosePOC(data.data[0].ClosedPOC)
                    setNotChekedList(data.data)
                    setTitle(data.data[0].Title)
                    setSeverity(data.data[0].Severity)
                    setcheckListTemplateStatus(data.data[0].VulnerabilityStatus)
                    setLable_status(data.data[0].Status)
                    setCheckListTag(data.data[0].Tags)
                    setdescription(data.data[0].Description)
                    setimpact(data.data[0].Impact)
                    setAffects(data.data[0].Affects)
                    setremediation(data.data[0].Remediation)
                    setvulnerabilityID(data.data[0].VulnerabilityID)
                    setcheckListID(data.data[0].CheckListID)
                    setModifierName(data.data[0].Modifier_Name)
                    toggle_team_leader_review_modal();
                }
                else {
                    swal({
                        title: "Empty!",
                        text: "No vulnerability for review !",
                        icon: "warning",
                        button: "Ok!",
                    });
                }
            })
    }

    function OpenPOCData(openPOC_imageList, descOPEN_POC) {
        console.log("openPOC_imageList ", openPOC_imageList)
        // console.log("editOldimagesOpenPOC ", editOldimagesOpenPOC)
        console.log("descPOC", descOPEN_POC)
        setopenPOC_file_List(openPOC_imageList)
        setopenPOC_desc_List(descOPEN_POC)
    }
    function ClosedPOCData(closedPOC_imageList, descClosed_POC) {
        console.log("ClosedPOC_imageList ", closedPOC_imageList)
        // console.log("editOldimagesOpenPOC ", editOldimagesOpenPOC)
        console.log("descClosedPOC", descClosed_POC)
        setclosedPOC_file_List(closedPOC_imageList)
        setclosedPOC_desc_List(descClosed_POC)
    }
    function OpenPOCData_Edit() {
        console.log("openPOC_imageList ", selectedFiles)
        console.log("editOldimagesOpenPOC ", editOldimagesOpenPOC)
        console.log("descPOC", descOPEN_POC)
        // console.log("refs", OpenPOCdropzoneEditRef.current.temp())
        // setopenPOC_file_List(openPOC_imageList)
        // setopenPOC_desc_List(descOPEN_POC)
    }
    function ClosedPOCData_Edit() {
        console.log("closedPOC_imageList ", selectedFiles_closedPOC)
        console.log("editOldimagesOpenPOC ", editOldimagesClosedPOC)
        console.log("descPOC", descClosed_POC)
        // console.log("refs", OpenPOCdropzoneEditRef.current.temp())
        // setopenPOC_file_List(openPOC_imageList)
        // setopenPOC_desc_List(descOPEN_POC)
    }
    // save vulnerabiity on save of start audit modal 
    async function save_vulnerability_changes() {

        // OpenPOCdropzoneRef Child data 
        // console.log("data", )

        var formData = new FormData();
        formData.append("userCheck", userCheck);

        for (let i = 0; i < openPOC_file_List.length; i++) {
            for (let j = 0; j < openPOC_desc_List.length; j++) {
                if (openPOC_file_List[i].uid === openPOC_desc_List[j].uid) {
                    if (openPOC_desc_List[j].desc === "") {
                        // console.log("i am called")
                        descPopup_openPOC(i)
                        return 0;
                    }
                    formData.append(`openPOC_file`, openPOC_file_List[i], openPOC_desc_List[j].desc);
                }
            }
        }
        // for closed POC 
        for (let i = 0; i < closedPOC_file_List.length; i++) {
            for (let j = 0; j < closedPOC_desc_List.length; j++) {
                if (closedPOC_file_List[i].uid === closedPOC_desc_List[j].uid) {
                    if (closedPOC_desc_List[j].desc === "") {
                        // console.log("i am called")
                        descPopup_openPOC(i)
                        return 0;
                    }
                    formData.append(`ClosedPOC_file`, closedPOC_file_List[i], closedPOC_desc_List[j].desc);
                }
            }
        }
        formData.append(`oldOpenPOCimages`, JSON.stringify(editOldimagesOpenPOC));
        formData.append(`oldClosedPOCimages`, JSON.stringify(editOldimagesClosedPOC));


        formData.append("projectID", projectID);
        formData.append("Title", Title);
        formData.append("Severity", Severity);
        formData.append("checkListTemplateStatus", checkListTemplateStatus);
        formData.append("CheckListTag", CheckListTag);
        formData.append("impact", impact);
        formData.append("remediation", remediation);
        formData.append("vulnerabilityID", vulnerabilityID);
        formData.append("description", description);
        formData.append("affects", affects);
        formData.append("userCheck", userCheck);

        try {
            await axios.post(`${process.env.REACT_APP_DEFAULTPATH}edit-auditor-project-audit-vulnerability-latest`, formData).
                then(() => {
                    console.log(".then is called")
                    setselectedFiles([])
                    setclosedPOC_file_List([])
                    setopenPOC_file_List([])
                    getChecklistForAudit()
                    // OpenPOCdropzoneRef.current.temp()
                    // ClosedPOCdropzoneRef.current.temp()
                    const incremented_Index = globalIndex + 1;
                    setglobalIndex(incremented_Index >= notCheckedList.length ? setNotChekedList([]) : incremented_Index)
                    if (notCheckedList[globalIndex]) {
                        setopenPOC_desc_List([])
                        setopenPOC_file_List([])
                        setTitle(notCheckedList[globalIndex].Title)
                        setSeverity(notCheckedList[globalIndex].Severity)
                        // setcheckListTemplateStatus(notCheckedList[globalIndex].VulnerabilityStatus)
                        setCheckListTag(notCheckedList[globalIndex].Tags)
                        setdescription(notCheckedList[globalIndex].Description)
                        setLable_status(notCheckedList[globalIndex].Status)
                        setimpact(notCheckedList[globalIndex].Impact)
                        setremediation(notCheckedList[globalIndex].Remediation)
                        setvulnerabilityID(notCheckedList[globalIndex].VulnerabilityID)


                        // setopenPOC_File_one()
                        // setopenPOC_File_two()
                        // setopenPOC_File_three()
                        // setopenPOC_File_four()
                        // setopenPOC_File_five()

                        // setopenPOC_desc_one("")
                        // setopenPOC_desc_two("")
                        // setopenPOC_desc_three("")
                        // setopenPOC_desc_four("")
                        // setopenPOC_desc_five("")

                        // setclosedPOC_desc_one("")
                        // setclosedPOC_desc_two("")
                        // setclosedPOC_desc_three("")
                        // setclosedPOC_desc_four("")
                        // setclosedPOC_desc_five("")


                        // setclosedPOC_File_one()
                        // setclosedPOC_File_two()
                        // setclosedPOC_File_three()
                        // setclosedPOC_File_four()
                        // setclosedPOC_File_five()
                        setstartAuditPreloader2(false)
                        setStart_audit(true)
                    }
                    // window.location.reload();
                })
                .catch((err) => {
                    console.log(".catch is called", err)
                    // setstartAuditPreloader(false)
                    setstartAuditPreloader2(false)
                });

        } catch (error) {
            console.log(error)
        }





        // await fetch(`${process.env.REACT_APP_DEFAULTPATH}edit-auditor-project-audit-vulnerability`, {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/Json"
        //     },
        //     body: JSON.stringify({
        //         userCheck,
        //         projectID,
        //         Title,
        //         Severity,
        //         checkListTemplateStatus,
        //         CheckListTag,
        //         impact,
        //         remediation,
        //         vulnerabilityID,
        //         description,
        //         affects
        //     })
        // }).then((res) => res.json())
        //     .then((data) => {
        //         // console.log("ssave data", data)
        //         if (data.status) {
        //             getChecklistForAudit()
        //             const incremented_Index = globalIndex + 1;
        //             setglobalIndex(incremented_Index >= notCheckedList.length ? setNotChekedList([]) : incremented_Index)
        //             if (notCheckedList[globalIndex]) {
        //                 setTitle(notCheckedList[globalIndex].Title)
        //                 setSeverity(notCheckedList[globalIndex].Severity)
        //                 setcheckListTemplateStatus(notCheckedList[globalIndex].VulnerabilityStatus)
        //                 setCheckListTag(notCheckedList[globalIndex].Tags)
        //                 setdescription(notCheckedList[globalIndex].Description)
        //                 setLable_status(notCheckedList[globalIndex].Status)
        //                 setimpact(notCheckedList[globalIndex].Impact)
        //                 setremediation(notCheckedList[globalIndex].Remediation)
        //                 setvulnerabilityID(notCheckedList[globalIndex].VulnerabilityID)


        //                 setopenPOC_File_one("")
        //                 setopenPOC_File_two("")
        //                 setopenPOC_File_three("")
        //                 setopenPOC_File_four("")
        //                 setopenPOC_File_five("")




        //                 setclosedPOC_File_one("")
        //                 setclosedPOC_File_two("")
        //                 setclosedPOC_File_three("")
        //                 setclosedPOC_File_four("")
        //                 setclosedPOC_File_five("")


        //                 setStart_audit(true)
        //             }
        //             // tog_standard_start_audit()
        //         }
        //     })
    }

    //  check file type for Open POC 
    function check_and_set_open_poc_file(file, index) {
        if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {

            if (index === 0) {
                setopenPOC_File_one(file)
            } if (index === 1) {
                setopenPOC_File_two(file)
            } if (index === 2) {
                setopenPOC_File_three(file)
            } if (index === 3) {
                setopenPOC_File_four(file)
            } if (index === 4) {
                setopenPOC_File_five(file)
            }
        }
        else {
            swal({
                title: "Type Error !",
                text: "You Entered Wrong File Type!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
        }
    }

    //  check file type for close POC 
    function check_and_set_close_poc_file(file, index) {
        if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {

            if (index === 0) {
                setclosedPOC_File_one(file)
            } if (index === 1) {
                setclosedPOC_File_two(file)
            } if (index === 2) {
                setclosedPOC_File_three(file)
            } if (index === 3) {
                setclosedPOC_File_four(file)
            } if (index === 4) {
                setclosedPOC_File_five(file)
            }
        }
        else {
            swal({
                title: "Type Error !",
                text: "You Entered Wrong File Type!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
        }
    }
    const handle_generate_report = (check) => {


        if (check) {
            swal({
                title: "Print all Vulnverability?",
                text: "Once selected, all vulnerability will be printed including not checked!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        set_generate_pdf_loader(true)
                        tog_standard_generate_report()
                        set_report_generated(false)
                        axios.post(`${process.env.REACT_APP_DEFAULTPATH}edit-auditor-project-audit-generate-report-not-checked`, { projectID, userCheck }, {
                            responseType: 'arraybuffer',
                            headers: {
                                'Accept': 'application/pdf'
                            }
                        })
                            .then((res) => {
                                set_generate_pdf_loader(false)
                                console.log(res.data)
                                set_report_generated(true)
                                const blob = new Blob([res.data], { type: 'application/pdf' })
                                const link = document.createElement('a')
                                var link_href = URL.createObjectURL(blob)
                                set_updated_blob(link_href)
                                // window.open(link_href , "_blank");
                            })
                    } else {
                        set_generate_pdf_loader(true)
                        tog_standard_generate_report()
                        set_report_generated(false)
                        axios.post(`${process.env.REACT_APP_DEFAULTPATH}edit-auditor-project-audit-generate-report`, { projectID, userCheck }, {
                            responseType: 'arraybuffer',
                            headers: {
                                'Accept': 'application/pdf'
                            }
                        })
                            .then((res) => {
                                set_generate_pdf_loader(false)
                                console.log(res.data)
                                set_report_generated(true)
                                const blob = new Blob([res.data], { type: 'application/pdf' })
                                const link = document.createElement('a')
                                var link_href = URL.createObjectURL(blob)
                                set_updated_blob(link_href)
                                // window.open(link_href , "_blank");
                            })
                    }
                });
        }
        else {
            // console.log("name dunga")
            swal("Error!", "Report cannot be generated!", "warning");
        }




    }

    const handle_generate_certificate = () => {
        set_report_generated(false)
        // axios.post(`${process.env.REACT_APP_DEFAULTPATH}get-auditor-project-audit-certificate`, { projectID }, {
        //     responseType: 'arraybuffer',
        //     headers: {
        //         'Accept': 'application/pdf'
        //     }
        // })
        //     .then((res) => {
        //         console.log("respinse---->" , res)
        //         set_generate_pdf_loader(false)
        //         console.log(res.data)
        //         // tog_standard_generate_report()
        //         const blob = new Blob([res.data], { type: 'application/pdf' })
        //         const link = document.createElement('a')
        //         var link_href = URL.createObjectURL(blob)
        //         set_updated_blob(link_href)
        //         // window.open(link_href , "_blank");

        //     })

        axios.post(`${process.env.REACT_APP_DEFAULTPATH}create-auditor-project-audit-certificate`, { projectID, userCheck }, {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/pdf,application/json'
            }
        })
            .then((res) => {
                set_generate_pdf_loader(false)
                // console.log("res.data - >", res.data.message)
                // console.log(res.data.byteLength)
                // if (res.data.byteLength == 16 || res.data.byteLength < 16) {
                //     setmodal_generate_report(false)
                //     swal("Error", `${res.data.message}`, "warning");
                // }
                // else {
                //     console.log(res.status)
                //     set_report_generated(true)
                //     const blob = new Blob([res.data], { type: 'application/pdf' })
                //     const link = document.createElement('a')
                //     var link_href = URL.createObjectURL(blob)
                //     set_updated_blob(link_href)
                // }
                // window.open(link_href , "_blank");


                const contentType = res.headers['content-type'];
                console.log("content type", contentType)
                if (contentType.includes('application/pdf')) {
                    console.log(res.status)
                    set_report_generated(true)
                    const blob = new Blob([res.data], { type: 'application/pdf' })
                    const link = document.createElement('a')
                    var link_href = URL.createObjectURL(blob)
                    set_updated_blob(link_href)
                }

                // if the response type is JSON, parse the data and use it in your React component
                if (contentType.includes('application/json')) {
                    const jsonData = JSON.parse(new TextDecoder().decode(res.data));
                    setmodal_generate_report(false)
                    console.log("JSon", jsonData)
                    swal("Error", `${jsonData.message}`, "warning");
                }
            })
    }

    // here DropZone EDit data and function will be create 
    const [descOPEN_POC, setdescOPEN_POC] = useState([])
    const [descClosed_POC, setdescClosed_POC] = useState([])
    const [descOPEN_POC_oldPOC, setdescOPEN_POC_oldPOC] = useState([])
    const [selectedFiles, setselectedFiles] = useState([])
    const [selectedFiles_closedPOC, setselectedFiles_closedPOC] = useState([])
    const [editOldimagesOpenPOC, seteditOldimagesOpenPOC] = useState([])
    const [editOldimagesClosedPOC, seteditOldimagesClosedPOC] = useState([])
    const [DeleteID, setDeleteID] = useState(0)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [oldPOC, setOldPOC] = useState([])

    // useImperativeHandle(ref, () => ({
    //     // const name = "asdassd"
    //     temp() {
    //         // console.log("child called selectedFiles", selectedFiles)
    //         // console.log("openPOC_imageList child", selectedFiles)
    //         // console.log("editOldimagesOpenPOC child", editOldimagesOpenPOC)
    //         // console.log("descPOC child", descOPEN_POC)
    //         props.OpenPOCData_Edit(selectedFiles, descOPEN_POC, editOldimagesOpenPOC)
    //     }
    // }))
    // Random Number 10 unique ID Generator  --------------------------------->
    function uniqueIdGenerator() {
        var seq = (Math.floor(Math.random() * 100) + 100).toString().substring(1);
        const id = Date.now() + seq;
        return id.slice(5, 15);
    }

    function handleAcceptedFiles(files) {
        // console.log("old files", files)

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
                uid: uniqueIdGenerator(),
            })
        )
        let tempArray = []
        for (let i = 0; i < files.length; i++) {
            // console.log("this is ", files[i])
            tempArray.push({ uid: files[i].uid, desc: "" })
        }
        setdescOPEN_POC(tempArray)
        // console.log("files", files)
        // props.OpenPOCData_Edit(files)
        setselectedFiles(files)
        // props.OpenPOCData_Edit(selectedFiles, descOPEN_POC, editOldimagesOpenPOC)

    }
    function handleAcceptedFiles_Closed_POC(files) {
        // console.log("old files", files)

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
                uid: uniqueIdGenerator(),
            })
        )
        let tempArray = []
        for (let i = 0; i < files.length; i++) {
            // console.log("this is ", files[i])
            tempArray.push({ uid: files[i].uid, desc: "" })
        }
        setdescClosed_POC(tempArray)
        // console.log("files", files)
        // props.OpenPOCData_Edit(files)
        setselectedFiles_closedPOC(files)
        // props.OpenPOCData_Edit(selectedFiles, descOPEN_POC, editOldimagesOpenPOC)

    }
    // const [modal_standard4, setmodal_standard4] = useState(false)
    // function tog_standard4() {
    //     setmodal_standard4(!modal_standard4);
    //     // toggle for view more button 
    // }
    function deleteImage(id) {
        // console.log("uid", DeleteID)
        setselectedFiles(prev => selectedFiles.filter(items => items.uid !== id))
        setdescOPEN_POC(prev => descOPEN_POC.filter(items => items.uid !== id))
        // props.OpenPOCData_Edit(selectedFiles, descOPEN_POC, editOldimagesOpenPOC)
        // console.log("seleected files", selectedFiles)
        // let files = selectedFiles
        // files.map(file =>
        //     Object.assign(file)
        // )
        // conso
        // props.OpenPOCData_Edit(files)
    }
    function deleteImage_closedPOC(id) {
        // console.log("uid", DeleteID)
        setselectedFiles_closedPOC(prev => selectedFiles_closedPOC.filter(items => items.uid !== id))
        setdescClosed_POC(prev => descClosed_POC.filter(items => items.uid !== id))
        // props.OpenPOCData_Edit(selectedFiles, descOPEN_POC, editOldimagesOpenPOC)
        // console.log("seleected files", selectedFiles)
        // let files = selectedFiles
        // files.map(file =>
        //     Object.assign(file)
        // )
        // conso
        // props.OpenPOCData_Edit(files)
    }

    function deleteImage_oldImages(id) {
        // console.log("uid", DeleteID)
        seteditOldimagesOpenPOC(prev => editOldimagesOpenPOC.filter(items => items.POCFilename !== id))
        // setdescOPEN_POC(prev => descOPEN_POC.filter(items => items.uid !== id))
        // props.OpenPOCData_Edit(selectedFiles, descOPEN_POC, editOldimagesOpenPOC)
        // console.log("seleected files", selectedFiles)
        // let files = selectedFiles
        // files.map(file =>
        //     Object.assign(file)
        // )
        // conso
        // props.OpenPOCData_Edit(files)
    }
    function deleteImage_oldImages_Closed_POC(id) {
        // console.log("uid", DeleteID)
        seteditOldimagesClosedPOC(prev => editOldimagesClosedPOC.filter(items => items.POCFilename !== id))
        // setdescOPEN_POC(prev => descOPEN_POC.filter(items => items.uid !== id))
        // props.OpenPOCData_Edit(selectedFiles, descOPEN_POC, editOldimagesOpenPOC)
        // console.log("seleected files", selectedFiles)
        // let files = selectedFiles
        // files.map(file =>
        //     Object.assign(file)
        // )
        // conso
        // props.OpenPOCData_Edit(files)
    }
    /**
     * Formats the size
     */

    function sendFiles() {
        console.log("selected files", selectedFiles)
    }
    const handleDescOnchange_oldImages = (value, id) => {
        console.log("data", value, id)

        seteditOldimagesOpenPOC(editOldimagesOpenPOC.map(items => {
            if (items.POCFilename === id) {
                return { DateTime: items.DateTime, POCDescription: value, POCFilename: items.POCFilename };
                console.log("asdas", items)
            } else {
                return items;
            }
        }))
        console.log("main data ", editOldimagesOpenPOC)
        // props.OpenPOCData_Edit(selectedFiles, descOPEN_POC, editOldimagesOpenPOC)
        // const element  = {
        //     desc : value , 
        //     uid : id
        // } 
        // for
        // setdescOPEN_POC(...descOPEN_POC)
        // if()
        // setselectedFiles(prev => selectedFiles.map((data, index) => {
        //     if (id === data.uid) {
        //         console.log("matched")
        //         data.desc = value
        //         console.log("test data", data)
        //     }
        // }))
        // console.log("test data", files)
    }
    const handleDescOnchange_oldImages_closed_POC = (value, id) => {
        console.log("data", value, id)

        seteditOldimagesClosedPOC(editOldimagesClosedPOC.map(items => {
            if (items.POCFilename === id) {
                return { DateTime: items.DateTime, POCDescription: value, POCFilename: items.POCFilename };
                console.log("asdas", items)
            } else {
                return items;
            }
        }))
        console.log("main data ", editOldimagesClosedPOC)
        // props.OpenPOCData_Edit(selectedFiles, descOPEN_POC, editOldimagesOpenPOC)
        // const element  = {
        //     desc : value , 
        //     uid : id
        // } 
        // for
        // setdescOPEN_POC(...descOPEN_POC)
        // if()
        // setselectedFiles(prev => selectedFiles.map((data, index) => {
        //     if (id === data.uid) {
        //         console.log("matched")
        //         data.desc = value
        //         console.log("test data", data)
        //     }
        // }))
        // console.log("test data", files)
    }
    const handleDescOnchange = (value, id) => {
        // console.log("data", value, id)

        setdescOPEN_POC(descOPEN_POC.map(items => {
            if (items.uid === id) {
                return { uid: id, desc: value };
            } else {
                return items;
            }
        }))
        // props.OpenPOCData_Edit(selectedFiles, descOPEN_POC, editOldimagesOpenPOC)
        // const element  = {
        //     desc : value , 
        //     uid : id
        // } 
        // for
        // setdescOPEN_POC(...descOPEN_POC)
        // if()
        // setselectedFiles(prev => selectedFiles.map((data, index) => {
        //     if (id === data.uid) {
        //         console.log("matched")
        //         data.desc = value
        //         console.log("test data", data)
        //     }
        // }))
        // console.log("test data", files)
    }

    const handleDescOnchange_closedPOC = (value, id) => {
        // console.log("data", value, id)

        setdescClosed_POC(descClosed_POC.map(items => {
            if (items.uid === id) {
                return { uid: id, desc: value };
            } else {
                return items;
            }
        }))
        // props.OpenPOCData_Edit(selectedFiles, descOPEN_POC, editOldimagesOpenPOC)
        // const element  = {
        //     desc : value , 
        //     uid : id
        // } 
        // for
        // setdescOPEN_POC(...descOPEN_POC)
        // if()
        // setselectedFiles(prev => selectedFiles.map((data, index) => {
        //     if (id === data.uid) {
        //         console.log("matched")
        //         data.desc = value
        //         console.log("test data", data)
        //     }
        // }))
        // console.log("test data", files)
    }
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    const [modal_image, set_modal_image] = useState("");

    return (
        <React.Fragment>
            {(() => {
                if (startAuditPreloader2) {
                    return (<div class="loader">
                        <div class="bar1"></div>
                        <div class="bar2"></div>
                        <div class="bar3"></div>
                        <div class="bar4"></div>
                        <div class="bar5"></div>
                        <div class="bar6"></div>
                        <div class="bar7"></div>
                        <div class="bar8"></div>
                        <div class="bar9"></div>
                        <div class="bar10"></div>
                        <div class="bar11"></div>
                        <div class="bar12"></div>
                    </div>)
                }
            })()}

            {/* {startAuditPreloade2r ? <div class="wrapper">
                <div class="circle"></div>
                <div class="circle"></div>
                <div class="circle"></div>
                <div class="shadow"></div>
                <div class="shadow"></div>
                <div class="shadow"></div>
            </div> : <></>} */}
            <div className="pt-4 credentialBreachProfile">
                {startAuditPreloader ? <div className="startAuditPreloader">
                    <Circle2 background="transparent" /> </div> : <></>}

                {AuditlistPreloader ?
                    <div className="sub-transparent-loader">
                        <Spinner type="grow" className="ms-2" color="primary" /> </div> : <></>}
                <MetaTags>
                    <title>Audit | AuditSense</title>
                </MetaTags>
                <Row>
                    {/* <Breadcrumbs
                        title="Dashboard"
                        breadcrumbItem="Vulnerability Dashboard"
                        breadcrumbFeature="Vulnerabilities"
                    /> */}
                </Row>
                {(() => {
                    if (role === "Auditor") {
                        return (
                            <div className=" d-flex justify-content-end">

                                <Button className="btn-success d-flex justify-content-center align-items-center me-4" onClick={() => {
                                    // console.log("asdas")
                                    // tog_standard_select_checklist()
                                    // tog_standard_start_audit()

                                    // setstartAuditPreloader(true)
                                    setstartAuditPreloader2(true)
                                    // setopenPOC_desc_one("")
                                    // setopenPOC_desc_two("")
                                    // setopenPOC_desc_three("")
                                    // setopenPOC_desc_four("")
                                    // setopenPOC_desc_five("")

                                    // setclosedPOC_desc_one("")
                                    // setclosedPOC_desc_two("")
                                    // setclosedPOC_desc_three("")
                                    // setclosedPOC_desc_four("")
                                    // setclosedPOC_desc_five("")
                                    setglobalIndex(1)
                                    get_Not_Checked_List()

                                }}><i className="bx bx-play" style={{ fontSize: "20px" }}></i> &nbsp; Start Audit</Button>

                                <Button className="btn-info d-flex justify-content-center align-items-center me-4" onClick={() => {
                                    // console.log("asdas")
                                    // tog_standard_select_checklist()
                                    // tog_standard_start_audit()
                                    setglobalIndex(1)
                                    get_complete_list_for_auditor_review()
                                }}><i className="bx bx-show" style={{ fontSize: "20px" }}></i> &nbsp; Review</Button>

                                <Button className="btn-danger d-flex justify-content-center align-items-center me-4" onClick={() => {
                                    let checkCondition = false;
                                    for (let i = 0; i < productData.length; i++) {
                                        // console.log("product Data  = ", productData[i])
                                        if (productData[i].Status === "Approved") {
                                            checkCondition = true;
                                        }
                                    }

                                    handle_generate_report(checkCondition)

                                }}><i className="bx bx-file" style={{ fontSize: "20px" }}></i> &nbsp; Generate Reports
                                    {generate_pdf_loader ? <div class="spinner-grow spinner-grow-sm text-light" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div> : <></>}
                                </Button>

                                <Button className="btn-warning d-flex justify-content-center align-items-center me-4" onClick={() => {
                                    // set_generate_pdf_loader(true)
                                    tog_standard_generate_report()
                                    handle_generate_certificate()

                                }}>
                                    <i className="fa fa-certificate" style={{ fontSize: "20px" }}></i>
                                    {/* <AiOutlineSafetyCertificate/> */}
                                    &nbsp;Generate Certificate
                                    {/* {generate_pdf_loader ? <div class="spinner-grow spinner-grow-sm text-light" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div> : <></>} */}
                                </Button>
                            </div>
                        )
                    }
                })()}

                {(() => {
                    if (role === "Team Leader") {
                        return (
                            <div className=" d-flex justify-content-end">
                                <Button className="btn-info d-flex justify-content-center align-items-center me-4" onClick={() => {
                                    // console.log("asdas")
                                    // tog_standard_select_checklist()
                                    // tog_standard_start_audit()
                                    // setglobalIndex(1)
                                    get_complete_list_for_teamLeader_review()
                                }}><i className="bx bx-show" style={{ fontSize: "20px" }}></i> &nbsp; Review</Button>
                            </div>
                        )
                    }
                })()}
                {/* <Row> */}

                {/*Auditor  Start Audit  */}
                <Modal
                    isOpen={start_audit}
                    toggle={() => {
                        tog_standard_start_audit();
                    }}
                    className="modal-xl"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Vulnerability Details -
                            {(() => {
                                if (Lable_status === null) {
                                    return <>-</>;
                                } else if (Lable_status === "Approved") {
                                    return <> <Badge pill className="badge-soft-success ms-1  fs-6">{Lable_status}</Badge></>;
                                } else if (Lable_status === "Rejected") {
                                    return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{Lable_status}</Badge>

                                    </>;
                                } else if (Lable_status === "Not Checked") {
                                    return <> <Badge pill className="badge-soft-info ms-1  fs-6">{Lable_status}</Badge>

                                    </>;
                                } else if (Lable_status === "Draft") {
                                    return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{Lable_status}</Badge>

                                    </>;
                                }
                            })()}
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                // setmodal_add_auditee(false);
                                tog_standard_start_audit();
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>


                    {(() => {
                        // console.log("checklist type"  , checklistType)
                        if (checklistType === "Web Application Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={empSize}
                                                                placeholder={Severity}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={empSize}
                                                                placeholder={checkListTemplateStatus}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3 ckEditor_height" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>


                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Affects :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={affects}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setAffects(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                {/* open POC  */}
                                                <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Open POC  :</Label>

                                                    <OpenPocDropZone ref={OpenPOCdropzoneRef} OpenPOCData={OpenPOCData} />

                                                    {/* old code   */}
                                                    {/* <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_one}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_one(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                // placeholder="asdasd"
                                                                // value={openPOC_file_one_name}
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 0)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_two}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_two(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 1)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_three}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_three(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 2)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_four}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_four(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 3)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_five}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_five(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 4)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row> */}


                                                </Row>


                                                {/* Close POC  */}
                                                <Row className="justify-content-between">

                                                    <Label className="form-label mt-4" htmlFor="otherDetails"> Close POC  :</Label>
                                                    <ClosedPocDropZone ref={ClosedPOCdropzoneRef} ClosedPOCData={ClosedPOCData} />
                                                </Row>
                                                {/* <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_one}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_one(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                // placeholder="asdasd"
                                                                // value={closedPOC_file_one_name}
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 0)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_two}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_two(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 1)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_three}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_three(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 2)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_four}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_four(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 3)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_five}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_five(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 4)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>


                                                </Row> */}


                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        if (checklistType === "Mobile Application Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={empSize}
                                                                placeholder={Severity}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={empSize}
                                                                placeholder={checkListTemplateStatus}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>


                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Affects :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={affects}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setAffects(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>
                                                {/* open POC  */}

                                                {/* open POC  */}
                                                <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Open POC  :</Label>

                                                    <OpenPocDropZone ref={OpenPOCdropzoneRef} OpenPOCData={OpenPOCData} />
                                                </Row>

                                                {/* Close POC  */}
                                                <Row className="justify-content-between">

                                                    <Label className="form-label mt-4" htmlFor="otherDetails"> Close POC  :</Label>
                                                    <ClosedPocDropZone ref={ClosedPOCdropzoneRef} ClosedPOCData={ClosedPOCData} />
                                                </Row>
                                                {/* <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Open POC  :</Label>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_one}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_one(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                // placeholder="asdasd"
                                                                // value={openPOC_file_one_name}
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 0)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_two}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_two(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 1)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_three}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_three(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 2)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_four}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_four(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 3)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_five}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_five(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 4)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>


                                                </Row> */}


                                                {/* Close POC  */}
                                                {/* <Row className="justify-content-between">

                                                    <Label className="form-label mt-4" htmlFor="otherDetails"> Close POC  :</Label>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_one}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_one(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                // placeholder="asdasd"
                                                                // value={closedPOC_file_one_name}
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 0)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_two}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_two(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 1)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_three}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_three(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 2)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_four}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_four(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 3)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_five}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_five(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 4)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>


                                                </Row> */}

                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        if (checklistType === "API Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={empSize}
                                                                placeholder={Severity}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={empSize}
                                                                placeholder={checkListTemplateStatus}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>


                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Affects :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={affects}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setAffects(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>
                                                {/* open POC  */}
                                                <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Open POC  :</Label>

                                                    <OpenPocDropZone ref={OpenPOCdropzoneRef} OpenPOCData={OpenPOCData} />
                                                </Row>
                                                {/* Close POC  */}
                                                <Row className="justify-content-between">

                                                    <Label className="form-label mt-4" htmlFor="otherDetails"> Close POC  :</Label>
                                                    <ClosedPocDropZone ref={ClosedPOCdropzoneRef} ClosedPOCData={ClosedPOCData} />
                                                </Row>
                                                {/* <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Open POC  :</Label>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_one}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_one(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                // placeholder="asdasd"
                                                                // value={openPOC_file_one_name}
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 0)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_two}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_two(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 1)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_three}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_three(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 2)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_four}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_four(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 3)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_five}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_five(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 4)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>


                                                </Row> */}


                                                {/* Close POC  */}
                                                {/* <Row className="justify-content-between">

                                                    <Label className="form-label mt-4" htmlFor="otherDetails"> Close POC  :</Label>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_one}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_one(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                // placeholder="asdasd"
                                                                // value={closedPOC_file_one_name}
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 0)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_two}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_two(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 1)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_three}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_three(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 2)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_four}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_four(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 3)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_five}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_five(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 4)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>


                                                </Row> */}

                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        if (checklistType === "Network Audit") {
                            return (

                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={empSize}
                                                                placeholder={Severity}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={empSize}
                                                                placeholder={checkListTemplateStatus}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>


                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Affects :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={affects}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setAffects(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>
                                                {/* open POC  */}
                                                {/* <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Open POC  :</Label>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_one}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_one(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                // placeholder="asdasd"
                                                                // value={openPOC_file_one_name}
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 0)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_two}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_two(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 1)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_three}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_three(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 2)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_four}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_four(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 3)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_five}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_five(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 4)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>


                                                </Row> */}
                                                <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Open POC  :</Label>

                                                    <OpenPocDropZone ref={OpenPOCdropzoneRef} OpenPOCData={OpenPOCData} />
                                                </Row>
                                                {/* Close POC  */}
                                                <Row className="justify-content-between">

                                                    <Label className="form-label mt-4" htmlFor="otherDetails"> Close POC  :</Label>
                                                    <ClosedPocDropZone ref={ClosedPOCdropzoneRef} ClosedPOCData={ClosedPOCData} />
                                                </Row>

                                                {/* Close POC  */}
                                                {/* <Row className="justify-content-between">

                                                    <Label className="form-label mt-4" htmlFor="otherDetails"> Close POC  :</Label>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_one}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_one(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                // placeholder="asdasd"
                                                                // value={closedPOC_file_one_name}
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 0)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_two}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_two(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 1)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_three}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_three(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 2)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_four}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_four(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 3)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_five}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_five(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 4)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>


                                                </Row> */}

                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                    })()}
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                tog_standard_start_audit();
                                setStart_audit(false);
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                // updatePersonalDetails_sweetAlert()
                                // addVulnerabilities()
                                setstartAuditPreloader2(true)
                                save_vulnerability_changes()
                                tog_standard_start_audit()
                            }}
                            className="btn btn-success "
                        // data-dismiss="modal"
                        >
                            Save and Next
                        </button>
                    </div>
                </Modal>

                {/* Edit modal  */}
                <Modal
                    isOpen={modal_standard4}
                    toggle={() => {
                        tog_standard4();
                    }}
                    className="modal-xl"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Vulnerability Details -
                            {(() => {
                                if (Lable_status === null) {
                                    return <>-</>;
                                } else if (Lable_status === "Approved") {
                                    return <> <Badge pill className="badge-soft-success ms-1  fs-6">{Lable_status}</Badge></>;
                                } else if (Lable_status === "Rejected") {
                                    return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{Lable_status}</Badge>

                                    </>;
                                } else if (Lable_status === "Not Checked") {
                                    return <> <Badge pill className="badge-soft-info ms-1  fs-6">{Lable_status}</Badge>

                                    </>;
                                } else if (Lable_status === "Draft") {
                                    return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{Lable_status}</Badge>

                                    </>;
                                }
                            })()}
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setmodal_standard4(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>


                    {/* its modal to show image  */}
                    <Modal isOpen={carousel_modal} centered toggle={() => {
                        toggle_carousel_modal();
                    }} className="modal-lg">
                        {/* <ModalHeader >Modal title</ModalHeader> */}
                        <ModalBody className="align-item-center d-flex justify-content-center cursor-pointer w-100 bg-dark border-black" style={{
                            // height: "80vh",
                            display: "flex",
                            // backgroundColor : 'black',xa
                            justifyContent: "center",
                            alignItems: "center"

                        }}>
                            {/* <img
                        data-dz-thumbnail=""
                        // height="80"
                        width={"80%"}
                        className=""
                        // alt={f.name}
                        src={modal_image}
                    /> */}

                            <Carousel_OpenPOC ImageArr={ImageArr} currentIndex={currentCarouseIndex} />
                        </ModalBody>
                        {/* <ModalFooter>
                    <Button color="secondary" onClick={tog_standard4}>Close</Button>
                </ModalFooter> */}
                    </Modal>

                    {(() => {
                        // console.log("checklist type"  , checklistType)
                        if (checklistType === "Web Application Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={empSize}
                                                                placeholder={Severity}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={empSize}
                                                                placeholder={checkListTemplateStatus}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3 ckEditor_height" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>


                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Affects :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={affects}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setAffects(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                {/* open POC  */}
                                                <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Open POC  :</Label>
                                                    {/* this is dropzone content could be store in component  */}
                                                    <Row>
                                                        <Col className="col-12">
                                                            <Card>
                                                                <CardBody>
                                                                    {/* <CardTitle>Dropzone</CardTitle>
                            <CardSubtitle className="mb-3">
                                DropzoneJS is an open source library that provides
                                drag’n’drop file uploads with image previews.
                            </CardSubtitle> */}
                                                                    <Form>

                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                        </div>
                                                                        <Dropzone
                                                                            accept={".png , .jpg"}
                                                                            onDrop={acceptedFiles => {
                                                                                handleAcceptedFiles(acceptedFiles)
                                                                            }}
                                                                        >
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div className="dropzone">
                                                                                    <div
                                                                                        className="dz-message needsclick mt-2"
                                                                                        {...getRootProps()}
                                                                                    >
                                                                                        <input {...getInputProps()} />
                                                                                        <div className="mb-3">
                                                                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                        </div>
                                                                                        <h4>Drop files here or click to upload.</h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>
                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                            {editOldimagesOpenPOC.map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(editOldimagesOpenPOC)
                                                                                            setCurrentCarouseIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={`${process.env.REACT_APP_DEFAULTPATH}/images/${f.POCFilename}`}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.POCFilename}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder={f.POCDescription} onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange_oldImages(e.target.value, f.POCFilename)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage_oldImages(f.POCFilename)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}
                                                                            {(selectedFiles || []).map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(selectedFiles)
                                                                                            setCurrentIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={f.preview}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.formattedSize}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder="POC Description" onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange(e.target.value, f.uid)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage(f.uid)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}

                                                                        </div>
                                                                    </Form>

                                                                    {/* <div className="text-center mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={sendFiles}
                                >
                                    Send Files
                                </button>
                            </div> */}
                                                                    {/* <input type="file" onChange={(e) => console.log("main change data", e.target.files)} /> */}
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>

                                                    {/* <OpenPOCdropzoneEdit ref={OpenPOCdropzoneEditRef} OpenPOCData_Edit={OpenPOCData_Edit} preSelectedfiles={preSelectedfiles} /> */}


                                                </Row>



                                                {/* Closed POC  */}

                                                <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Closed POC  :</Label>
                                                    {/* this is dropzone content could be store in component  */}
                                                    <Row>
                                                        <Col className="col-12">
                                                            <Card>
                                                                <CardBody>
                                                                    {/* <CardTitle>Dropzone</CardTitle>
                            <CardSubtitle className="mb-3">
                                DropzoneJS is an open source library that provides
                                drag’n’drop file uploads with image previews.
                            </CardSubtitle> */}
                                                                    <Form>

                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                        </div>
                                                                        <Dropzone
                                                                            accept={".png , .jpg"}
                                                                            onDrop={acceptedFiles => {
                                                                                handleAcceptedFiles_Closed_POC(acceptedFiles)
                                                                            }}
                                                                        >
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div className="dropzone">
                                                                                    <div
                                                                                        className="dz-message needsclick mt-2"
                                                                                        {...getRootProps()}
                                                                                    >
                                                                                        <input {...getInputProps()} />
                                                                                        <div className="mb-3">
                                                                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                        </div>
                                                                                        <h4>Drop files here or click to upload.</h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>
                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                            {editOldimagesClosedPOC.map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(editOldimagesClosedPOC)
                                                                                            setCurrentCarouseIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={`${process.env.REACT_APP_DEFAULTPATH}/images/${f.POCFilename}`}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.POCFilename}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder={f.POCDescription} onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange_oldImages_closed_POC(e.target.value, f.POCFilename)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage_oldImages_Closed_POC(f.POCFilename)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}
                                                                            {(selectedFiles_closedPOC || []).map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(selectedFiles_closedPOC)
                                                                                            setCurrentIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={f.preview}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.formattedSize}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder="POC Description" onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange_closedPOC(e.target.value, f.uid)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage_closedPOC(f.uid)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}

                                                                        </div>
                                                                    </Form>

                                                                    {/* <div className="text-center mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={sendFiles}
                                >
                                    Send Files
                                </button>
                            </div> */}
                                                                    {/* <input type="file" onChange={(e) => console.log("main change data", e.target.files)} /> */}
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>

                                                    {/* <OpenPOCdropzoneEdit ref={OpenPOCdropzoneEditRef} OpenPOCData_Edit={OpenPOCData_Edit} preSelectedfiles={preSelectedfiles} /> */}


                                                </Row>
                                                {/* <Row className="justify-content-between">

                                                    <Label className="form-label mt-4" htmlFor="otherDetails"> Close POC  :</Label>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_one}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_one(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                // placeholder="asdasd"
                                                                // value={closedPOC_file_one_name}
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 0)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_two}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_two(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 1)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_three}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_three(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 2)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_four}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_four(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 3)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_five}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_five(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 4)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>


                                                </Row> */}


                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        if (checklistType === "Mobile Application Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={empSize}
                                                                placeholder={Severity}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={empSize}
                                                                placeholder={checkListTemplateStatus}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3 ckEditor_height" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>


                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Affects :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={affects}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setAffects(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                {/* open POC  */}
                                                <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Open POC  :</Label>
                                                    {/* this is dropzone content could be store in component  */}
                                                    <Row>
                                                        <Col className="col-12">
                                                            <Card>
                                                                <CardBody>
                                                                    {/* <CardTitle>Dropzone</CardTitle>
                            <CardSubtitle className="mb-3">
                                DropzoneJS is an open source library that provides
                                drag’n’drop file uploads with image previews.
                            </CardSubtitle> */}
                                                                    <Form>

                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                        </div>
                                                                        <Dropzone
                                                                            accept={".png , .jpg"}
                                                                            onDrop={acceptedFiles => {
                                                                                handleAcceptedFiles(acceptedFiles)
                                                                            }}
                                                                        >
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div className="dropzone">
                                                                                    <div
                                                                                        className="dz-message needsclick mt-2"
                                                                                        {...getRootProps()}
                                                                                    >
                                                                                        <input {...getInputProps()} />
                                                                                        <div className="mb-3">
                                                                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                        </div>
                                                                                        <h4>Drop files here or click to upload.</h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>
                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                            {editOldimagesOpenPOC.map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(editOldimagesOpenPOC)
                                                                                            setCurrentCarouseIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={`${process.env.REACT_APP_DEFAULTPATH}/images/${f.POCFilename}`}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.POCFilename}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder={f.POCDescription} onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange_oldImages(e.target.value, f.POCFilename)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage_oldImages(f.POCFilename)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}
                                                                            {(selectedFiles || []).map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(selectedFiles)
                                                                                            setCurrentIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={f.preview}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.formattedSize}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder="POC Description" onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange(e.target.value, f.uid)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage(f.uid)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}

                                                                        </div>
                                                                    </Form>

                                                                    {/* <div className="text-center mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={sendFiles}
                                >
                                    Send Files
                                </button>
                            </div> */}
                                                                    {/* <input type="file" onChange={(e) => console.log("main change data", e.target.files)} /> */}
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>

                                                    {/* <OpenPOCdropzoneEdit ref={OpenPOCdropzoneEditRef} OpenPOCData_Edit={OpenPOCData_Edit} preSelectedfiles={preSelectedfiles} /> */}


                                                </Row>


                                                {/* Closed POC  */}

                                                <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Closed POC  :</Label>
                                                    {/* this is dropzone content could be store in component  */}
                                                    <Row>
                                                        <Col className="col-12">
                                                            <Card>
                                                                <CardBody>
                                                                    {/* <CardTitle>Dropzone</CardTitle>
                            <CardSubtitle className="mb-3">
                                DropzoneJS is an open source library that provides
                                drag’n’drop file uploads with image previews.
                            </CardSubtitle> */}
                                                                    <Form>

                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                        </div>
                                                                        <Dropzone
                                                                            accept={".png , .jpg"}
                                                                            onDrop={acceptedFiles => {
                                                                                handleAcceptedFiles_Closed_POC(acceptedFiles)
                                                                            }}
                                                                        >
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div className="dropzone">
                                                                                    <div
                                                                                        className="dz-message needsclick mt-2"
                                                                                        {...getRootProps()}
                                                                                    >
                                                                                        <input {...getInputProps()} />
                                                                                        <div className="mb-3">
                                                                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                        </div>
                                                                                        <h4>Drop files here or click to upload.</h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>
                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                            {editOldimagesClosedPOC.map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(editOldimagesClosedPOC)
                                                                                            setCurrentCarouseIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={`${process.env.REACT_APP_DEFAULTPATH}/images/${f.POCFilename}`}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.POCFilename}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder={f.POCDescription} onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange_oldImages_closed_POC(e.target.value, f.POCFilename)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage_oldImages_Closed_POC(f.POCFilename)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}
                                                                            {(selectedFiles_closedPOC || []).map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(selectedFiles_closedPOC)
                                                                                            setCurrentIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={f.preview}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.formattedSize}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder="POC Description" onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange_closedPOC(e.target.value, f.uid)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage_closedPOC(f.uid)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}

                                                                        </div>
                                                                    </Form>

                                                                    {/* <div className="text-center mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={sendFiles}
                                >
                                    Send Files
                                </button>
                            </div> */}
                                                                    {/* <input type="file" onChange={(e) => console.log("main change data", e.target.files)} /> */}
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>

                                                    {/* <OpenPOCdropzoneEdit ref={OpenPOCdropzoneEditRef} OpenPOCData_Edit={OpenPOCData_Edit} preSelectedfiles={preSelectedfiles} /> */}


                                                </Row>
                                                {/* Close POC  */}
                                                {/* <Row className="justify-content-between">

                                                    <Label className="form-label mt-4" htmlFor="otherDetails"> Close POC  :</Label>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_one}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_one(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                // placeholder="asdasd"
                                                                // value={closedPOC_file_one_name}
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 0)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_two}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_two(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 1)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_three}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_three(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 2)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_four}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_four(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 3)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_five}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_five(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 4)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>


                                                </Row> */}


                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        if (checklistType === "API Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={empSize}
                                                                placeholder={Severity}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={empSize}
                                                                placeholder={checkListTemplateStatus}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3 ckEditor_height" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>


                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Affects :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={affects}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setAffects(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                {/* open POC  */}

                                                <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Open POC  :</Label>
                                                    {/* this is dropzone content could be store in component  */}
                                                    <Row>
                                                        <Col className="col-12">
                                                            <Card>
                                                                <CardBody>
                                                                    {/* <CardTitle>Dropzone</CardTitle>
                            <CardSubtitle className="mb-3">
                                DropzoneJS is an open source library that provides
                                drag’n’drop file uploads with image previews.
                            </CardSubtitle> */}
                                                                    <Form>

                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                        </div>
                                                                        <Dropzone
                                                                            accept={".png , .jpg"}
                                                                            onDrop={acceptedFiles => {
                                                                                handleAcceptedFiles(acceptedFiles)
                                                                            }}
                                                                        >
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div className="dropzone">
                                                                                    <div
                                                                                        className="dz-message needsclick mt-2"
                                                                                        {...getRootProps()}
                                                                                    >
                                                                                        <input {...getInputProps()} />
                                                                                        <div className="mb-3">
                                                                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                        </div>
                                                                                        <h4>Drop files here or click to upload.</h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>
                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                            {editOldimagesOpenPOC.map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(editOldimagesOpenPOC)
                                                                                            setCurrentCarouseIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={`${process.env.REACT_APP_DEFAULTPATH}/images/${f.POCFilename}`}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.POCFilename}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder={f.POCDescription} onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange_oldImages(e.target.value, f.POCFilename)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage_oldImages(f.POCFilename)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}
                                                                            {(selectedFiles || []).map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(selectedFiles)
                                                                                            setCurrentIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={f.preview}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.formattedSize}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder="POC Description" onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange(e.target.value, f.uid)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage(f.uid)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}

                                                                        </div>
                                                                    </Form>

                                                                    {/* <div className="text-center mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={sendFiles}
                                >
                                    Send Files
                                </button>
                            </div> */}
                                                                    {/* <input type="file" onChange={(e) => console.log("main change data", e.target.files)} /> */}
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>

                                                    {/* <OpenPOCdropzoneEdit ref={OpenPOCdropzoneEditRef} OpenPOCData_Edit={OpenPOCData_Edit} preSelectedfiles={preSelectedfiles} /> */}


                                                </Row>



                                                {/* Closed POC  */}

                                                <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Closed POC  :</Label>
                                                    {/* this is dropzone content could be store in component  */}
                                                    <Row>
                                                        <Col className="col-12">
                                                            <Card>
                                                                <CardBody>
                                                                    {/* <CardTitle>Dropzone</CardTitle>
                            <CardSubtitle className="mb-3">
                                DropzoneJS is an open source library that provides
                                drag’n’drop file uploads with image previews.
                            </CardSubtitle> */}
                                                                    <Form>

                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                        </div>
                                                                        <Dropzone
                                                                            accept={".png , .jpg"}
                                                                            onDrop={acceptedFiles => {
                                                                                handleAcceptedFiles_Closed_POC(acceptedFiles)
                                                                            }}
                                                                        >
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div className="dropzone">
                                                                                    <div
                                                                                        className="dz-message needsclick mt-2"
                                                                                        {...getRootProps()}
                                                                                    >
                                                                                        <input {...getInputProps()} />
                                                                                        <div className="mb-3">
                                                                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                        </div>
                                                                                        <h4>Drop files here or click to upload.</h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>
                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                            {editOldimagesClosedPOC.map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(editOldimagesClosedPOC)
                                                                                            setCurrentCarouseIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={`${process.env.REACT_APP_DEFAULTPATH}/images/${f.POCFilename}`}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.POCFilename}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder={f.POCDescription} onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange_oldImages_closed_POC(e.target.value, f.POCFilename)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage_oldImages_Closed_POC(f.POCFilename)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}
                                                                            {(selectedFiles_closedPOC || []).map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(selectedFiles_closedPOC)
                                                                                            setCurrentIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={f.preview}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.formattedSize}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder="POC Description" onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange_closedPOC(e.target.value, f.uid)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage_closedPOC(f.uid)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}

                                                                        </div>
                                                                    </Form>

                                                                    {/* <div className="text-center mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={sendFiles}
                                >
                                    Send Files
                                </button>
                            </div> */}
                                                                    {/* <input type="file" onChange={(e) => console.log("main change data", e.target.files)} /> */}
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>

                                                    {/* <OpenPOCdropzoneEdit ref={OpenPOCdropzoneEditRef} OpenPOCData_Edit={OpenPOCData_Edit} preSelectedfiles={preSelectedfiles} /> */}


                                                </Row>
                                                {/* <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Open POC  :</Label>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_one}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_one(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                // placeholder="asdasd"
                                                                // value={openPOC_file_one_name}
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 0)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_two}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_two(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 1)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_three}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_three(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 2)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_four}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_four(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 3)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_five}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_five(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 4)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>


                                                </Row> */}


                                                {/* Close POC  */}
                                                {/* <Row className="justify-content-between">

                                                    <Label className="form-label mt-4" htmlFor="otherDetails"> Close POC  :</Label>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_one}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_one(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                // placeholder="asdasd"
                                                                // value={closedPOC_file_one_name}
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 0)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_two}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_two(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 1)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_three}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_three(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 2)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_four}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_four(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 3)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_five}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_five(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 4)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>


                                                </Row> */}


                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        if (checklistType === "Network Audit") {
                            return (

                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={empSize}
                                                                placeholder={Severity}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={empSize}
                                                                placeholder={checkListTemplateStatus}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3 ckEditor_height" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>


                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Affects :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={affects}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setAffects(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                {/* open POC  */}

                                                <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Open POC  :</Label>
                                                    {/* this is dropzone content could be store in component  */}
                                                    <Row>
                                                        <Col className="col-12">
                                                            <Card>
                                                                <CardBody>
                                                                    {/* <CardTitle>Dropzone</CardTitle>
                            <CardSubtitle className="mb-3">
                                DropzoneJS is an open source library that provides
                                drag’n’drop file uploads with image previews.
                            </CardSubtitle> */}
                                                                    <Form>

                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                        </div>
                                                                        <Dropzone
                                                                            accept={".png , .jpg"}
                                                                            onDrop={acceptedFiles => {
                                                                                handleAcceptedFiles(acceptedFiles)
                                                                            }}
                                                                        >
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div className="dropzone">
                                                                                    <div
                                                                                        className="dz-message needsclick mt-2"
                                                                                        {...getRootProps()}
                                                                                    >
                                                                                        <input {...getInputProps()} />
                                                                                        <div className="mb-3">
                                                                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                        </div>
                                                                                        <h4>Drop files here or click to upload.</h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>
                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                            {editOldimagesOpenPOC.map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(editOldimagesOpenPOC)
                                                                                            setCurrentCarouseIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={`${process.env.REACT_APP_DEFAULTPATH}/images/${f.POCFilename}`}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.POCFilename}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder={f.POCDescription} onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange_oldImages(e.target.value, f.POCFilename)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage_oldImages(f.POCFilename)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}
                                                                            {(selectedFiles || []).map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(selectedFiles)
                                                                                            setCurrentIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={f.preview}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.formattedSize}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder="POC Description" onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange(e.target.value, f.uid)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage(f.uid)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}

                                                                        </div>
                                                                    </Form>

                                                                    {/* <div className="text-center mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={sendFiles}
                                >
                                    Send Files
                                </button>
                            </div> */}
                                                                    {/* <input type="file" onChange={(e) => console.log("main change data", e.target.files)} /> */}
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>

                                                    {/* <OpenPOCdropzoneEdit ref={OpenPOCdropzoneEditRef} OpenPOCData_Edit={OpenPOCData_Edit} preSelectedfiles={preSelectedfiles} /> */}


                                                </Row>


                                                {/* Closed POC  */}

                                                <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Closed POC  :</Label>
                                                    {/* this is dropzone content could be store in component  */}
                                                    <Row>
                                                        <Col className="col-12">
                                                            <Card>
                                                                <CardBody>
                                                                    {/* <CardTitle>Dropzone</CardTitle>
                            <CardSubtitle className="mb-3">
                                DropzoneJS is an open source library that provides
                                drag’n’drop file uploads with image previews.
                            </CardSubtitle> */}
                                                                    <Form>

                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                        </div>
                                                                        <Dropzone
                                                                            accept={".png , .jpg"}
                                                                            onDrop={acceptedFiles => {
                                                                                handleAcceptedFiles_Closed_POC(acceptedFiles)
                                                                            }}
                                                                        >
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div className="dropzone">
                                                                                    <div
                                                                                        className="dz-message needsclick mt-2"
                                                                                        {...getRootProps()}
                                                                                    >
                                                                                        <input {...getInputProps()} />
                                                                                        <div className="mb-3">
                                                                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                        </div>
                                                                                        <h4>Drop files here or click to upload.</h4>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>
                                                                        <div className="dropzone-previews mt-3" id="file-previews">

                                                                            {editOldimagesClosedPOC.map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(editOldimagesClosedPOC)
                                                                                            setCurrentCarouseIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={`${process.env.REACT_APP_DEFAULTPATH}/images/${f.POCFilename}`}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.POCFilename}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder={f.POCDescription} onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange_oldImages_closed_POC(e.target.value, f.POCFilename)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage_oldImages_Closed_POC(f.POCFilename)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}
                                                                            {(selectedFiles_closedPOC || []).map((f, i) => {
                                                                                return (<div className="row  d-flex justify-content-around">

                                                                                    <Card role="button"
                                                                                        className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                        key={i + "-file"}
                                                                                        onClick={() => {
                                                                                            set_modal_image(f.preview)
                                                                                            setcarousel_modal(true)
                                                                                            setImageArr(selectedFiles_closedPOC)
                                                                                            setCurrentIndex(i)
                                                                                        }}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Col className="col-auto">
                                                                                                    <img
                                                                                                        data-dz-thumbnail=""
                                                                                                        height="80"
                                                                                                        className="avatar-sm rounded bg-light"
                                                                                                        // alt={f.name}
                                                                                                        src={f.preview}
                                                                                                    />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <Link
                                                                                                        to="#"
                                                                                                        className="text-muted font-weight-bold"
                                                                                                    >
                                                                                                        {f.name}
                                                                                                    </Link>
                                                                                                    <p className="mb-0">
                                                                                                        <strong>{f.formattedSize}</strong>
                                                                                                    </p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>
                                                                                    <Card
                                                                                        className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">
                                                                                            <Row className="align-items-center">
                                                                                                <Input type="text" placeholder="POC Description" onChange={(e) => {
                                                                                                    // console.log("this is e", e.target.value)
                                                                                                    handleDescOnchange_closedPOC(e.target.value, f.uid)
                                                                                                }} />
                                                                                            </Row>
                                                                                        </div>
                                                                                    </Card>

                                                                                    <Card
                                                                                        className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                                                        key={i + "-file"}
                                                                                    >
                                                                                        <div className="p-2">

                                                                                            <Button className="" color="danger" onClick={() => {
                                                                                                // set_modal_image(f.preview)
                                                                                                // setmodal_standard4(true)
                                                                                                // console.log("delteUID", f.uid)
                                                                                                setCurrentIndex(i)
                                                                                                // setDeleteID()
                                                                                                deleteImage_closedPOC(f.uid)
                                                                                            }}>Delete</Button>
                                                                                        </div>
                                                                                    </Card>
                                                                                </div>
                                                                                )
                                                                            })}

                                                                        </div>
                                                                    </Form>

                                                                    {/* <div className="text-center mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={sendFiles}
                                >
                                    Send Files
                                </button>
                            </div> */}
                                                                    {/* <input type="file" onChange={(e) => console.log("main change data", e.target.files)} /> */}
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>

                                                    {/* <OpenPOCdropzoneEdit ref={OpenPOCdropzoneEditRef} OpenPOCData_Edit={OpenPOCData_Edit} preSelectedfiles={preSelectedfiles} /> */}


                                                </Row>
                                                {/* <Row className="justify-content-between">

                                                    <Label className="form-label" htmlFor="otherDetails"> Open POC  :</Label>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_one}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_one(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                // placeholder="asdasd"
                                                                // value={openPOC_file_one_name}
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 0)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_two}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_two(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 1)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_three}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_three(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 2)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_four}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_four(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 3)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={openPOC_desc_five}
                                                                onChange={(e) => {
                                                                    setopenPOC_desc_five(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {
                                                                    // console.log("files 1", e.target.files)
                                                                    check_and_set_open_poc_file(e.target.files[0], 4)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>


                                                </Row> */}


                                                {/* Close POC  */}
                                                {/* <Row className="justify-content-between">

                                                    <Label className="form-label mt-4" htmlFor="otherDetails"> Close POC  :</Label>
                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_one}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_one(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                // placeholder="asdasd"
                                                                // value={closedPOC_file_one_name}
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 0)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_two}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_two(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 1)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_three}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_three(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 2)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_four}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_four(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 3)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={8}>
                                                            <Input type="text" placeholder="Enter POC Description" className="mb-3"
                                                                value={closedPOC_desc_five}
                                                                onChange={(e) => {
                                                                    setclosedPOC_desc_five(e.target.value)
                                                                }} />

                                                        </Col>
                                                        <Col md={3}>

                                                            <input type="file" className="mb-3" accept="image/jpg, image/jpeg , image/png"
                                                                onChange={(e) => {

                                                                    check_and_set_close_poc_file(e.target.files[0], 4)
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>

                                                            <Button className="btn bg-primary mb-3" > View</Button>
                                                        </Col>
                                                    </Row>


                                                </Row> */}


                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                    })()}

                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                tog_standard4();
                                setmodal_standard4(false);
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                // updatePersonalDetails_sweetAlert()
                                editChecklistTemplate()
                                OpenPOCData_Edit()

                            }}
                            // disabled={detailBtn}
                            className="btn btn-primary "
                            data-dismiss="modal"
                        >
                            Save
                        </button>
                    </div>
                </Modal>



                {/* TAble  */}

                <Row className="content">
                    <Col
                        lg={4}
                        md={5}
                        className="col-xxl-9 userData credentialData"
                    >
                        <div className="auth-full-page-content d-flex p-sm-5 p-4">
                            <div className="w-100">
                                <div className="d-flex flex-column">
                                    <div className="  text-center">
                                        <Link
                                            to="/dashboard"
                                            className="d-block auth-logo"
                                        >
                                        </Link>
                                    </div>
                                    <div className="auth-content my-auto">

                                        <div className="mainTable">
                                            <CardBody>
                                                <PaginationProvider
                                                    pagination={paginationFactory(
                                                        pageOptions
                                                    )}
                                                >
                                                    {({
                                                        paginationProps,
                                                        paginationTableProps,
                                                    }) => (
                                                        <ToolkitProvider
                                                            keyField="id"
                                                            columns={columns}
                                                            data={productData}
                                                            search
                                                        >
                                                            {(toolkitProps) => (
                                                                <React.Fragment>
                                                                    <Row className="mb-2">
                                                                        <Col>
                                                                            <div className="search-box me-2 mb-2 d-flex justify-content-between">
                                                                                <div className="d-inline">
                                                                                    <SizePerPageDropdownStandalone
                                                                                        {...paginationProps}
                                                                                    />
                                                                                </div>
                                                                                <div className="position-relative">
                                                                                    <SearchBar
                                                                                        {...toolkitProps.searchProps}
                                                                                    />
                                                                                    <i className="bx bx-search-alt search-icon" />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col xl="12">
                                                                            <div className="table-responsive">
                                                                                <BootstrapTable
                                                                                    keyField={"id"}
                                                                                    responsive
                                                                                    bordered={false}
                                                                                    striped={false}
                                                                                    defaultSorted={
                                                                                        defaultSorted
                                                                                    }
                                                                                    classes={
                                                                                        "table align-middle table-nowrap"
                                                                                    }
                                                                                    headerWrapperClasses={
                                                                                        "thead-light"
                                                                                    }
                                                                                    {...toolkitProps.baseProps}
                                                                                    {...paginationTableProps}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="align-items-md-center mt-30">
                                                                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </Col>
                                                                    </Row>

                                                                </React.Fragment>
                                                            )}
                                                        </ToolkitProvider>
                                                    )}
                                                </PaginationProvider>
                                            </CardBody>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* </Row> */}


                {/* Auditor Review  MODal to REview  */}
                <Modal
                    isOpen={auditor_review_modal}
                    toggle={() => {
                        toggle_auditor_review_modal();
                    }}
                    className="modal-xl"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Vulnerability Details -
                            {(() => {
                                if (Lable_status === null) {
                                    return <>-</>;
                                } else if (Lable_status === "Approved") {
                                    return <> <Badge pill className="badge-soft-success ms-1  fs-6">{Lable_status}</Badge></>;
                                } else if (Lable_status === "Rejected") {
                                    return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{Lable_status}</Badge>

                                    </>;
                                } else if (Lable_status === "Not Checked") {
                                    return <> <Badge pill className="badge-soft-info ms-1  fs-6">{Lable_status}</Badge>

                                    </>;
                                } else if (Lable_status === "Draft") {
                                    return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{Lable_status}</Badge>

                                    </>;
                                }
                            })()}
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                toggle_auditor_review_modal();
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {
                        (() => {
                            if (checklistType === "Web Application Audit") {
                                return (
                                    <div className="modal-body">

                                        <Form >

                                            <Row className='my-2'>

                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Checklist ID *  </Label>
                                                        <div>{checkListID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Vulnerability ID *  </Label>
                                                        <div>{vulnerabilityID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Modified By *  </Label>
                                                        <div>{modifier_Name}</div>
                                                    </div>
                                                </Col>


                                            </Row>

                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                        <div>{Title}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                        <div>
                                                            {(() => {

                                                                if (Severity === null) {
                                                                    return <>-</>;
                                                                } else if (Severity === "Critical" || Severity === "High") {
                                                                    return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                } else if (Severity === "Medium" || Severity === "Low") {
                                                                    return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                } else if (Severity === "Informational") {
                                                                    return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                }

                                                            })()}

                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Vulnerability Status * </Label>
                                                        <div>  {(() => {

                                                            if (checkListTemplateStatus === null) {
                                                                return <>-</>;
                                                            } else if (checkListTemplateStatus === "Open") {
                                                                return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                            } else if (checkListTemplateStatus === "Closed") {
                                                                return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            } else if (checkListTemplateStatus === "Not Applicable") {
                                                                return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            }
                                                        })()}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                        <div>{CheckListTag}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                <div className="text-secondary">{description}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                <div className="text-secondary">{remediation}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Impact :  </Label>
                                                <div className="text-secondary">{impact}</div>
                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Affects :  </Label>
                                                <div className="text-secondary">{affects}</div>
                                            </Row>


                                        </Form>

                                    </div>
                                )
                            }

                            if (checklistType === "Mobile Application Audit") {
                                return (
                                    <div className="modal-body">

                                        <Form >

                                            <Row className='my-2'>

                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Checklist ID *  </Label>
                                                        <div>{checkListID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Vulnerability ID *  </Label>
                                                        <div>{vulnerabilityID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Modified By *  </Label>
                                                        <div>{modifier_Name}</div>
                                                    </div>
                                                </Col>


                                            </Row>

                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                        <div>{Title}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                        <div>
                                                            {(() => {

                                                                if (Severity === null) {
                                                                    return <>-</>;
                                                                } else if (Severity === "Critical" || Severity === "High") {
                                                                    return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                } else if (Severity === "Medium" || Severity === "Low") {
                                                                    return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                } else if (Severity === "Informational") {
                                                                    return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                }

                                                            })()}

                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Vulnerability Status * </Label>
                                                        <div>  {(() => {

                                                            if (checkListTemplateStatus === null) {
                                                                return <>-</>;
                                                            } else if (checkListTemplateStatus === "Open") {
                                                                return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                            } else if (checkListTemplateStatus === "Closed") {
                                                                return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            } else if (checkListTemplateStatus === "Not Applicable") {
                                                                return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            }
                                                        })()}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                        <div>{CheckListTag}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                <div className="text-secondary">{description}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                <div className="text-secondary">{remediation}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Impact :  </Label>
                                                <div className="text-secondary">{impact}</div>
                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Affects :  </Label>
                                                <div className="text-secondary">{affects}</div>
                                            </Row>


                                        </Form>

                                    </div>
                                )
                            }

                            if (checklistType === "API Audit") {
                                return (
                                    <div className="modal-body">

                                        <Form >

                                            <Row className='my-2'>

                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Checklist ID *  </Label>
                                                        <div>{checkListID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Vulnerability ID *  </Label>
                                                        <div>{vulnerabilityID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Modified By *  </Label>
                                                        <div>{modifier_Name}</div>
                                                    </div>
                                                </Col>


                                            </Row>

                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                        <div>{Title}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                        <div>
                                                            {(() => {

                                                                if (Severity === null) {
                                                                    return <>-</>;
                                                                } else if (Severity === "Critical" || Severity === "High") {
                                                                    return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                } else if (Severity === "Medium" || Severity === "Low") {
                                                                    return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                } else if (Severity === "Informational") {
                                                                    return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                }

                                                            })()}

                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Vulnerability Status * </Label>
                                                        <div>  {(() => {

                                                            if (checkListTemplateStatus === null) {
                                                                return <>-</>;
                                                            } else if (checkListTemplateStatus === "Open") {
                                                                return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                            } else if (checkListTemplateStatus === "Closed") {
                                                                return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            } else if (checkListTemplateStatus === "Not Applicable") {
                                                                return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            }
                                                        })()}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                        <div>{CheckListTag}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                <div className="text-secondary">{description}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                <div className="text-secondary">{remediation}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Impact :  </Label>
                                                <div className="text-secondary">{impact}</div>
                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Affects :  </Label>
                                                <div className="text-secondary">{affects}</div>
                                            </Row>


                                        </Form>

                                    </div>
                                )
                            }

                            if (checklistType === "Network Audit") {
                                return (
                                    <div className="modal-body">

                                        <Form >

                                            <Row className='my-2'>

                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Checklist ID *  </Label>
                                                        <div>{checkListID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Vulnerability ID *  </Label>
                                                        <div>{vulnerabilityID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Modified By *  </Label>
                                                        <div>{modifier_Name}</div>
                                                    </div>
                                                </Col>


                                            </Row>

                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                        <div>{Title}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                        <div>
                                                            {(() => {

                                                                if (Severity === null) {
                                                                    return <>-</>;
                                                                } else if (Severity === "Critical" || Severity === "High") {
                                                                    return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                } else if (Severity === "Medium" || Severity === "Low") {
                                                                    return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                } else if (Severity === "Informational") {
                                                                    return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                }

                                                            })()}

                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Vulnerability Status * </Label>
                                                        <div>  {(() => {

                                                            if (checkListTemplateStatus === null) {
                                                                return <>-</>;
                                                            } else if (checkListTemplateStatus === "Open") {
                                                                return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                            } else if (checkListTemplateStatus === "Closed") {
                                                                return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            } else if (checkListTemplateStatus === "Not Applicable") {
                                                                return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            }
                                                        })()}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                        <div>{CheckListTag}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                <div className="text-secondary">{description}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                <div className="text-secondary">{remediation}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Impact :  </Label>
                                                <div className="text-secondary">{impact}</div>
                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Affects :  </Label>
                                                <div className="text-secondary">{affects}</div>
                                            </Row>


                                        </Form>

                                    </div>
                                )
                            }
                        })()
                    }

                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                toggle_auditor_review_modal();
                                const incremented_Index = globalIndex + 1;
                                setglobalIndex(incremented_Index >= notCheckedList.length ? setNotChekedList([]) : incremented_Index)
                                if (notCheckedList[globalIndex]) {
                                    setTitle(notCheckedList[globalIndex].Title)
                                    setSeverity(notCheckedList[globalIndex].Severity)
                                    setcheckListTemplateStatus(notCheckedList[globalIndex].VulnerabilityStatus)
                                    setCheckListTag(notCheckedList[globalIndex].Tags)
                                    setdescription(notCheckedList[globalIndex].Description)
                                    setimpact(notCheckedList[globalIndex].Impact)
                                    setremediation(notCheckedList[globalIndex].Remediation)
                                    setvulnerabilityID(notCheckedList[globalIndex].VulnerabilityID)
                                    setLable_status(notCheckedList[globalIndex].Status)
                                    setcheckListID(notCheckedList[globalIndex].CheckListID)
                                    setModifierName(notCheckedList[globalIndex].Modifier_Name)
                                    setAffects(notCheckedList[globalIndex].Affects)
                                    setauditor_review_modal(true);
                                }

                            }}
                            className="btn btn-warning "
                            data-dismiss="modal"
                        >
                            Next
                        </button>
                        {/* <button
                                type="button"
                                onClick={() => {
                                    // updatePersonalDetails_sweetAlert()
                                    editChecklistTemplate()
                                }}
                                // disabled={detailBtn}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Save
                            </button> */}
                    </div>
                </Modal>


                {/* Team leader  Review  MODal to REview  */}
                <Modal
                    isOpen={team_leader_review_modal}
                    toggle={() => {
                        toggle_team_leader_review_modal();
                    }}
                    className="modal-xl"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Vulnerability Details -
                            {(() => {
                                if (Lable_status === null) {
                                    return <>-</>;
                                } else if (Lable_status === "Approved") {
                                    return <> <Badge pill className="badge-soft-success ms-1  fs-6">{Lable_status}</Badge></>;
                                } else if (Lable_status === "Rejected") {
                                    return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{Lable_status}</Badge>

                                    </>;
                                } else if (Lable_status === "Not Checked") {
                                    return <> <Badge pill className="badge-soft-info ms-1  fs-6">{Lable_status}</Badge>

                                    </>;
                                } else if (Lable_status === "Draft") {
                                    return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{Lable_status}</Badge>

                                    </>;
                                }
                            })()}
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                toggle_team_leader_review_modal();
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {
                        (() => {
                            if (checklistType === "Web Application Audit") {
                                return (
                                    <div className="modal-body">

                                        <Form >

                                            <Row className='my-2'>

                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Checklist ID *  </Label>
                                                        <div>{checkListID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Vulnerability ID *  </Label>
                                                        <div>{vulnerabilityID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Modified By *  </Label>
                                                        <div>{modifier_Name}</div>
                                                    </div>
                                                </Col>


                                            </Row>

                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                        <div>{Title}</div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className='my-2'>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                        <div>
                                                            {(() => {

                                                                if (Severity === null) {
                                                                    return <>-</>;
                                                                } else if (Severity === "Critical" || Severity === "High") {
                                                                    return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                } else if (Severity === "Medium" || Severity === "Low") {
                                                                    return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                } else if (Severity === "Informational") {
                                                                    return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                }

                                                            })()}

                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Vulnerability Status * </Label>
                                                        <div>  {(() => {

                                                            if (checkListTemplateStatus === null) {
                                                                return <>-</>;
                                                            } else if (checkListTemplateStatus === "Open") {
                                                                return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                            } else if (checkListTemplateStatus === "Closed") {
                                                                return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            } else if (checkListTemplateStatus === "Not Applicable") {
                                                                return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            }
                                                        })()}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                        <div>{CheckListTag}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                <div className="text-secondary">{description}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                <div className="text-secondary">{remediation}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Impact :  </Label>
                                                <div className="text-secondary">{impact}</div>
                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Affects :  </Label>
                                                <div className="text-secondary">{affects}</div>
                                            </Row>

                                            <Row className='my-3'>
                                                <Label className="form-label" htmlFor="employeeSize">POC :  </Label>
                                                {
                                                    checkListTemplateStatus && checkListTemplateStatus === "Open" && (
                                                        printOpenPOC.map((data, index) => {
                                                            return <img src={`${process.env.REACT_APP_DEFAULTPATH}images/${data.POCFilename}`} />
                                                        })
                                                    )
                                                }{
                                                    checkListTemplateStatus && checkListTemplateStatus === "Closed" && (
                                                        printClosePOC.map((data, index) => {
                                                            return <img src={`${process.env.REACT_APP_DEFAULTPATH}images/${data.POCFilename}`} />
                                                        })
                                                    )
                                                }
                                            </Row>


                                        </Form>

                                    </div>
                                )
                            }

                            if (checklistType === "Mobile Application Audit") {
                                return (
                                    <div className="modal-body">

                                        <Form >

                                            <Row className='my-2'>

                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Checklist ID *  </Label>
                                                        <div>{checkListID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Vulnerability ID *  </Label>
                                                        <div>{vulnerabilityID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Modified By *  </Label>
                                                        <div>{modifier_Name}</div>
                                                    </div>
                                                </Col>


                                            </Row>

                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                        <div>{Title}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                        <div>
                                                            {(() => {

                                                                if (Severity === null) {
                                                                    return <>-</>;
                                                                } else if (Severity === "Critical" || Severity === "High") {
                                                                    return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                } else if (Severity === "Medium" || Severity === "Low") {
                                                                    return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                } else if (Severity === "Informational") {
                                                                    return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                }

                                                            })()}

                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Vulnerability Status * </Label>
                                                        <div>  {(() => {

                                                            if (checkListTemplateStatus === null) {
                                                                return <>-</>;
                                                            } else if (checkListTemplateStatus === "Open") {
                                                                return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                            } else if (checkListTemplateStatus === "Closed") {
                                                                return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            } else if (checkListTemplateStatus === "Not Applicable") {
                                                                return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            }
                                                        })()}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                        <div>{CheckListTag}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                <div className="text-secondary">{description}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                <div className="text-secondary">{remediation}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Impact :  </Label>
                                                <div className="text-secondary">{impact}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Affects :  </Label>
                                                <div className="text-secondary">{affects}</div>
                                            </Row>
                                            <Row className='my-3'>
                                                <Label className="form-label" htmlFor="employeeSize">POC :  </Label>
                                                {
                                                    checkListTemplateStatus && checkListTemplateStatus === "Open" && (
                                                        printOpenPOC.map((data, index) => {
                                                            return <img src={`${process.env.REACT_APP_DEFAULTPATH}images/${data.POCFilename}`} />
                                                        })
                                                    )
                                                }{
                                                    checkListTemplateStatus && checkListTemplateStatus === "Closed" && (
                                                        printClosePOC.map((data, index) => {
                                                            return <img src={`${process.env.REACT_APP_DEFAULTPATH}images/${data.POCFilename}`} />
                                                        })
                                                    )
                                                }
                                            </Row>

                                        </Form>

                                    </div>
                                )
                            }

                            if (checklistType === "API Audit") {
                                return (
                                    <div className="modal-body">

                                        <Form >

                                            <Row className='my-2'>

                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Checklist ID *  </Label>
                                                        <div>{checkListID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Vulnerability ID *  </Label>
                                                        <div>{vulnerabilityID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Modified By *  </Label>
                                                        <div>{modifier_Name}</div>
                                                    </div>
                                                </Col>


                                            </Row>

                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                        <div>{Title}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                        <div>
                                                            {(() => {

                                                                if (Severity === null) {
                                                                    return <>-</>;
                                                                } else if (Severity === "Critical" || Severity === "High") {
                                                                    return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                } else if (Severity === "Medium" || Severity === "Low") {
                                                                    return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                } else if (Severity === "Informational") {
                                                                    return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                }

                                                            })()}

                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Vulnerability Status * </Label>
                                                        <div>  {(() => {

                                                            if (checkListTemplateStatus === null) {
                                                                return <>-</>;
                                                            } else if (checkListTemplateStatus === "Open") {
                                                                return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                            } else if (checkListTemplateStatus === "Closed") {
                                                                return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            } else if (checkListTemplateStatus === "Not Applicable") {
                                                                return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            }
                                                        })()}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                        <div>{CheckListTag}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                <div className="text-secondary">{description}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                <div className="text-secondary">{remediation}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Impact :  </Label>
                                                <div className="text-secondary">{impact}</div>
                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Affects :  </Label>
                                                <div className="text-secondary">{affects}</div>
                                            </Row>
                                            <Row className='my-3'>
                                                <Label className="form-label" htmlFor="employeeSize">POC :  </Label>
                                                {
                                                    checkListTemplateStatus && checkListTemplateStatus === "Open" && (
                                                        printOpenPOC.map((data, index) => {
                                                            return <img src={`${process.env.REACT_APP_DEFAULTPATH}images/${data.POCFilename}`} />
                                                        })
                                                    )
                                                }{
                                                    checkListTemplateStatus && checkListTemplateStatus === "Closed" && (
                                                        printClosePOC.map((data, index) => {
                                                            return <img src={`${process.env.REACT_APP_DEFAULTPATH}images/${data.POCFilename}`} />
                                                        })
                                                    )
                                                }
                                            </Row>


                                        </Form>

                                    </div>
                                )
                            }

                            if (checklistType === "Network Audit") {
                                return (
                                    <div className="modal-body">

                                        <Form >

                                            <Row className='my-2'>

                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Checklist ID *  </Label>
                                                        <div>{checkListID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Vulnerability ID *  </Label>
                                                        <div>{vulnerabilityID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Modified By *  </Label>
                                                        <div>{modifier_Name}</div>
                                                    </div>
                                                </Col>


                                            </Row>

                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                        <div>{Title}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                        <div>
                                                            {(() => {

                                                                if (Severity === null) {
                                                                    return <>-</>;
                                                                } else if (Severity === "Critical" || Severity === "High") {
                                                                    return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                } else if (Severity === "Medium" || Severity === "Low") {
                                                                    return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                } else if (Severity === "Informational") {
                                                                    return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                }
                                                            })()}
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Vulnerability Status * </Label>
                                                        <div>  {(() => {

                                                            if (checkListTemplateStatus === null) {
                                                                return <>-</>;
                                                            } else if (checkListTemplateStatus === "Open") {
                                                                return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                            } else if (checkListTemplateStatus === "Closed") {
                                                                return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            } else if (checkListTemplateStatus === "Not Applicable") {
                                                                return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            }
                                                        })()}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                        <div>{CheckListTag}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                <div className="text-secondary">{description}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                <div className="text-secondary">{remediation}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Impact :  </Label>
                                                <div className="text-secondary">{impact}</div>
                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Affects :  </Label>
                                                <div className="text-secondary">{affects}</div>
                                            </Row>

                                            <Row className='my-3'>
                                                <Label className="form-label" htmlFor="employeeSize">POC :  </Label>
                                                {
                                                    checkListTemplateStatus && checkListTemplateStatus === "Open" && (
                                                        printOpenPOC.map((data, index) => {
                                                            return <img src={`${process.env.REACT_APP_DEFAULTPATH}images/${data.POCFilename}`} />
                                                        })
                                                    )
                                                }{
                                                    checkListTemplateStatus && checkListTemplateStatus === "Closed" && (
                                                        printClosePOC.map((data, index) => {
                                                            return <img src={`${process.env.REACT_APP_DEFAULTPATH}images/${data.POCFilename}`} />
                                                        })
                                                    )
                                                }
                                            </Row>

                                        </Form>

                                    </div>
                                )
                            }
                        })()
                    }

                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                // console.log("affects", affects)
                                changeChecklistStatus(
                                    Title, Severity, CheckListTag, checkListTemplateStatus, description, remediation, impact, affects, vulnerabilityID, "Approved")
                                toggle_team_leader_review_modal()

                            }}
                            className="btn btn-success "
                        >
                            Approve
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                changeChecklistStatus(
                                    Title, Severity, CheckListTag, checkListTemplateStatus, description, remediation, impact, affects, vulnerabilityID, "Rejected")
                                toggle_team_leader_review_modal()
                            }}
                            // disabled={detailBtn}
                            className="btn btn-danger "
                        >
                            Reject
                        </button>
                    </div>
                </Modal>


                {/* Auditor and tearm leader View More  MODal to view  */}
                <Modal
                    isOpen={view_more_modal}
                    toggle={() => {
                        toggle_view_more_modal();
                    }}
                    className="modal-xl"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Vulnerability Details -
                            {(() => {
                                if (Lable_status === null) {
                                    return <>-</>;
                                } else if (Lable_status === "Approved") {
                                    return <> <Badge pill className="badge-soft-success ms-1  fs-6">{Lable_status}</Badge></>;
                                } else if (Lable_status === "Rejected") {
                                    return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{Lable_status}</Badge>

                                    </>;
                                } else if (Lable_status === "Not Checked") {
                                    return <> <Badge pill className="badge-soft-info ms-1  fs-6">{Lable_status}</Badge>

                                    </>;
                                } else if (Lable_status === "Draft") {
                                    return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{Lable_status}</Badge>

                                    </>;
                                }
                            })()}
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                toggle_view_more_modal();
                                setview_more_modal(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {
                        (() => {
                            if (checklistType === "Web Application Audit") {
                                return (
                                    <div className="modal-body">

                                        <Form >

                                            <Row className='my-2'>

                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Checklist ID *  </Label>
                                                        <div>{checkListID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Vulnerability ID *  </Label>
                                                        <div>{vulnerabilityID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Modified By *  </Label>
                                                        <div>{modifier_Name}</div>
                                                    </div>
                                                </Col>


                                            </Row>

                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                        <div>{Title}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                        <div>
                                                            {(() => {

                                                                if (Severity === null) {
                                                                    return <>-</>;
                                                                } else if (Severity === "Critical" || Severity === "High") {
                                                                    return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                } else if (Severity === "Medium" || Severity === "Low") {
                                                                    return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                } else if (Severity === "Informational") {
                                                                    return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                }

                                                            })()}

                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Vulnerability Status * </Label>
                                                        <div>  {(() => {

                                                            if (checkListTemplateStatus === null) {
                                                                return <>-</>;
                                                            } else if (checkListTemplateStatus === "Open") {
                                                                return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                            } else if (checkListTemplateStatus === "Closed") {
                                                                return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            } else if (checkListTemplateStatus === "Not Applicable") {
                                                                return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            }
                                                        })()}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                        <div>{CheckListTag}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">CVSS Vector *  </Label>
                                                        <div>{CVSS_vector}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Vulnerability Status * </Label>
                                                        <div>  {(() => {

                                                            if (checkListTemplateStatus === null) {
                                                                return <>-</>;
                                                            } else if (checkListTemplateStatus === "Open") {
                                                                return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                            } else if (checkListTemplateStatus === "Closed") {
                                                                return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            } else if (checkListTemplateStatus === "Not Applicable") {
                                                                return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            }
                                                        })()}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                <div className="text-secondary">{description}</div>
                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Impact :  </Label>
                                                <div className="text-secondary">{impact}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                <div className="text-secondary">{remediation}</div>
                                            </Row>


                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Affects :  </Label>
                                                <div className="text-secondary">{affects}</div>
                                            </Row>

                                            <Row className='my-3'>
                                                <Label className="form-label" htmlFor="employeeSize">POC :  </Label>
                                                {
                                                    checkListTemplateStatus && checkListTemplateStatus === "Open" && (
                                                        printOpenPOC.map((data, index) => {
                                                            console.log(`${process.env.REACT_APP_DEFAULT_IMAGES_PATH}images/${data.POCFilename}`)
                                                            return <><img src={`${process.env.REACT_APP_DEFAULT_IMAGES_PATH}images/${data.POCFilename}`} />
                                                                <p style={{ textAlign: "center", paddingTop: "15px" }}>{data.POCDescription}</p></>
                                                        })
                                                    )
                                                }{
                                                    checkListTemplateStatus && checkListTemplateStatus === "Closed" && (
                                                        printClosePOC.map((data, index) => {
                                                            console.log(`${process.env.REACT_APP_DEFAULT_IMAGES_PATH}images/${data.POCFilename}`)
                                                            return <><img src={`${process.env.REACT_APP_DEFAULT_IMAGES_PATH}images/${data.POCFilename}`} />
                                                                <p style={{ textAlign: "center", paddingTop: "15px" }}>{data.POCDescription}</p></>
                                                        })
                                                    )
                                                }
                                            </Row>


                                        </Form>

                                    </div>
                                )
                            }

                            if (checklistType === "Mobile Application Audit") {
                                return (
                                    <div className="modal-body">

                                        <Form >

                                            <Row className='my-2'>

                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Checklist ID *  </Label>
                                                        <div>{checkListID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Vulnerability ID *  </Label>
                                                        <div>{vulnerabilityID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Modified By *  </Label>
                                                        <div>{modifier_Name}</div>
                                                    </div>
                                                </Col>


                                            </Row>

                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                        <div>{Title}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                        <div>
                                                            {(() => {

                                                                if (Severity === null) {
                                                                    return <>-</>;
                                                                } else if (Severity === "Critical" || Severity === "High") {
                                                                    return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                } else if (Severity === "Medium" || Severity === "Low") {
                                                                    return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                } else if (Severity === "Informational") {
                                                                    return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                }

                                                            })()}

                                                        </div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                        <div>{CheckListTag}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">CVSS Vector *  </Label>
                                                        <div>{CVSS_vector}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Vulnerability Status * </Label>
                                                        <div>  {(() => {

                                                            if (checkListTemplateStatus === null) {
                                                                return <>-</>;
                                                            } else if (checkListTemplateStatus === "Open") {
                                                                return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                            } else if (checkListTemplateStatus === "Closed") {
                                                                return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            } else if (checkListTemplateStatus === "Not Applicable") {
                                                                return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            }
                                                        })()}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                <div className="text-secondary">{description}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                <div className="text-secondary">{remediation}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Impact :  </Label>
                                                <div className="text-secondary">{impact}</div>
                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Affects :  </Label>
                                                <div className="text-secondary">{affects}</div>
                                            </Row>
                                            <Row className='my-3'>
                                                <Label className="form-label" htmlFor="employeeSize">POC :  </Label>
                                                {
                                                    checkListTemplateStatus && checkListTemplateStatus === "Open" && (
                                                        printOpenPOC.map((data, index) => {
                                                            console.log(`${process.env.REACT_APP_DEFAULT_IMAGES_PATH}images/${data.POCFilename}`)
                                                            return <><img src={`${process.env.REACT_APP_DEFAULT_IMAGES_PATH}images/${data.POCFilename}`} />
                                                                <p style={{ textAlign: "center", paddingTop: "15px" }}>{data.POCDescription}</p></>
                                                        })
                                                    )
                                                }{
                                                    checkListTemplateStatus && checkListTemplateStatus === "Closed" && (
                                                        printClosePOC.map((data, index) => {
                                                            console.log(`${process.env.REACT_APP_DEFAULT_IMAGES_PATH}images/${data.POCFilename}`)
                                                            return <><img src={`${process.env.REACT_APP_DEFAULT_IMAGES_PATH}images/${data.POCFilename}`} />
                                                                <p style={{ textAlign: "center", paddingTop: "15px" }}>{data.POCDescription}</p></>
                                                        })
                                                    )
                                                }
                                            </Row>


                                        </Form>

                                    </div>
                                )
                            }

                            if (checklistType === "API Audit") {
                                return (
                                    <div className="modal-body">

                                        <Form >

                                            <Row className='my-2'>

                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Checklist ID *  </Label>
                                                        <div>{checkListID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Vulnerability ID *  </Label>
                                                        <div>{vulnerabilityID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Modified By *  </Label>
                                                        <div>{modifier_Name}</div>
                                                    </div>
                                                </Col>


                                            </Row>

                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                        <div>{Title}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                        <div>
                                                            {(() => {

                                                                if (Severity === null) {
                                                                    return <>-</>;
                                                                } else if (Severity === "Critical" || Severity === "High") {
                                                                    return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                } else if (Severity === "Medium" || Severity === "Low") {
                                                                    return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                } else if (Severity === "Informational") {
                                                                    return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                }

                                                            })()}

                                                        </div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                        <div>{CheckListTag}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">CVSS Vector *  </Label>
                                                        <div>{CVSS_vector}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Vulnerability Status * </Label>
                                                        <div>  {(() => {

                                                            if (checkListTemplateStatus === null) {
                                                                return <>-</>;
                                                            } else if (checkListTemplateStatus === "Open") {
                                                                return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                            } else if (checkListTemplateStatus === "Closed") {
                                                                return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            } else if (checkListTemplateStatus === "Not Applicable") {
                                                                return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                </>;
                                                            }
                                                        })()}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                <div className="text-secondary">{description}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                <div className="text-secondary">{remediation}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Impact :  </Label>
                                                <div className="text-secondary">{impact}</div>
                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Affects :  </Label>
                                                <div className="text-secondary">{affects}</div>
                                            </Row>

                                            <Row className='my-3'>
                                                <Label className="form-label" htmlFor="employeeSize">POC :  </Label>
                                                {
                                                    checkListTemplateStatus && checkListTemplateStatus === "Open" && (
                                                        printOpenPOC.map((data, index) => {
                                                            return <img src={`${process.env.REACT_APP_DEFAULTPATH}images/${data.POCFilename}`} />
                                                        })
                                                    )
                                                }{
                                                    checkListTemplateStatus && checkListTemplateStatus === "Closed" && (
                                                        printClosePOC.map((data, index) => {
                                                            return <img src={`${process.env.REACT_APP_DEFAULTPATH}images/${data.POCFilename}`} />
                                                        })
                                                    )
                                                }
                                            </Row>

                                        </Form>

                                    </div>
                                )
                            }

                            if (checklistType === "Network Audit") {
                                return (
                                    <div className="modal-body">

                                        <Form >

                                            <Row className='my-2'>

                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Checklist ID *  </Label>
                                                        <div>{checkListID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Vulnerability ID *  </Label>
                                                        <div>{vulnerabilityID}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Modified By *  </Label>
                                                        <div>{modifier_Name}</div>
                                                    </div>
                                                </Col>


                                            </Row>

                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Title *  </Label>
                                                        <div>{Title}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="companyLogo">Severity * </Label>
                                                        <div>
                                                            {(() => {

                                                                if (Severity === null) {
                                                                    return <>-</>;
                                                                } else if (Severity === "Critical" || Severity === "High") {
                                                                    return <> <Badge pill className="me-2 bg-danger ms-1  fs-6">{Severity}</Badge></>;
                                                                } else if (Severity === "Medium" || Severity === "Low") {
                                                                    return <> <Badge pill className="me-2 bg-warning ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                } else if (Severity === "Informational") {
                                                                    return <> <Badge pill className="me-2 bg-success ms-1 fs-6 ">{Severity}</Badge>

                                                                    </>;
                                                                }

                                                            })()}

                                                        </div>
                                                    </div>
                                                </Col>

                                                <Row className='my-2'>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">CVSS Vector *  </Label>
                                                            <div>{CVSS_vector}</div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className='my-2'>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Vulnerability Status * </Label>
                                                            <div>  {(() => {

                                                                if (checkListTemplateStatus === null) {
                                                                    return <>-</>;
                                                                } else if (checkListTemplateStatus === "Open") {
                                                                    return <> <Badge pill className="badge-soft-success ms-1  fs-6">{checkListTemplateStatus}</Badge></>;
                                                                } else if (checkListTemplateStatus === "Closed") {
                                                                    return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                    </>;
                                                                } else if (checkListTemplateStatus === "Not Applicable") {
                                                                    return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{checkListTemplateStatus}</Badge>

                                                                    </>;
                                                                }
                                                            })()}</div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Row>
                                            <Row className='my-2'>
                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="employeeSize">Tags *  </Label>
                                                        <div>{CheckListTag}</div>
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Description :  </Label>
                                                <div className="text-secondary">{description}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Remediation :  </Label>
                                                <div className="text-secondary">{remediation}</div>
                                            </Row>

                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Impact :  </Label>
                                                <div className="text-secondary">{impact}</div>
                                            </Row>
                                            <Row className='my-3'>

                                                <Label className="form-label" htmlFor="employeeSize">Affects :  </Label>
                                                <div className="text-secondary">{affects}</div>
                                            </Row>

                                            <Row className='my-3'>
                                                <Label className="form-label" htmlFor="employeeSize">POC :  </Label>
                                                {
                                                    checkListTemplateStatus && checkListTemplateStatus === "Open" && (
                                                        printOpenPOC.map((data, index) => {
                                                            return <img src={`${process.env.REACT_APP_DEFAULTPATH}images/${data.POCFilename}`} />
                                                        })
                                                    )
                                                }{
                                                    checkListTemplateStatus && checkListTemplateStatus === "Closed" && (
                                                        printClosePOC.map((data, index) => {
                                                            return <img src={`${process.env.REACT_APP_DEFAULTPATH}images/${data.POCFilename}`} />
                                                        })
                                                    )
                                                }
                                            </Row>

                                        </Form>

                                    </div>
                                )
                            }
                        })()
                    }

                    <div className="modal-footer">
                        {(() => {
                            // toggle_view_more_modal()
                            // if (role === "Team Leader" || role === "Project Manager" || role === "Admin") {
                            //     return (
                            //         <>
                            //             <button
                            //                 type="button"
                            //                 onClick={() => {

                            //                     changeChecklistStatus_sweetAlert(
                            //                         Title, Severity, CheckListTag
                            //                         , checkListTemplateStatus, description, remediation, impact, affects, vulnerabilityID, "Approved")

                            //                 }}
                            //                 className="btn btn-success "
                            //                 data-dismiss="modal"
                            //             >
                            //                 Approve
                            //             </button>
                            //             <button
                            //                 type="button"
                            //                 onClick={() => {
                            //                     changeChecklistStatus_sweetAlert(
                            //                         Title, Severity, CheckListTag
                            //                         , checkListTemplateStatus, description, remediation, impact, affects, vulnerabilityID, "Rejected")
                            //                 }}
                            //                 // disabled={detailBtn}
                            //                 className="btn btn-danger "
                            //                 data-dismiss="modal"
                            //             >
                            //                 Reject
                            //             </button></>
                            //     )
                            // }
                        })()}
                        <button
                            type="button"
                            className="btn btn-success "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </Modal>

                {/* Add Checklist  */}
                <Modal
                    isOpen={modal_add_auditee}
                    toggle={() => {
                        tog_standard_add_auditee();
                    }}
                    className="modal-xl"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Add CheckList Template
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setmodal_add_auditee(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    {(() => {
                        // console.log("checklist type", checklistType)
                        if (checklistType === "Web Application Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={Severity}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={checkListTemplateStatus}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>








                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Reference :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={reference}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setreference(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        if (checklistType === "Mobile Application Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={empSize}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={empSize}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>








                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Reference :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={reference}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setreference(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        if (checklistType === "API Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={empSize}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={empSize}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>








                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Impact :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={impact}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setimpact(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Reference :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={reference}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setreference(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        if (checklistType === "Network Audit") {
                            return (
                                <div className="modal-body">

                                    <div className="d-flex flex-column h-100">

                                        <div className='webApplication'>

                                            <Form >


                                                <Row className='my-2'>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyLogo">Title  : </Label>
                                                            <Input type="text" className="form-control" id="companyLogo"
                                                                value={Title}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setTitle(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="employeeSize">Severity :  </Label>
                                                            <Select
                                                                // value={empSize}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setSeverity(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_severity}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className='my-2'>

                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Tags : </Label>
                                                            <Input type="text" className="form-control" id="companyEmail"
                                                                value={CheckListTag}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value);
                                                                    setCheckListTag(e.target.value);
                                                                }} />
                                                        </div>
                                                    </Col>    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="companyEmail">Status : </Label> <Select
                                                                // value={empSize}
                                                                onChange={(e) => {
                                                                    // console.log(e.value)
                                                                    setcheckListTemplateStatus(e.value);
                                                                    // handleSelectOptions_empSize(e.value);
                                                                    // console.log(selectedOptions_empSize)
                                                                }}
                                                                options={optionGroup_status}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>








                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Description :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={description}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setdescription(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>


                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Remediation :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={remediation}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setremediation(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                                <Row className='my-3'>
                                                    <Label className="form-label" htmlFor="otherDetails"> Reference :</Label>


                                                    <div className="mb-3" md={6}>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={reference}
                                                            // onReady={editor => {
                                                            //     // You can store the "editor" and use when it is needed.
                                                            //     console.log('Editor is ready to use!', editor);
                                                            // }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({ event, editor, data });
                                                                // console.log(data);
                                                                setreference(data)
                                                                // console.log(event.target.data)
                                                            }}
                                                        // onBlur={(event, editor) => {
                                                        //     console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //     console.log('Focus.', editor);
                                                        // }}
                                                        />
                                                    </div>

                                                    {/* <CustomToolbar /> */}
                                                    {/* <Editor/> */}

                                                </Row>

                                            </Form>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                    })()}
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                tog_standard_add_auditee();
                                setmodal_add_auditee(false);
                            }}
                            className="btn btn-secondary "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                // updatePersonalDetails_sweetAlert()
                                addVulnerabilities()
                            }}
                            className="btn btn-success "
                        // data-dismiss="modal"
                        >
                            Save and Next
                        </button>
                    </div>
                </Modal>



                {/* Generate PDf Modal  */}
                <Modal
                    isOpen={modal_generate_report}
                    toggle={() => {
                        tog_standard_generate_report();
                    }}
                    className="modal-xl"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Download PDF
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setmodal_generate_report(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>


                    <div className="modal-body d-flex justify-content-center align-items-center"

                        style={{ minHeight: "80vh" }}>
                        {report_generated ? <iframe id="displayZone" src={updated_blob} type="application/pdf" style={{ width: "100%", height: "80vh" }}></iframe> : <div className=""><Loader /></div>}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                tog_standard_generate_report();
                                setmodal_generate_report(false);
                            }}
                            className="btn btn-success "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    );
};

export default Audit;
