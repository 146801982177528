import React, { useState, useEffect } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import CountUp from "react-countup";


import FeatherIcon from "feather-icons-react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import "react-table/react-table.css";
import {
    Badge,
    CardBody,
    Spinner,
    Col,
    Modal,
    Row,
    Card,
    Input,
    Button,
    Form, Label, CardHeader,
    CardTitle,
    CardSubtitle,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select"
import { AvForm, AvField } from "availity-reactstrap-validation";

import MetaTags from "react-meta-tags";
import swal from 'sweetalert';
import axios from "axios"
import { BsArrowRight } from "react-icons/bs"
//import images
import Breadcrumbs from "../../../components/Common/Breadcrumb";


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { teamLeader } from "../../../routes/allRoutes";
const Project = () => {

    // const location = useLocation();
    // const history = useHistory()
    // console.log("location", history.location);
    const [productData, setProductData] = useState([]);
    const [modal_standard4, setmodal_standard4] = useState(false); // View More button Modal of ExposedCredentials


    const [modal_add_auditee, setmodal_add_auditee] = useState(false); // View More button Modal of ExposedCredentials
    const [modal_add_checklist, setmodal_add_checklist] = useState(false); // View More button Modal of ExposedCredentials
    const [modal_select_checklist, setmodal_select_checklist] = useState(false); // View More button Modal of ExposedCredentials
    const [modal_select_checklist_template, setmodal_select_checklist_template] = useState(false); // View More button Modal of ExposedCredentials

    const [view_more_modal, setview_more_modal] = useState(false); // View More button Modal of ExposedCredentials
    const [orgNum, setOrgNum] = useState(0);
    const [activeOrg, setActiveOrg] = useState(0);
    const [InActiveOrg, setInActiveOrg] = useState(0);


    // values stored of form
    const [OrgName, setOrgName] = useState("");
    const [status, setstatus] = useState("");

    // validation state error 
    const [emailError, setemailError] = useState("");
    const [OrgNameError, setOrgNameError] = useState("");
    const [OrganizaionID, setOrganizaionID] = useState("");
    const [UserCount, setUserCount] = useState("");
    const [formOrganizationID, setformOrganizationID] = useState(""); // used to send userID of row to backend
    const [detailBtn, setdetailBtn] = useState(true); // to disabled the button on inital


    // function to get uniques ID
    function uniqueIdGenerator() {
        var seq = (Math.floor(Math.random() * 100) + 100).toString().substring(1);
        const id = Date.now() + seq;
        return id;
    }
    const userCheck = localStorage.getItem("AuditorID");

    const [reportID, setReportID] = useState(localStorage.getItem("reportId"));
    const [orgID, setorgID] = useState(localStorage.getItem("orgID"));
    const [findvulnerability, setfindvulnerability] = useState();
    const [rating, setrating] = useState()
    const [vulnerabilityDescription, setvulnerabilityDescription] = useState();
    const [vulnerabilityImpact, setvulnerabilityImpact] = useState();
    const [vulnerabilityRemediation, setvulnerabilityRemediation] = useState();
    const [vulnerabilityAffectedURL, setvulnerabilityAffectedURL] = useState();
    // const [vulnerabilityAffectedURL , setvulnerabilityAffectedURL] =  useState();
    const [vulnerabilityImg1, setvulnerabilityImg1] = useState();
    const [vulnerabilityList, setVulnerabilityList] = useState();


    const [checkListName, setcheckListName] = useState();
    const [companyLogo, setcompanyLogo] = useState();
    // const [empSize, setempSize] = useState();
    const [country, setcountry] = useState();
    const [checkListYear, setcheckListYear] = useState();
    const [companyPhone, setcompanyPhone] = useState();
    const [companyWebsite, setcompanyWebsite] = useState();
    const [city, setcity] = useState();
    const [state, setstate] = useState();
    const [pincode, setpincode] = useState();
    const [otherDetails, setotherDetails] = useState();

    const [checkListVersion, setcheckListVersion] = useState("");
    const [checkList_Type, setcheckList_Type] = useState("");
    const [checkList_Template, setcheckList_Template] = useState("");
    const [checkLIstStatus, setcheckLIstStatus] = useState("");
    const [companyStatus, setcompanyStatus] = useState();
    const [checkListDbId, setcheckListDbId] = useState("");

    const [status_vulnerability_status, setstatus_vulnerability_status] = useState("");
    const [selectedOptions_vulnerability_status, setselectedOptions_vulnerability_status] = useState(null);

    const [projectName, setprojectName] = useState();
    const [companyName, setcompanyName] = useState();
    const [displayCompanyName, setdisplayCompanyName] = useState();
    // const [checkList_Type , setcheckList_Type] = useState();
    const [applicationScreenshot, setapplicationScreenshot] = useState();
    const [projectAssignTo, setprojectAssignTo] = useState();
    const [applicationEnvironment, setapplicationEnvironment] = useState();
    const [applicationAccess, setapplicationAccess] = useState();
    const [projectAssignToAuditor, setprojectAssignToAuditor] = useState("");
    const [displayProjectAssignTo, setdisplayProjectAssignTo] = useState();
    const [displayAuditorName, setdisplayAuditorName] = useState();
    const [projectStartDate, setprojectStartDate] = useState();
    const [projectEndDate, setprojectEndDate] = useState();
    const [scopeDescription, setscopeDescription] = useState();
    const [projectDescription, setprojectDescription] = useState();
    const [tempCompanyList, settempCompanyList] = useState([]);
    const [tempUserList, settempUserList] = useState([]);
    const [projectID, setprojectID] = useState()
    const [auditorList, setauditorList] = useState([])
    const [modal_assign_to, setmodal_assign_to] = useState()
    const [projectAssignBy, setprojectAssignBy] = useState()
    const [auditorID, setauditorID] = useState()
    const [teamLeaderID, setteamLeaderID] = useState()
    const [projectStatus, setprojectStatus] = useState()
    const [AuditorName, setAuditorName] = useState()
    const [ProjectManagerName, setProjectManagerName] = useState()
    const [TeamLeaderName, setTeamLeaderName] = useState()
    const [applicationName, setapplicationName] = useState()
    const [applicationURL, setapplicationURL] = useState()
    const [defaultProjectStatus, setdefaultProjectStatus] = useState()
    const [checklist_template_list, setchecklist_template_list] = useState([])
    const [checklist_template_list_data, setchecklist_template_list_data] = useState()



    function year_list() {

        var yearList = new Date().getFullYear(); // 2020
        var year_arr = []
        let i = 0;
        while (i < 20) {
            year_arr[i] = { label: yearList, value: yearList }
            yearList = yearList - 1;
            i++;
        }
        // console.log(year_arr); // 2019
        return year_arr;
    }


    function getCompanyList() {
        let i = 0;
        var option_group_companyList = []
        while (i < tempCompanyList.length) {
            option_group_companyList[i] = { label: tempCompanyList[i].name, value: tempCompanyList[i].ID }
            i++;
        }
        // console.log("option", option_group_companyList)
        return option_group_companyList;
    }

    function getUserList() {
        let i = 0;
        let option_group_User_List = []
        while (i < tempUserList.length) {
            option_group_User_List[i] = { label: tempUserList[i].name, value: tempUserList[i].ID }
            i++;
        }
        // console.log("option", option_group_User_List)
        return option_group_User_List;
    }

    function getAuditorUserList() {
        let i = 0;
        let option_group_User_List = []
        while (i < auditorList.length) {
            option_group_User_List[i] = { label: auditorList[i].name, value: auditorList[i].ID }
            i++;
        }
        // console.log("option", option_group_User_List)
        return option_group_User_List;
    }
    function get_checklist_template_list() {
        let i = 0;
        let option_group_User_List = []
        while (i < checklist_template_list.length) {
            option_group_User_List[i] = { label: checklist_template_list[i].ChecklistDatabaseName, value: checklist_template_list[i].ChecklistDatabaseID }
            i++;
        }
        // console.log("option", option_group_User_List)
        return option_group_User_List;
    }


    async function companyList() {
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}get-auditor-auditee-companylist`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            // body: JSON.stringify({
            //     userCheck,
            // }),
        });
        const data = await response.json();
        if (data) {
            const newdata = data;
            // console.log("data retrived- company", newdata.data);
            settempCompanyList(newdata.data)

            // setRole(newdata.Role)

            // setphone(newdata.Phone);
            // setjobTitle(newdata.JobTitle);
            // console.log("main profile", newdata.FirstName);
        } else {
            alert("data retreival error");
        }
    }

    // function to get team leader list 
    async function UserList() {
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}get-auditor-users`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            // body: JSON.stringify({
            //     userCheck,
            // }),
        });
        const data = await response.json();
        if (data) {
            const newdata = data;
            console.log("data retrived- company", newdata.data);
            settempUserList(newdata.data)

            // setRole(newdata.Role)

            // setphone(newdata.Phone);
            // setjobTitle(newdata.JobTitle);
            // console.log("main profile", newdata.FirstName);
        } else {
            alert("data retreival error");
        }
    }

    // function to get auditors   list 
    async function AuditorUserList() {
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}get-auditor-auditor-users`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            // body: JSON.stringify({
            //     userCheck,
            // }),
        });
        const data = await response.json();
        if (data) {
            const newdata = data;
            // console.log("data retrived- company", newdata.data);
            setauditorList(newdata.data)

            // setRole(newdata.Role)

            // setphone(newdata.Phone);
            // setjobTitle(newdata.JobTitle);
            // console.log("main profile", newdata.FirstName);
        } else {
            alert("data retreival error");
        }
    }

    // function to get checklist template list 
    async function check_checklist_template_list() {
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}get-auditor-specific-checklist-template`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                checkList_Type,
            }),
        });
        const data = await response.json();
        if (data) {
            const newdata = data;
            console.log("checklost templaee", newdata.data);
            setchecklist_template_list(newdata.data)

            // setRole(newdata.Role)

            // setphone(newdata.Phone);
            // setjobTitle(newdata.JobTitle);
            // console.log("main profile", newdata.FirstName);
        } else {
            alert("data retre")
        }
    }

    const optionGroup_checklIst_version = [
        { label: "v7.3 ", value: "v7.3 " },
        { label: "v3.2", value: "v3.2" },
    ];
    const optionGroup_checkListStatus = [
        { label: "Initiated", value: "Initiated" },
        { label: "InProgress", value: "InProgress" },
        { label: "Completed", value: "Completed" },
    ];

    const optionGroup_ApplicationEnvironment = [
        { label: "Development", value: "Development" },
        { label: "Staging", value: "Staging" },
        { label: "Production", value: "Production" },
    ];

    const optionGroup_ApplicationAccess = [
        { label: "Credential", value: "Credential" },
        { label: "Non-Credential", value: "Non-Credential" },
        { label: "Hybrid", value: "Hybrid" },
    ];
    const optionGroup_checkList_Type = [
        { label: "Web Application Audit", value: "Web Application Audit" },
        { label: "Mobile Application Audit", value: "Mobile Application Audit" },
        { label: "API Audit", value: "API Audit" },
        { label: "Network Audit", value: "Network Audit" }
    ];

    // form validatioon checkuo end ------------->>>>>>>>>>>>>>>>>>>>>>>>>>

    // function to handle change in status dropdown
    //  function handleSelectOptions_empSize(selectedOptions_empSize) {
    //     setselectedOptions_empSize(selectedOptions_empSize);
    // }

    // function to handle change in status dropdown
    function handleSelectOptions_severity(selectedOptions_severity) {
        // setselectedOptions_severity(selectedOptions_severity);
    }
    // function to handle change in status dropdown
    function handleSelectOptions_vulnerability_status(selectedOptions_vulnerability_status) {
        setselectedOptions_vulnerability_status(selectedOptions_vulnerability_status);
    }




    const [selectedOptions, setselectedOptions] = useState(null)
    // const animatedComponents = makeAnimated()
    // const orgID = localStorage.getItem("orgID");
    const token = localStorage.getItem("token");

    const optionGroup = [
        { label: "Enable", value: "Enable" },
        { label: "Disabled", value: "Disabled" },
    ]
    // Columns of DataTable
    const columns = [
        {
            dataField: "ProjectID",
            text: "Project ID",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        console.log("productData", productData)
                        if (productData.ProjectID === null) {
                            return <>-</>;
                        } else {
                            return <>
                                <Badge className="me-2 bg-primary p-1 fs-6">
                                    {productData.ProjectID}
                                </Badge>
                            </>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "ProjectTitle",
            text: "Project Title",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.ProjectTitle === null) {
                            return <>-</>;
                        } else {
                            return <td className="fw-bold">{productData.ProjectTitle}</td>;
                        }
                    })()}
                </>
            ),
        },
        
        {
            dataField: "OrganizationName",
            text: "Auditee Org.",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.OrganizationName === null) {
                            return <>-</>;
                        } else {
                            return <Badge pill className="badge-soft-primary ms-1  fs-6">{productData.OrganizationName}</Badge>
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "AuditType",
            text: "Audit Type ",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.AuditType === null) {
                            return <>-</>;
                        } else {
                            return <Badge pill className="badge-soft-warning ms-1  fs-6" style={{color : "#000000"}}>{productData.AuditType}</Badge>;
                        }
                    })()}
                </>
            ),
        },
        
        {
            dataField: "ProjectManagerName",
            text: "Auditors",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.ProjectManagerName === null) {
                            return <>-</>;
                        } else {
                            return <>
                                <tr>{productData.ProjectManagerName}</tr>
                                <tr>{productData.TeamLeaderName}</tr>
                                <tr>{productData.AuditorName}</tr>
                                </>;
                            // <td>  <Badge pill className="me-2 bg-danger ">{productData.Name}</Badge></td></>;
                        }
                    })()}
                </>
            ),
        },
        // {
        //     dataField: "TeamLeaderName",
        //     text: "Team  Leader",
        //     sort: true,
        //     formatter: (cellContent, productData) => (
        //         <>
        //             {(() => {
        //                 if (productData.TeamLeaderName === null) {
        //                     return <>-</>;
        //                 } else {
        //                     return <>
        //                         <td>{productData.TeamLeaderName}</td></>;
        //                     // <td>  <Badge pill className="me-2 bg-danger ">{productData.Name}</Badge></td></>;
        //                 }
        //             })()}
        //         </>
        //     ),
        // },
        // {
        //     dataField: "AuditorName",
        //     text: "Auditor",
        //     sort: true,
        //     formatter: (cellContent, productData) => (
        //         <>
        //             {(() => {
        //                 if (productData.AuditorName === null) {
        //                     return <>-</>;
        //                 } else {
        //                     return <>
        //                         <td>{productData.AuditorName}</td></>;
        //                     // <td>  <Badge pill className="me-2 bg-danger ">{productData.Name}</Badge></td></>;
        //                 }
        //             })()}
        //         </>
        //     ),
        // },

        // await axios.post('http://localhost:1338/get-user-by-id',{id : productData.ProjectAssignTo}).
        // then((res) => {
        // });
        // {
        //     dataField: "Status",
        //     text: "Status",
        //     sort: true,
        //     formatter: (cellContent, productData) => (
        //         <>
        //             {(() => {
        //                 if (productData.Status === null) {
        //                     return <>-</>;
        //                 } else if (productData.Status === "Enable") {
        //                     return <> <Badge pill className="badge-soft-success ms-1 fs-6 ">{productData.Status}</Badge></>;
        //                 } else if (productData.Status === "Disabled") {
        //                     return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{productData.Status}</Badge></>;
        //                 }
        //             })()}
        //         </>
        //     ),
        // },
        {
            dataField: "ProjectStatus",
            text: "Status",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.ProjectStatus === null) {
                            return <>-</>;
                        } else if (productData.ProjectStatus === "Initiated") {
                            return <> <Badge pill className="badge-soft-success ms-1  fs-6">{productData.ProjectStatus}</Badge></>;
                        } else if (productData.ProjectStatus === "InProgress") {
                            return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{productData.ProjectStatus}</Badge>
                            </>;
                        } else if (productData.ProjectStatus === "Completed") {
                            return <> <Badge pill className="badge-soft-info ms-1  fs-6">{productData.ProjectStatus}</Badge>
                            </>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Action",
            text: "Action",
            sort: true,
            formatter: (cellContent, productData) => (

                <>
                    {/* {console.log("without cliecekd", productData)} */}
                    {
                        // (() => {
                        //     if (role === "Admin" || role === "Project Manager") {
                        //         return (
                        //             <>
                        //                 <Button
                        //                     color="warning"
                        //                     className="btn-sm me-2"
                        //                     // Disabled={role === "Admin" ? false : true}
                        //                     onClick={() => {
                        //                         console.log("cliecekd", productData);
                        //                         // tog_standard4();
                        //                         setprojectID(productData.ProjectID)
                        //                         setprojectName(productData.ProjectTitle)
                        //                         setdisplayCompanyName(productData.OrganizationName)
                        //                         setcompanyName(productData.AuditeeOrganizationID)
                        //                         setcheckList_Type(productData.AuditType)
                        //                         setdisplayProjectAssignTo(productData.TeamLeaderName)
                        //                         setdisplayAuditorName(productData.AuditorName)
                        //                         setprojectAssignTo(productData.TeamLeaderID)
                        //                         setprojectAssignToAuditor(productData.AuditorID)
                        //                         setprojectStartDate(productData.StartDate)
                        //                         setprojectEndDate(productData.EndDate)
                        //                         setscopeDescription(productData.ProjectScope)
                        //                         setprojectDescription(productData.ProjectDescription)

                        //                         {(()=>{
                        //                             if(productData.AuditType === "Mobile Application Audit"){
                        //                                 setapplicationName(productData.ApplicationName)
                        //                                 setapplicationURL(productData.ApplicationFilename) 
                        //                                 setapplicationScreenshot(productData.ApplicationScreenshot)
                        //                                 setapplicationEnvironment(productData.ApplicationEnvironment)
                        //                                 setapplicationAccess(productData.ApplicationAccess)
                        //                             }

                        //                             if(productData.AuditType === "Web Application Audit"){
                        //                                 setapplicationName(productData.ApplicationName)
                        //                                 setapplicationURL(productData.ApplicationURL) 
                        //                                 setapplicationScreenshot(productData.ApplicationScreenshot)
                        //                                 setapplicationEnvironment(productData.ApplicationEnvironment)
                        //                                 setapplicationAccess(productData.ApplicationAccess)
                        //                             }

                        //                             if(productData.AuditType === "API Audit"){
                        //                                 setapplicationName(productData.APIName)
                        //                                 setapplicationURL(productData.APIURL) 
                        //                                 setapplicationScreenshot(productData.APIScreenshot)
                        //                                 setapplicationEnvironment(productData.APIEnvironment)
                        //                                 setapplicationAccess(productData.APIAccess)
                        //                             }

                        //                             if(productData.AuditType === "Network Audit"){
                        //                                 console.log("network")
                        //                                 setapplicationName(productData.NetworkName)
                        //                                 setapplicationURL(productData.NetworkIP) 
                        //                                 setapplicationScreenshot(productData.NetworkScreenshot)
                        //                                 setapplicationEnvironment(productData.NetworkEnvironment)
                        //                                 setapplicationAccess(productData.NetworkAccess)
                        //                             }
                        //                         })()}
                        //                         // setapplicationURL("fcghjk")
                        //                         // {productData.AuditType === "Mobile Application Audit" ? setapplicationURL(productData.ApplicationFilename) : productData.AuditType === "Web Application Audit" ? setapplicationURL(productData.ApplicationURL) : productData.AuditType === "API Audit" ? setapplicationURL(productData.APIURL) : setapplicationURL(productData.NetworkIP)}
                        //                         // setapplicationURL()
                        //                         // setapplicationName(productData.ApplicationName)
                        //                         setdefaultProjectStatus(productData.ProjectStatus)
                        //                         tog_standard4();
                        //                         // setmodalPopupData(productData.UserCount)
                        //                     }}
                        //                 >
                        //                     Edit
                        //                 </Button>
                        //                 <Button
                        //                     color="danger"
                        //                     className="btn-sm me-2"
                        //                     // Disabled={role === "Admin" ? false : true}
                        //                     onClick={() => {
                        //                         updatePersonalDetails_sweetAlert(productData.ProjectID)
                        //                     }}
                        //                 >

                        //                     Delete

                        //                 </Button>
                        //             </>
                        //         )
                        //     }

                        //     if (role === "Team Leader") {
                        //         return (
                        //             <Button
                        //                 className="btn-sm me-2"
                        //                 color="danger"
                        //                 onClick={() => {
                        //                     setcheckList_Type(productData.AuditType)
                        //                     console.log("cliecekd", checkList_Type);

                        //                     setprojectID(productData.ProjectID)
                        //                     tog_standard_assign_to();
                        //                     // setmodalPopupData(productData.UserCount)
                        //                 }}
                        //             >

                        //                 Edit

                        //             </Button>)
                        //     }
                        // })()
                    }




                    {/* <Link to={`/auditor/checklist/certificate/${productData.ChecklistDatabaseID}`} className="text-white"> */}
                    <Button
                        className="btn-sm me-2"
                        color="primary"
                        onClick={() => {
                            console.log("product data ->", productData)
                            setcheckList_Type(productData.AuditType)
                            setprojectID(productData.ProjectID)
                            setprojectName(productData.ProjectTitle)
                            // setdisplayCompanyName(productData.OrganizationName)
                            // setcompanyName(productData.AuditeeOrganizationID)
                            // setapplicationScreenshot(productData.OrganizationName)
                            setteamLeaderID(productData.TeamLeaderID)
                            // setdisplayProjectAssignTo(productData.Name)
                            setprojectAssignBy(productData.ProjectManagerID)
                            setauditorID(productData.AuditorID)
                            setprojectStartDate(productData.StartDate)
                            setprojectEndDate(productData.EndDate)
                            setscopeDescription(productData.ProjectScope)
                            setprojectDescription(productData.ProjectDescription)
                            setAuditorName(productData.AuditorName)
                            setProjectManagerName(productData.ProjectManagerName)
                            setTeamLeaderName(productData.TeamLeaderName)
                            setprojectStatus(productData.ProjectStatus)
                            toggle_view_more_modal()
                            // console.log("cliecekd", [productData]);
                            // setprojectID(productData.ProjectID)
                            // tog_standard4();
                            // setmodalPopupData(productData.UserCount)
                        }}
                    >

                        View




                    </Button>

                    <Link to={`/project/${productData.ProjectID}`} className="text-white">
                        <Button
                            className="btn-sm me-2"
                            color="primary"
                        // onClick={() => {
                        //     // console.log("cliecekd", [productData]);
                        //     tog_standard4();
                        //     setmodalPopupData(productData.UserCount)
                        // }}
                        >

                            Audit

                        </Button>
                    </Link>


                    {/* {(() => {
                        if (role === "Auditor" || role === "Admin" || role === "Project Manager") {
                            return (

                            )
                        }
                    })()} */}
                </>
            ),
        },
    ];
    // Sorting of Data Table
    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    // Page Options in Data Table 
    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    };
    const { SearchBar } = Search; // Search Bar in Data TAble

    var mainData = [];
    var name_regx = /^[A-Za-z ]{3,20}$/;

    useEffect(async () => {

        getUser();
        getCustomers();
        // companyList()
        // UserList()
        // AuditorUserList()
        // check_checklist_template_list()
    }, []);


    function tog_standard4() {
        setmodal_standard4(!modal_standard4);
        // toggle for view more button 
    }
    function tog_standard_add_auditee() {
        setmodal_add_auditee(!modal_add_auditee);
        // toggle for view more button 
    }
    function tog_standard_select_checklist() {
        setmodal_select_checklist(!modal_select_checklist);
        // toggle for view more button 
    }
    function tog_standard_select_checklist_template() {
        setmodal_select_checklist_template(!modal_select_checklist_template);
        // toggle for view more button 
    }
    function tog_standard_assign_to() {
        setmodal_assign_to(!modal_assign_to);
        // toggle for view more button 
    } function toggle_view_more_modal() {
        setview_more_modal(!view_more_modal);
        // toggle for view more button 
    }

    function tog_standard_add_checklist() {
        setmodal_add_checklist(!modal_add_checklist);
        // toggle for view more button 
    }

    async function addVulnerabilities() {
        var formData = new FormData();
        console.log("add vulner")
        formData.append("projectName", projectName);
        formData.append("companyName", companyName);
        formData.append("checkList_Type", checkList_Type);
        formData.append("applicationScreenshot", applicationScreenshot);
        formData.append("applicationEnvironment", applicationEnvironment);
        formData.append("applicationAccess", applicationAccess);
        formData.append("projectAssignTo", projectAssignTo);
        formData.append("projectAssignToAuditor", projectAssignToAuditor);
        formData.append("projectStartDate", projectStartDate)
        formData.append("projectEndDate", projectEndDate)
        formData.append("scopeDescription", scopeDescription)
        formData.append("projectDescription", projectDescription)
        formData.append("projectAssignBy", userCheck)
        formData.append("applicationName", applicationName)
        formData.append("applicationURL", applicationURL)
        formData.append("auditorID", userCheck)
        formData.append("defaultProjectStatus", defaultProjectStatus)
        formData.append("checklistDatabaseID", checklist_template_list_data)
        // formData.append("status_vulnerability_status", status_vulnerability_status);
        // formData.append("vulnerabilityImg1", vulnerabilityImg1[0], "vulnerability_status_img.png");
        // console.log(checkListName, checkListVersion, checkListYear, checkLIstStatus)
        // console.log()


        try {
            // const response = await fetch('http://localhost:1338/add-auditee', {
            //     method : "POST",
            //     headers : {
            //         "content-Type" : "application/json"
            //     },
            //     body:formData
            // })
            await axios.post(`${process.env.REACT_APP_DEFAULTPATH}add-auditor-projects`, formData).
                then(() => {
                    getCustomers();
                    // // tog_standard4
                    // tog_standard_add_auditee()
                    // tog_standard_add_checklist()
                    // window.location.reload();
                });

        } catch (error) {
            console.log(error)
        }
    }
    const [role, setRole] = useState()
    // to get userLSit 
    async function getUser(event) {
        // event.preventDefault();
        // console.log("token passes " , userCheck)
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}user-profile`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({
                userCheck: localStorage.getItem("AuditorID"),
                orgID: localStorage.getItem("orgID")
            }),
        });
        const data = await response.json();
        if (data) {
            const newdata = data.user;
            console.log("data retrived", newdata);
            setRole(newdata.Role)

            // setphone(newdata.Phone);
            // setjobTitle(newdata.JobTitle);
            // console.log("main profile", newdata.FirstName);
        } else {
            alert("data retreival error");
        }
    }

    // ApI to hit Task Data 
    async function getCustomers(event) {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}get-auditor-projects`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({
                    userCheck,
                    orgID
                })
            }
        );
        const data = await response.json();
        if (data) {
            setstartAuditPreloader(false)
            console.log("projects", data);
            // mainData = await data.data[0].data.entries;
            mainData = await data;
            console.log(",amaindata ", mainData.data)
            setProductData(mainData.data);

            // setOrgNum(mainData.data.length)
            // // console.log(mainData)
            // var ACtiveCounter = 0;
            // var InactiveCounter = 0
            // for (var i = 0; i < mainData.data.length; i++) {
            //     if (mainData.data[i].Status === "Active") {
            //         ACtiveCounter += 1;
            //     }
            //     else {
            //         InactiveCounter += 1;
            //     }
            // }
            // setActiveOrg(ACtiveCounter);
            // setInActiveOrg(InactiveCounter)
            // console.log("tempData", TempData)
            // setProductData(TempData);
            // setIpCardPrice(TempData[1].Value.length)
            // setdomainCard(TempData[0].Value.length)
            // setSubdomainCardPrice(TempData[2].Value.length)
        } else {
            setstartAuditPreloader(false)
            // alert("data retreival error");
        }
    }

    // function to send data to backend 
    async function updateRow(event) {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}/auditee-list`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    OrgID: OrganizaionID,
                    OrgName,
                    status
                }),
            }
        );
        const data = await response.json();
        if (data.status) {
        }
        else {
            setemailError("Email already exist")
        }

    }

    //function to assign to functionality 
    async function assignProjectTo() {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}edit-auditor-project-auditor`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    projectID,
                    projectAssignBy: userCheck,
                    projectAssignTo
                })
            }
        );
        const data = await response.json();
        if (data) {
            console.log("projects", data);
            // mainData = await data.data[0].data.entries;
            // mainData = await data;
            getCustomers()
        } else {
            // alert("data retreival error");
        }
    }

    // Swwet alert or modal to confirm detials  should modify for perosnal Details 
    function updatePersonalDetails_sweetAlert(id) {
        console.log("selected GRoup", id)
        swal({
            title: "Are you sure?",
            text: "Once done, you will update your personal details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (deleteProject(id)) {
                        swal("Poof! Your perosnal details has been updated!", {
                            icon: "success",
                            buttons: true
                        }).then((reload) => {
                            if (reload) {
                                getCustomers()
                                // window.location.reload(false);
                            }
                        })
                    }
                    else {
                        swal("Reload operation has been aborted!");
                    }
                } else {
                    swal("Operation has been aborted!");
                }
            });
    }

    async function editCheckListDb(id) {
        var formData = new FormData();
        formData.append("projectID", projectID);
        formData.append("projectName", projectName);
        formData.append("companyName", companyName);
        formData.append("checkList_Type", checkList_Type);
        formData.append("applicationScreenshot", applicationScreenshot);
        formData.append("applicationEnvironment", applicationEnvironment);
        formData.append("applicationAccess", applicationAccess);
        formData.append("projectAssignTo", projectAssignTo);
        formData.append("projectStartDate", projectStartDate)
        formData.append("projectEndDate", projectEndDate)
        formData.append("scopeDescription", scopeDescription)
        formData.append("projectDescription", projectDescription)
        // formData.append("projectAssignBy", userCheck)
        formData.append("projectAssignToAuditor", projectAssignToAuditor);
        formData.append("projectAssignBy", userCheck)
        formData.append("applicationName", applicationName)
        formData.append("applicationURL", applicationURL)
        formData.append("auditorID", userCheck)
        formData.append("defaultProjectStatus", defaultProjectStatus)

        try {
            // const response = await fetch('http://localhost:1338/add-auditee', {
            //     method : "POST",
            //     headers : {
            //         "content-Type" : "application/json"
            //     },
            //     body:formData
            // })
            await axios.post(`${process.env.REACT_APP_DEFAULTPATH}edit-auditor-projects`, formData).
                then(() => {
                    getCustomers()
                    // window.location.reload();
                });

        } catch (error) {
            console.log(error)
        }
    }

    async function deleteProject(id) {
        console.log("inside edit", id)
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}delete-auditor-project`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ProjectID: id
                }),
            }
        );
        const data = await response.json();
        if (data.status) {
        }
        else {
            setemailError("Email already exist")
        }
    }

    // functions to validate regex OrgName
    function checkOrgNameValidation(OrgName_val) {
        if (OrgName_val === " ") {
            setOrgNameError("enter first name")
            setdetailBtn(true)
        }
        else if (name_regx.test(OrgName_val)) {
            setOrgNameError("")
            setdetailBtn(false)
        }
        else if (OrgName_val.length < 3) {
            setOrgNameError(" must contain 3 characters")
            setdetailBtn(true)
        }
        else {
            setOrgNameError(`Organization doesn't contain "0-9  , & @ "`);
            setdetailBtn(true);
        }
    }

    // function to handle change in status dropdown
    function handleSelectOptions(selectedOptions) {
        setselectedOptions(selectedOptions);
        setdetailBtn(false);
    }
    const [startAuditPreloader, setstartAuditPreloader] = useState(true)

    return (
        <React.Fragment>

            {startAuditPreloader ?
                <div className="transparent-loader">
                    <Spinner type="grow" className="ms-2" color="primary" /> </div> : <></>}
            <div className="userProfile credentialBreachProfile">
                <MetaTags>
                    <title>Projects | AuditSense</title>
                </MetaTags>
                <Row>
                    <Breadcrumbs
                        title="Dashboard"
                        breadcrumbItem="Project List"
                        breadcrumbFeature="Projects"
                    />
                </Row>


                {(() => {
                    if (role === "Project Manager") {
                        return (
                            <div className="text-end">
                                <Button className="btn-success" onClick={() => {
                                    // console.log("asdas")
                                    setdefaultProjectStatus("Initiated")
                                    tog_standard_select_checklist()
                                }}><i className="bx bx-buildings"></i> &nbsp; Add Project</Button>
                            </div>
                        )
                    }
                })()}


                <Row>

                    {/* EDIT MODAL  */}
                    <Modal
                        isOpen={modal_standard4}
                        toggle={() => {
                            tog_standard4();
                        }}
                        className="modal-xl"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                {checkList_Type === "Web Application Audit" ? "Edit Web Application Audit Checklist Database" : checkList_Type === "Mobile Application Audit" ? "Edit Mobile Application Audit Checklist Database" : checkList_Type === "API Audit" ? "Edit API Audit Checklist Database" : checkList_Type === "Network Audit" ? "Edit Network Audit Checklist Database" : ""}
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_standard4(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>



                        {(() => {
                            if (checkList_Type === "Web Application Audit") {
                                return (
                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <div>
                                                    <Form >

                                                        <Row className='my-2'>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyEmail">Company Name : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        // value={companyName}
                                                                        placeholder={displayCompanyName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcompanyName(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getCompanyList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyName">Project Title: </Label>
                                                                    <Input type="text" className="form-control" id="companyName"
                                                                        value={projectName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectName(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                        <Row className='my-2'>

                                                            {/* <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="country">Select CheckList type : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        placeholder={checkList_Type}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcheckList_Type(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkList_Type}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col> */}

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Name :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={applicationName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationName(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Screenshot :  </Label>
                                                                    <Input type="file" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        // value={applicationScreenshot}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationScreenshot(e.target.files[0]);
                                                                        }} />

                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Application Environment : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={applicationEnvironment}
                                                                        // value={statusCountry}`
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationEnvironment(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationEnvironment}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>  <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Application Access : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={applicationAccess}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationAccess(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationAccess}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Application URL :</Label>


                                                            <div className="mb-3 " md={6} >
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={applicationURL}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    // value={}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setapplicationURL(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                        </Row>

                                                        <Row className='my-2 '>


                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Start Date :   </Label>
                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectStartDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectStartDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project End Date :  </Label>

                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectEndDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectEndDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={4}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Project Status: </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={defaultProjectStatus}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setdefaultProjectStatus(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkListStatus}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row className='my-2 '>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Team Leader : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={displayProjectAssignTo}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Auditor : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={displayAuditorName}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignToAuditor(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getAuditorUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>



                                                        </Row>
                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Project Description :</Label>


                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={projectDescription}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setprojectDescription(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }

                            if (checkList_Type === "Mobile Application Audit") {
                                return (
                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <div>
                                                    <Form >

                                                        <Row className='my-2'>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyEmail">Company Name : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        // value={companyName}
                                                                        placeholder={displayCompanyName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcompanyName(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getCompanyList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyName">Project Title: </Label>
                                                                    <Input type="text" className="form-control" id="companyName"
                                                                        value={projectName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectName(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                        <Row className='my-2'>

                                                            {/* <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="country">Select CheckList type : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        placeholder={checkList_Type}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcheckList_Type(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkList_Type}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col> */}

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Name :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={applicationName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationName(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Screenshot :  </Label>
                                                                    <Input type="file" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        // value={applicationScreenshot}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationScreenshot(e.target.files[0]);
                                                                        }} />

                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Application Environment : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={applicationEnvironment}
                                                                        // value={statusCountry}`
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationEnvironment(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationEnvironment}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>  <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Application Access : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={applicationAccess}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationAccess(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationAccess}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Application Filename :</Label>


                                                            <div className="mb-3 " md={6} >
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={applicationURL}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    // value={}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setapplicationURL(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                        </Row>

                                                        <Row className='my-2 '>


                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Start Date :   </Label>
                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectStartDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectStartDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project End Date :  </Label>

                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectEndDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectEndDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={4}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Project Status: </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={defaultProjectStatus}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setdefaultProjectStatus(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkListStatus}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row className='my-2 '>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Team Leader : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={displayProjectAssignTo}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Auditor : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={displayAuditorName}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignToAuditor(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getAuditorUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>



                                                        </Row>
                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Project Description :</Label>


                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={projectDescription}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setprojectDescription(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }

                            if (checkList_Type === "API Audit") {
                                return (
                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <div>
                                                    <Form >

                                                        <Row className='my-2'>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyEmail">Company Name : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        // value={companyName}
                                                                        placeholder={displayCompanyName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcompanyName(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getCompanyList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyName">Project Title: </Label>
                                                                    <Input type="text" className="form-control" id="companyName"
                                                                        value={projectName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectName(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                        <Row className='my-2'>

                                                            {/* <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="country">Select CheckList type : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        placeholder={checkList_Type}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcheckList_Type(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkList_Type}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col> */}

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">API Name :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={applicationName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationName(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">API Screenshot :  </Label>
                                                                    <Input type="file" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        // value={applicationScreenshot}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationScreenshot(e.target.files[0]);
                                                                        }} />

                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">API Environment : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={applicationEnvironment}
                                                                        // value={statusCountry}`
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationEnvironment(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationEnvironment}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>  <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">API Access : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={applicationAccess}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationAccess(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationAccess}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">API URL :</Label>


                                                            <div className="mb-3 " md={6} >
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={applicationURL}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    // value={}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setapplicationURL(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                        </Row>

                                                        <Row className='my-2 '>


                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Start Date :   </Label>
                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectStartDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectStartDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project End Date :  </Label>

                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectEndDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectEndDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={4}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Project Status: </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={defaultProjectStatus}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setdefaultProjectStatus(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkListStatus}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row className='my-2 '>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Team Leader : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={displayProjectAssignTo}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Auditor : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={displayAuditorName}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignToAuditor(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getAuditorUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>



                                                        </Row>
                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Project Description :</Label>


                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={projectDescription}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setprojectDescription(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }

                            if (checkList_Type === "Network Audit") {
                                return (
                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <div>
                                                    <Form >

                                                        <Row className='my-2'>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyEmail">Company Name : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        // value={companyName}
                                                                        placeholder={displayCompanyName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcompanyName(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getCompanyList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyName">Project Title: </Label>
                                                                    <Input type="text" className="form-control" id="companyName"
                                                                        value={projectName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectName(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                        <Row className='my-2'>

                                                            {/* <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="country">Select CheckList type : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        placeholder={checkList_Type}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcheckList_Type(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkList_Type}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col> */}

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Network Name :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={applicationName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationName(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Network Screenshot :  </Label>
                                                                    <Input type="file" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        // value={applicationScreenshot}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationScreenshot(e.target.files[0]);
                                                                        }} />

                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Network Environment : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={applicationEnvironment}
                                                                        // value={statusCountry}`
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationEnvironment(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationEnvironment}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>  <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Network Access : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={applicationAccess}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationAccess(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationAccess}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Network IP :</Label>


                                                            <div className="mb-3 " md={6} >
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={applicationURL}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    // value={}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setapplicationURL(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                        </Row>

                                                        <Row className='my-2 '>


                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Start Date :   </Label>
                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectStartDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectStartDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project End Date :  </Label>

                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectEndDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectEndDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={4}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Project Status: </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={defaultProjectStatus}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setdefaultProjectStatus(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkListStatus}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row className='my-2 '>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Team Leader : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={displayProjectAssignTo}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Auditor : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={displayAuditorName}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignToAuditor(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getAuditorUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>



                                                        </Row>
                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Project Description :</Label>


                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={projectDescription}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setprojectDescription(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }
                        })()}
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    tog_standard4();
                                    setmodal_standard4(false);
                                }}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    // updatePersonalDetails_sweetAlert()
                                    editCheckListDb();
                                    tog_standard4();
                                }}
                                // Disabled={detailBtn}
                                className="btn btn-primary "
                                data-dismiss="modal"
                            >
                                Save
                            </button>
                        </div>
                    </Modal>


                    {/* DATa Table  */}
                    <Row className="content">
                        <Col
                            lg={4}
                            md={5}
                            className="col-xxl-9 userData credentialData"
                        >
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column">
                                        <div className="  text-center">
                                            <Link
                                                to="/dashboard"
                                                className="d-block auth-logo"
                                            >
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">

                                            <div className="mainTable">
                                                <CardBody>
                                                    <PaginationProvider
                                                        pagination={paginationFactory(
                                                            pageOptions
                                                        )}
                                                    >
                                                        {({
                                                            paginationProps,
                                                            paginationTableProps,
                                                        }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={columns}
                                                                data={productData}
                                                                search
                                                            >
                                                                {(toolkitProps) => (
                                                                    <React.Fragment>
                                                                        <Row className="mb-2">
                                                                            <Col>
                                                                                <div className="search-box me-2 mb-2 d-flex justify-content-between">
                                                                                    <div className="d-inline">
                                                                                        <SizePerPageDropdownStandalone
                                                                                            {...paginationProps}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="position-relative">
                                                                                        <SearchBar
                                                                                            {...toolkitProps.searchProps}
                                                                                        />
                                                                                        <i className="bx bx-search-alt search-icon" />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    <BootstrapTable
                                                                                        keyField={"id"}
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        defaultSorted={
                                                                                            defaultSorted
                                                                                        }
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={
                                                                                            "thead-light"
                                                                                        }
                                                                                        {...toolkitProps.baseProps}
                                                                                        {...paginationTableProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                                <PaginationListStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                </CardBody>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Row>


                <Row>

                    {/* Modal to select checklist type  */}
                    <Modal
                        isOpen={modal_select_checklist}
                        toggle={() => {
                            tog_standard_select_checklist();
                        }}
                        className="modal-lg"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Select Checklist Type
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_select_checklist(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>


                        <div className="modal-body">

                            <div className="d-flex flex-column h-100">

                                <div className='webApplication'>
                                    <div>
                                        <Form >

                                            <Row className='my-2'>

                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="country">Select CheckList type : </Label>
                                                        <Select
                                                            // value={statusCountry}
                                                            placeholder={checkList_Type}
                                                            onChange={(e) => {
                                                                // console.log(e.value)
                                                                setcheckList_Type(e.value);
                                                                // handleSelectOptions_empSize(e.value);
                                                                // console.log(selectedOptions_empSize)
                                                            }}
                                                            options={optionGroup_checkList_Type}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            {/* <button
                                type="button"
                                onClick={() => {
                                    tog_standard_select_checklist();
                                    setmodal_select_checklist(false);
                                }}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button> */}
                            <button
                                type="button"
                                onClick={() => {
                                    // tog_standard_add_checklist();
                                    // updatePersonalDetails_sweetAlert()
                                    // addVulnerabilities()
                                    // tog_standard_add_auditee();
                                    check_checklist_template_list()
                                    tog_standard_select_checklist_template()

                                }}
                                className="btn btn-primary "
                            // data-dismiss="modal"
                            >
                                Next &nbsp; <BsArrowRight />
                            </button>
                        </div>
                    </Modal>

                    {/* modal to select checklist template  */}
                    <Modal
                        isOpen={modal_select_checklist_template}
                        toggle={() => {
                            tog_standard_select_checklist_template();
                        }}
                        className="modal-lg"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Select {checkList_Type} Template
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_select_checklist_template(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>


                        <div className="modal-body">

                            <div className="d-flex flex-column h-100">

                                <div className='webApplication'>
                                    <div>
                                        <Form >

                                            <Row className='my-2'>

                                                <Col md={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="country">Select CheckList type : </Label>
                                                        <Select
                                                            placeholder={checklist_template_list_data}
                                                            // placeholder={checklist_template_list}
                                                            onChange={(e) => {
                                                                // console.log(e.value)
                                                                setchecklist_template_list_data(e.value);
                                                                // handleSelectOptions_empSize(e.value);
                                                                // console.log(selectedOptions_empSize)
                                                            }}
                                                            options={get_checklist_template_list()}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            {/* <button
                                type="button"
                                onClick={() => {
                                    tog_standard_select_checklist_template();
                                    setmodal_select_checklist_template(false);
                                }}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button> */}
                            <button
                                type="button"
                                onClick={() => {
                                    // tog_standard_add_checklist();
                                    // updatePersonalDetails_sweetAlert()
                                    // addVulnerabilities()
                                    tog_standard_add_auditee();
                                }}
                                className="btn btn-primary "
                            // data-dismiss="modal"
                            >
                                Next &nbsp; <BsArrowRight />
                            </button>
                        </div>
                    </Modal>

                    {/*Add project - modal for forms on basic of checklist type for Team leader onlly */}
                    <Modal
                        isOpen={modal_add_auditee}
                        toggle={() => {
                            tog_standard_add_auditee();
                        }}
                        className="modal-xl"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Add Project Details
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_add_auditee(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>


                        {(() => {
                            if (checkList_Type === "Web Application Audit") {
                                return (

                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <div>
                                                    <Form >

                                                        <Row className='my-2'>



                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyEmail">Company Name : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        // value={companyName}
                                                                        placeholder={companyName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcompanyName(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getCompanyList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyName">Project Title: </Label>
                                                                    <Input type="text" className="form-control" id="companyName"
                                                                        value={projectName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectName(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className='my-2'>


                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Name :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={applicationName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationName(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Screenshot :  </Label>
                                                                    <Input type="file" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        // value={applicationScreenshot}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationScreenshot(e.target.files[0]);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Application Environment : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        // placeholder={projectAssignTo}
                                                                        // value={statusCountry}`
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationEnvironment(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationEnvironment}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>  <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Application Access : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        // placeholder={projectAssignTo}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationAccess(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationAccess}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Application URL :</Label>
                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={applicationURL}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setapplicationURL(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                        <Row className='my-2 '>


                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Start Date :   </Label>
                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectStartDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectStartDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project End Date :  </Label>

                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectEndDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectEndDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            {/* <Col md={4}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Project Status: </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={defaultProjectStatus}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setdefaultProjectStatus(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkListStatus}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col> */}




                                                        </Row>

                                                        <Row className='my-2 '>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Team Leader : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={projectAssignTo}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Auditor : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={projectAssignToAuditor}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignToAuditor(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getAuditorUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Project Description :</Label>


                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={projectDescription}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setprojectDescription(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }
                            if (checkList_Type === "Mobile Application Audit") {
                                return (

                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <div>
                                                    <Form >

                                                        <Row className='my-2'>



                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyEmail">Company Name : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        // value={companyName}
                                                                        placeholder={companyName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcompanyName(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getCompanyList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyName">Project Title: </Label>
                                                                    <Input type="text" className="form-control" id="companyName"
                                                                        value={projectName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectName(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className='my-2'>


                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Name :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={applicationName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationName(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Screenshot :  </Label>
                                                                    <Input type="file" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        // value={applicationScreenshot}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationScreenshot(e.target.files[0]);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Application Environment : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        // placeholder={projectAssignTo}
                                                                        // value={statusCountry}`
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationEnvironment(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationEnvironment}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>  <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Application Access : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        // placeholder={projectAssignTo}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationAccess(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationAccess}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Application Filename :</Label>
                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={applicationURL}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setapplicationURL(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                        <Row className='my-2 '>


                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Start Date :   </Label>
                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectStartDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectStartDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project End Date :  </Label>

                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectEndDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectEndDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            {/* <Col md={4}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Project Status: </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={defaultProjectStatus}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setdefaultProjectStatus(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkListStatus}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col> */}




                                                        </Row>

                                                        <Row className='my-2 '>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Team Leader : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={projectAssignTo}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Auditor : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={projectAssignToAuditor}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignToAuditor(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getAuditorUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Project Description :</Label>


                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={projectDescription}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setprojectDescription(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }
                            if (checkList_Type === "API Audit") {
                                return (
                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <div>
                                                    <Form >

                                                        <Row className='my-2'>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyEmail">Company Name : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        // value={companyName}
                                                                        placeholder={companyName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcompanyName(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getCompanyList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyName">Project Title: </Label>
                                                                    <Input type="text" className="form-control" id="companyName"
                                                                        value={projectName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectName(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className='my-2'>


                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">API Name :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={applicationName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationName(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">API Screenshot :  </Label>
                                                                    <Input type="file" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        // value={applicationScreenshot}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationScreenshot(e.target.files[0]);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">API Environment : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        // placeholder={projectAssignTo}
                                                                        // value={statusCountry}`
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationEnvironment(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationEnvironment}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>  <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Application Access : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        // placeholder={projectAssignTo}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationAccess(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationAccess}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Application URL :</Label>
                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={applicationURL}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setapplicationURL(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                        <Row className='my-2 '>


                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Start Date :   </Label>
                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectStartDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectStartDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project End Date :  </Label>

                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectEndDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectEndDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            {/* <Col md={4}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Project Status: </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={defaultProjectStatus}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setdefaultProjectStatus(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkListStatus}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col> */}




                                                        </Row>

                                                        <Row className='my-2 '>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Team Leader : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={projectAssignTo}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Auditor : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={projectAssignToAuditor}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignToAuditor(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getAuditorUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Project Description :</Label>


                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={projectDescription}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setprojectDescription(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }
                            if (checkList_Type === "Network Audit") {
                                return (

                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <div>
                                                    <Form >

                                                        <Row className='my-2'>



                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyEmail">Company Name : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        // value={companyName}
                                                                        placeholder={companyName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcompanyName(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getCompanyList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyName">Project Title: </Label>
                                                                    <Input type="text" className="form-control" id="companyName"
                                                                        value={projectName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectName(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className='my-2'>


                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Network Name :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={applicationName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationName(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Network Screenshot :  </Label>
                                                                    <Input type="file" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        // value={applicationScreenshot}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationScreenshot(e.target.files[0]);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Network Environment : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        // placeholder={projectAssignTo}
                                                                        // value={statusCountry}`
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationEnvironment(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationEnvironment}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>  <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Network Access : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        // placeholder={projectAssignTo}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setapplicationAccess(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_ApplicationAccess}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Network IP :</Label>
                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={applicationURL}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setapplicationURL(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                        <Row className='my-2 '>


                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Start Date :   </Label>
                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectStartDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectStartDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project End Date :  </Label>

                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        value={projectEndDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectEndDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            {/* <Col md={4}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Project Status: </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={defaultProjectStatus}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setdefaultProjectStatus(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkListStatus}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col> */}




                                                        </Row>

                                                        <Row className='my-2 '>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Team Leader : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={projectAssignTo}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Auditor : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={projectAssignToAuditor}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignToAuditor(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getAuditorUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Project Description :</Label>


                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={projectDescription}
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setprojectDescription(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }
                        })()}
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    tog_standard_add_auditee();
                                    setmodal_add_auditee(false);
                                }}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    // tog_standard_add_checklist();
                                    // updatePersonalDetails_sweetAlert()
                                    addVulnerabilities()
                                    tog_standard_add_auditee();
                                    tog_standard_select_checklist()
                                    tog_standard_select_checklist_template()
                                    // tog_standard_assign_to();
                                }}
                                className="btn btn-primary "
                            // data-dismiss="modal"
                            >
                                Create Project
                            </button>
                        </div>
                    </Modal>

                    {/* modal to select project assign to  */}
                    <Modal
                        isOpen={modal_assign_to}
                        className="modal-xl"
                        toggle={() => {
                            tog_standard_assign_to();
                        }}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Assign Project To Auditor
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_assign_to(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {(() => {
                            if (checkList_Type === "Web Application Audit") {
                                return (
                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <div>
                                                    <Form >

                                                        <Row className='my-2'>

                                                            <Col md={6}>
                                                                <div className="mb-3 disabled">
                                                                    <Label className="form-label" htmlFor="companyEmail">Company Name : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        // value={companyName}
                                                                        placeholder={displayCompanyName}
                                                                        isDisabled={true}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcompanyName(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getCompanyList()}
                                                                        classNamePrefix="disabled select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyName">Project Title: </Label>
                                                                    <Input type="text" className="form-control" id="companyName"
                                                                        disabled
                                                                        value={projectName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectName(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                        <Row className='my-2'>


                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Name :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={applicationName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationName(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Screenshot :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={applicationScreenshot}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationScreenshot(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>




                                                        </Row>
                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Application URL :</Label>


                                                            <div className="mb-3 " md={6} >
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={applicationURL}
                                                                    disabled
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    // value={}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setapplicationURL(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                        </Row>

                                                        <Row className='my-2 '>


                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Start Date :   </Label>
                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={projectStartDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectStartDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project End Date :  </Label>

                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={projectEndDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectEndDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={4}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Project Status: </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={defaultProjectStatus}
                                                                        // value={statusCountry}
                                                                        isDisabled={true}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setdefaultProjectStatus(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkListStatus}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row className='my-2 '>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Team Leader : </Label>
                                                                    <Select
                                                                        className="zIndex disabled"
                                                                        placeholder={displayProjectAssignTo}
                                                                        isDisabled={true}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Auditor : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={projectAssignTo}

                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getAuditorUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>



                                                        </Row>
                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Project Description :</Label>


                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={projectDescription}
                                                                    disabled
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setprojectDescription(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }

                            if (checkList_Type === "Mobile Application Audit") {
                                return (
                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <div>
                                                    <Form >

                                                        <Row className='my-2'>

                                                            <Col md={6}>
                                                                <div className="mb-3 disabled">
                                                                    <Label className="form-label" htmlFor="companyEmail">Company Name : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        // value={companyName}
                                                                        placeholder={displayCompanyName}
                                                                        isDisabled={true}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcompanyName(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getCompanyList()}
                                                                        classNamePrefix="disabled select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyName">Project Title: </Label>
                                                                    <Input type="text" className="form-control" id="companyName"
                                                                        disabled
                                                                        value={projectName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectName(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                        <Row className='my-2'>


                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Name :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={applicationName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationName(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Screenshot :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={applicationScreenshot}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationScreenshot(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>




                                                        </Row>
                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Application URL :</Label>


                                                            <div className="mb-3 " md={6} >
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={applicationURL}
                                                                    disabled
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    // value={}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setapplicationURL(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                        </Row>

                                                        <Row className='my-2 '>


                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Start Date :   </Label>
                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={projectStartDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectStartDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project End Date :  </Label>

                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={projectEndDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectEndDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={4}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Project Status: </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={defaultProjectStatus}
                                                                        // value={statusCountry}
                                                                        isDisabled={true}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setdefaultProjectStatus(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkListStatus}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row className='my-2 '>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Team Leader : </Label>
                                                                    <Select
                                                                        className="zIndex disabled"
                                                                        placeholder={displayProjectAssignTo}
                                                                        isDisabled={true}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Auditor : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={projectAssignTo}

                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getAuditorUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>



                                                        </Row>
                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Project Description :</Label>


                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={projectDescription}
                                                                    disabled
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setprojectDescription(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }

                            if (checkList_Type === "API Audit") {
                                return (
                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <div>
                                                    <Form >

                                                        <Row className='my-2'>

                                                            <Col md={6}>
                                                                <div className="mb-3 disabled">
                                                                    <Label className="form-label" htmlFor="companyEmail">Company Name : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        // value={companyName}
                                                                        placeholder={displayCompanyName}
                                                                        isDisabled={true}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcompanyName(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getCompanyList()}
                                                                        classNamePrefix="disabled select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyName">Project Title: </Label>
                                                                    <Input type="text" className="form-control" id="companyName"
                                                                        disabled
                                                                        value={projectName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectName(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                        <Row className='my-2'>


                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Name :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={applicationName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationName(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Screenshot :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={applicationScreenshot}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationScreenshot(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>




                                                        </Row>
                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Application URL :</Label>


                                                            <div className="mb-3 " md={6} >
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={applicationURL}
                                                                    disabled
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    // value={}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setapplicationURL(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                        </Row>

                                                        <Row className='my-2 '>


                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Start Date :   </Label>
                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={projectStartDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectStartDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project End Date :  </Label>

                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={projectEndDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectEndDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={4}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Project Status: </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={defaultProjectStatus}
                                                                        // value={statusCountry}
                                                                        isDisabled={true}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setdefaultProjectStatus(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkListStatus}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row className='my-2 '>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Team Leader : </Label>
                                                                    <Select
                                                                        className="zIndex disabled"
                                                                        placeholder={displayProjectAssignTo}
                                                                        isDisabled={true}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Auditor : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={projectAssignTo}

                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getAuditorUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>



                                                        </Row>
                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Project Description :</Label>


                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={projectDescription}
                                                                    disabled
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setprojectDescription(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }

                            if (checkList_Type === "Network Audit") {
                                return (
                                    <div className="modal-body">

                                        <div className="d-flex flex-column h-100">

                                            <div className='webApplication'>
                                                <div>
                                                    <Form >

                                                        <Row className='my-2'>

                                                            <Col md={6}>
                                                                <div className="mb-3 disabled">
                                                                    <Label className="form-label" htmlFor="companyEmail">Company Name : </Label>
                                                                    <Select
                                                                        // value={statusCountry}
                                                                        // value={companyName}
                                                                        placeholder={displayCompanyName}
                                                                        isDisabled={true}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setcompanyName(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getCompanyList()}
                                                                        classNamePrefix="disabled select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyName">Project Title: </Label>
                                                                    <Input type="text" className="form-control" id="companyName"
                                                                        disabled
                                                                        value={projectName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectName(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                        <Row className='my-2'>


                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Name :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={applicationName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationName(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Application Screenshot :  </Label>
                                                                    <Input type="text" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={applicationScreenshot}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setapplicationScreenshot(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>




                                                        </Row>
                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Application URL :</Label>


                                                            <div className="mb-3 " md={6} >
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={applicationURL}
                                                                    disabled
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    // value={}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setapplicationURL(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                        </Row>

                                                        <Row className='my-2 '>


                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Start Date :   </Label>
                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={projectStartDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectStartDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>

                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project End Date :  </Label>

                                                                    <Input type="date" className="form-control" id="companyEmail"
                                                                        // placeholder="2022"
                                                                        disabled
                                                                        value={projectEndDate}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setprojectEndDate(e.target.value);
                                                                        }} />

                                                                </div>
                                                            </Col>
                                                            <Col md={4}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Project Status: </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={defaultProjectStatus}
                                                                        // value={statusCountry}
                                                                        isDisabled={true}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setdefaultProjectStatus(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_checkListStatus}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>




                                                        </Row>

                                                        <Row className='my-2 '>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Team Leader : </Label>
                                                                    <Select
                                                                        className="zIndex disabled"
                                                                        placeholder={displayProjectAssignTo}
                                                                        isDisabled={true}
                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3 ">
                                                                    <Label className="form-label" htmlFor="country">Auditor : </Label>
                                                                    <Select
                                                                        className="zIndex"
                                                                        placeholder={projectAssignTo}

                                                                        // value={statusCountry}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setprojectAssignTo(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={getAuditorUserList()}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>



                                                        </Row>
                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="otherDetails">Project Description :</Label>


                                                            <div className="mb-3" md={6}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={projectDescription}
                                                                    disabled
                                                                    // onReady={editor => {
                                                                    //     // You can store the "editor" and use when it is needed.
                                                                    //     console.log('Editor is ready to use!', editor);
                                                                    // }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        // console.log({ event, editor, data });
                                                                        // console.log(data);
                                                                        setprojectDescription(data)
                                                                        // console.log(event.target.data)
                                                                    }}
                                                                // onBlur={(event, editor) => {
                                                                //     console.log('Blur.', editor);
                                                                // }}
                                                                // onFocus={(event, editor) => {
                                                                //     console.log('Focus.', editor);
                                                                // }}
                                                                />
                                                            </div>

                                                            {/* <CustomToolbar /> */}
                                                            {/* <Editor/> */}

                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            }
                        })()}

                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    tog_standard_assign_to();
                                    setmodal_assign_to(false);
                                }}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    // tog_standard_add_checklist();
                                    // updatePersonalDetails_sweetAlert()
                                    // addVulnerabilities()
                                    // setprojectID()
                                    assignProjectTo()
                                    tog_standard_assign_to();
                                }}
                                className="btn btn-primary "
                            // data-dismiss="modal"
                            >
                                Save
                            </button>
                        </div>
                    </Modal>

                    {/* MODal to view project details */}
                    <Modal
                        isOpen={view_more_modal}
                        toggle={() => {
                            toggle_view_more_modal();
                        }}
                        className="modal-xl"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Project Details
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setview_more_modal(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {
                            (() => {
                                if (checkList_Type === "Web Application Audit") {
                                    return (
                                        <div className="modal-body">

                                            <div className="d-flex flex-column h-100">

                                                <div className='webApplication'>
                                                    <Form >
                                                        <Row className='my-2'>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Title *  </Label>
                                                                    <div className="text-secondary">{projectName}</div>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Audit Type *  </Label>
                                                                    <div className="text-secondary">{checkList_Type}</div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* <Row className='my-2'>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Asset Name *  </Label>
                                                                    <div className="text-secondary">{applicationScreenshot}</div>
                                                                </div>
                                                            </Col>
                                                        </Row> */}

                                                        <Row className='my-3'>

                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Manager ID *  </Label>
                                                                    <div className="text-secondary">{ProjectManagerName}</div>
                                                                </div>
                                                            </Col>
                                                            {(() => {
                                                                // console.log("teamLeader", teamLeaderID)
                                                                if (teamLeaderID != null) {
                                                                    return (
                                                                        <Col md={4}>
                                                                            <div className="mb-3">
                                                                                <Label className="form-label" htmlFor="employeeSize">Team Leaders ID  :  </Label>
                                                                                <div className="text-secondary">{TeamLeaderName}</div>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                }
                                                            })()}

                                                            {(() => {
                                                                if (auditorID != null) {
                                                                    return (
                                                                        <Col md={4}>
                                                                            <div className="mb-3">
                                                                                <Label className="form-label" htmlFor="employeeSize">Auditor ID  :  </Label>
                                                                                <div className="text-secondary">{AuditorName}</div>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                }
                                                            })()}

                                                        </Row>



                                                        <Row className='my-3'>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Start Date *  </Label>
                                                                    <div className="text-secondary">{projectStartDate}</div>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">End Date *  </Label>
                                                                    <div className="text-secondary">{projectEndDate}</div>
                                                                </div>
                                                            </Col>
                                                        </Row>


                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="employeeSize">Project Description  *  </Label>
                                                            <div className="text-secondary">{projectDescription}</div>
                                                        </Row>

                                                        <Row className='my-2'>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyLogo">Status * </Label>
                                                                    <div>  {(() => {
                                                                        console.log("statusussuss", projectStatus)
                                                                        if (projectStatus === null) {
                                                                            return <>-</>;
                                                                        } else if (projectStatus === "Completed") {
                                                                            return <> <Badge pill className="badge-soft-success ms-1  fs-6">{projectStatus}</Badge></>;
                                                                        } else if (projectStatus === "InProgress") {
                                                                            return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{projectStatus}</Badge>

                                                                            </>;
                                                                        } else if (projectStatus === "Initiated") {
                                                                            return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{projectStatus}</Badge>

                                                                            </>;
                                                                        }
                                                                    })()}</div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                }

                                if (checkList_Type === "Mobile Application Audit") {
                                    return (
                                        <div className="modal-body">

                                            <div className="d-flex flex-column h-100">

                                                <div className='webApplication'>
                                                    <Form >
                                                        <Row className='my-2'>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Title *  </Label>
                                                                    <div className="text-secondary">{projectName}</div>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Audit Type *  </Label>
                                                                    <div className="text-secondary">{checkList_Type}</div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* <Row className='my-2'>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Asset Name *  </Label>
                                                                    <div className="text-secondary">{applicationScreenshot}</div>
                                                                </div>
                                                            </Col>
                                                        </Row> */}

                                                        <Row className='my-3'>

                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Manager ID *  </Label>
                                                                    <div className="text-secondary">{ProjectManagerName}</div>
                                                                </div>
                                                            </Col>
                                                            {(() => {
                                                                // console.log("teamLeader", teamLeaderID)
                                                                if (teamLeaderID != null) {
                                                                    return (
                                                                        <Col md={4}>
                                                                            <div className="mb-3">
                                                                                <Label className="form-label" htmlFor="employeeSize">Team Leaders ID  :  </Label>
                                                                                <div className="text-secondary">{TeamLeaderName}</div>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                }
                                                            })()}

                                                            {(() => {
                                                                if (auditorID != null) {
                                                                    return (
                                                                        <Col md={4}>
                                                                            <div className="mb-3">
                                                                                <Label className="form-label" htmlFor="employeeSize">Auditor ID  :  </Label>
                                                                                <div className="text-secondary">{AuditorName}</div>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                }
                                                            })()}
                                                        </Row>



                                                        <Row className='my-3'>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Start Date *  </Label>
                                                                    <div className="text-secondary">{projectStartDate}</div>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">End Date *  </Label>
                                                                    <div className="text-secondary">{projectEndDate}</div>
                                                                </div>
                                                            </Col>
                                                        </Row>


                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="employeeSize">Project Description  *  </Label>
                                                            <div className="text-secondary">{projectDescription}</div>
                                                        </Row>

                                                        <Row className='my-2'>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyLogo">Status * </Label>
                                                                    <div>  {(() => {

                                                                        if (projectStatus === null) {
                                                                            return <>-</>;
                                                                        } else if (projectStatus === "Completed") {
                                                                            return <> <Badge pill className="badge-soft-success ms-1  fs-6">{projectStatus}</Badge></>;
                                                                        } else if (projectStatus === "Inprogress") {
                                                                            return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{projectStatus}</Badge>

                                                                            </>;
                                                                        } else if (projectStatus === "Initiated") {
                                                                            return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{projectStatus}</Badge>

                                                                            </>;
                                                                        }
                                                                    })()}</div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                }

                                if (checkList_Type === "API Audit") {
                                    return (
                                        <div className="modal-body">

                                            <div className="d-flex flex-column h-100">

                                                <div className='webApplication'>
                                                    <Form >
                                                        <Row className='my-2'>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Title *  </Label>
                                                                    <div className="text-secondary">{projectName}</div>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Audit Type *  </Label>
                                                                    <div className="text-secondary">{checkList_Type}</div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* <Row className='my-2'>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Asset Name *  </Label>
                                                                    <div className="text-secondary">{applicationScreenshot}</div>
                                                                </div>
                                                            </Col>
                                                        </Row> */}

                                                        <Row className='my-3'>

                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Manager ID *  </Label>
                                                                    <div className="text-secondary">{ProjectManagerName}</div>
                                                                </div>
                                                            </Col>
                                                            {(() => {
                                                                // console.log("teamLeader", teamLeaderID)
                                                                if (teamLeaderID != null) {
                                                                    return (
                                                                        <Col md={4}>
                                                                            <div className="mb-3">
                                                                                <Label className="form-label" htmlFor="employeeSize">Team Leaders ID  :  </Label>
                                                                                <div className="text-secondary">{TeamLeaderName}</div>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                }
                                                            })()}

                                                            {(() => {
                                                                if (auditorID != null) {
                                                                    return (
                                                                        <Col md={4}>
                                                                            <div className="mb-3">
                                                                                <Label className="form-label" htmlFor="employeeSize">Auditor ID  :  </Label>
                                                                                <div className="text-secondary">{AuditorName}</div>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                }
                                                            })()}
                                                        </Row>



                                                        <Row className='my-3'>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Start Date *  </Label>
                                                                    <div className="text-secondary">{projectStartDate}</div>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">End Date *  </Label>
                                                                    <div className="text-secondary">{projectEndDate}</div>
                                                                </div>
                                                            </Col>
                                                        </Row>


                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="employeeSize">Project Description  *  </Label>
                                                            <div className="text-secondary">{projectDescription}</div>
                                                        </Row>

                                                        <Row className='my-2'>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyLogo">Status * </Label>
                                                                    <div>  {(() => {

                                                                        if (projectStatus === null) {
                                                                            return <>-</>;
                                                                        } else if (projectStatus === "Completed") {
                                                                            return <> <Badge pill className="badge-soft-success ms-1  fs-6">{projectStatus}</Badge></>;
                                                                        } else if (projectStatus === "Inprogress") {
                                                                            return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{projectStatus}</Badge>

                                                                            </>;
                                                                        } else if (projectStatus === "Initiated") {
                                                                            return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{projectStatus}</Badge>

                                                                            </>;
                                                                        }
                                                                    })()}</div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                }

                                if (checkList_Type === "Network Audit") {
                                    return (
                                        <div className="modal-body">

                                            <div className="d-flex flex-column h-100">

                                                <div className='webApplication'>
                                                    <Form >
                                                        <Row className='my-2'>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Title *  </Label>
                                                                    <div className="text-secondary">{projectName}</div>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Audit Type *  </Label>
                                                                    <div className="text-secondary">{checkList_Type}</div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* <Row className='my-2'>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Asset Name *  </Label>
                                                                    <div className="text-secondary">{applicationScreenshot}</div>
                                                                </div>
                                                            </Col>
                                                        </Row> */}

                                                        <Row className='my-3'>

                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Project Manager ID *  </Label>
                                                                    <div className="text-secondary">{ProjectManagerName}</div>
                                                                </div>
                                                            </Col>
                                                            {(() => {
                                                                // console.log("teamLeader", teamLeaderID)
                                                                if (teamLeaderID != null) {
                                                                    return (
                                                                        <Col md={4}>
                                                                            <div className="mb-3">
                                                                                <Label className="form-label" htmlFor="employeeSize">Team Leaders ID  :  </Label>
                                                                                <div className="text-secondary">{TeamLeaderName}</div>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                }
                                                            })()}

                                                            {(() => {
                                                                if (auditorID != null) {
                                                                    return (
                                                                        <Col md={4}>
                                                                            <div className="mb-3">
                                                                                <Label className="form-label" htmlFor="employeeSize">Auditor ID  :  </Label>
                                                                                <div className="text-secondary">{AuditorName}</div>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                }
                                                            })()}
                                                        </Row>



                                                        <Row className='my-3'>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">Start Date *  </Label>
                                                                    <div className="text-secondary">{projectStartDate}</div>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="employeeSize">End Date *  </Label>
                                                                    <div className="text-secondary">{projectEndDate}</div>
                                                                </div>
                                                            </Col>
                                                        </Row>


                                                        <Row className='my-3'>
                                                            <Label className="form-label" htmlFor="employeeSize">Project Description  *  </Label>
                                                            <div className="text-secondary">{projectDescription}</div>
                                                        </Row>

                                                        <Row className='my-2'>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="companyLogo">Status * </Label>
                                                                    <div>  {(() => {

                                                                        if (projectStatus === null) {
                                                                            return <>-</>;
                                                                        } else if (projectStatus === "Completed") {
                                                                            return <> <Badge pill className="badge-soft-success ms-1  fs-6">{projectStatus}</Badge></>;
                                                                        } else if (projectStatus === "Inprogress") {
                                                                            return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{projectStatus}</Badge>

                                                                            </>;
                                                                        } else if (projectStatus === "Initiated") {
                                                                            return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{projectStatus}</Badge>

                                                                            </>;
                                                                        }
                                                                    })()}</div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </Form>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                }
                            })()
                        }

                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    toggle_view_more_modal();
                                    setview_more_modal(false);
                                }}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            {/* <button
                                type="button"
                                onClick={() => {
                                    // updatePersonalDetails_sweetAlert()
                                    editChecklistTemplate()
                                }}
                                // Disabled={detailBtn}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Save
                            </button> */}
                        </div>
                    </Modal>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default Project;
