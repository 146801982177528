import { MetaTags } from "react-meta-tags";
import {
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    Col,
    Collapse,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
} from "reactstrap"
import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";

import classnames from "classnames"
import Audit from "./Audit";
import Test from "./Test";
import { Link, useHistory } from "react-router-dom";
import Report from "./Report";
import Certificate from "./Certificate";
const Audit_report_certificate = (props) => {
    const history = useHistory()
    const projectID = props.match.params.projectID
    const [activeTab, setactiveTab] = useState()

    // const toggle = tab => {
    //     if (activeTab !== tab) {
    //         setactiveTab(tab)
    //     }
    // }

    useEffect(() => {
        // read url in react
        // console.log("window.location.href", window.location.href)
        // console.log("window.location.href", process.env.REACT_APP_LOCALPATH)
        const URLactiveTab = window.location.href.split(`${process.env.REACT_APP_LOCALPATH}`)[1].split('/')[0]
        console.log("URLactiveTab", URLactiveTab)
        if (URLactiveTab === "project") {
            setactiveTab("1")
        }
        if (URLactiveTab === "report") {
            setactiveTab("2");
        }
        if (URLactiveTab === "certificate") {
            setactiveTab("3")
        }
    }, [])
    return (
        <React.Fragment>
            <div className="userProfile credentialBreachProfile">
                <MetaTags>
                    <title>Audit | AuditSense</title>
                </MetaTags>
                <Row>
                    <Breadcrumb
                        title="Dashboard"
                        breadcrumbItem="Vulnerability Dashboard"
                        breadcrumbFeature="Vulnerabilities"
                    />
                </Row>


                <Row>
                    <Col xl={12}>
                        <div>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "1",
                                        })}
                                        onClick={() => {
                                            // toggle("1")
                                            history.push(`/project/${projectID}`)
                                        }}
                                    >
                                        Audit
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        to="/report/213456"

                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "2",
                                        })}
                                        onClick={() => {
                                            // toggle("2")
                                            history.push(`/report/${projectID}`)
                                        }}
                                    >
                                        Report
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "3",
                                        })}
                                        onClick={() => {
                                            // toggle("3")
                                            history.push(`/certificate/${projectID}`)
                                        }}
                                    >
                                        Certificate
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={activeTab} className="p-3 text-muted">

                                {activeTab === "1" && <Audit projectID={projectID} />}
                                {activeTab === "2" && <Report projectID={projectID} />}
                                {activeTab === "3" && <Certificate projectID={projectID} />}
                                {/* <TabPane tabId="1">
                                            
                                        </TabPane>
                                        <TabPane tabId="2">
                                            report
                                        </TabPane>
                                        <TabPane tabId="3">Certificate
                                        </TabPane> */}
                            </TabContent>
                        </div>
                    </Col>

                </Row>
            </div>
        </React.Fragment>
    );
};

export default Audit_report_certificate;
