import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Card,
    Form,
    CardBody,
    CardTitle,
    CardSubtitle,
    Container,
    CardHeader,
    Button,
    Input, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap"
import Dropzone from "react-dropzone"

// Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb"

import { Link } from "react-router-dom"
import Carousel_OpenPOC from "./Carousel_OpenPOC";

const OpenPocDropZone = forwardRef((props, ref,) => {
    const [descOPEN_POC, setdescOPEN_POC] = useState([])

    useImperativeHandle(ref, () => ({
        temp() {
            console.log("child called selectedFiles", selectedFiles)
            setselectedFiles([])
        }
    }))
    const [selectedFiles, setselectedFiles] = useState([])
    const [DeleteID, setDeleteID] = useState(0)
    const [currentIndex, setCurrentIndex] = useState(0)
    // Random Number 10 unique ID Generator  --------------------------------->
    function uniqueIdGenerator() {
        var seq = (Math.floor(Math.random() * 100) + 100).toString().substring(1);
        const id = Date.now() + seq;
        return id.slice(5, 15);
    }
    useEffect(() => {

        props.OpenPOCData(selectedFiles, descOPEN_POC)
    }, [selectedFiles, descOPEN_POC])

    function handleAcceptedFiles(files) {
        // console.log("old files", files)

        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
                uid: uniqueIdGenerator(),
            })
        )
        let tempArray = []
        for (let i = 0; i < files.length; i++) {
            // console.log("this is ", files[i])
            tempArray.push({ uid: files[i].uid, desc: "" })
        }
        setdescOPEN_POC(tempArray)
        // console.log("files", files)
        // props.OpenPOCData(files)
        setselectedFiles(files)
    }
    const [modal_standard4, setmodal_standard4] = useState(false)
    function tog_standard4() {
        setmodal_standard4(!modal_standard4);
        // toggle for view more button 
    }
    function deleteImage(id) {
        // console.log("uid", DeleteID)
        setselectedFiles(prev => selectedFiles.filter(items => items.uid !== id))
        setdescOPEN_POC(prev => descOPEN_POC.filter(items => items.uid !== id))
        // console.log("seleected files", selectedFiles)
        // let files = selectedFiles
        // files.map(file =>
        //     Object.assign(file)
        // )
        // conso
        // props.OpenPOCData(files)
    }
    /**
     * Formats the size
     */

    function sendFiles() {
        console.log("selected files", selectedFiles)
    }
    const handleDescOnchange = (value, id) => {
        // console.log("data", value, id)

        setdescOPEN_POC(descOPEN_POC.map(items => {
            if (items.uid === id) {
                return { uid: id, desc: value };
            } else {
                return items;
            }
        }))
        // const element  = {
        //     desc : value , 
        //     uid : id
        // } 
        // for
        // setdescOPEN_POC(...descOPEN_POC)
        // if()
        // setselectedFiles(prev => selectedFiles.map((data, index) => {
        //     if (id === data.uid) {
        //         console.log("matched")
        //         data.desc = value
        //         console.log("test data", data)
        //     }
        // }))
        // console.log("test data", files)
    }
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    const [modal_image, set_modal_image] = useState("");

    return (
        <React.Fragment>
            <Row>
                <Col className="col-12">
                    <Card>
                        <CardBody>
                            {/* <CardTitle>Dropzone</CardTitle>
                            <CardSubtitle className="mb-3">
                                DropzoneJS is an open source library that provides
                                drag’n’drop file uploads with image previews.
                            </CardSubtitle> */}
                            <Form>
                                <Dropzone
                                    accept={".png , .jpg"}
                                    onDrop={acceptedFiles => {
                                        handleAcceptedFiles(acceptedFiles)
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                            <div
                                                className="dz-message needsclick mt-2"
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                </div>
                                                <h4>Drop files here or click to upload.</h4>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                                <div className="dropzone-previews mt-3" id="file-previews">
                                    {selectedFiles.map((f, i) => {
                                        return (<div className="row  d-flex justify-content-around">

                                            <Card role="button"
                                                className="col-5 mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                                onClick={() => {
                                                    set_modal_image(f.preview)
                                                    setmodal_standard4(true)
                                                    setCurrentIndex(i)
                                                }}
                                            >
                                                <div className="p-2">
                                                    <Row className="align-items-center">
                                                        <Col className="col-auto">
                                                            <img
                                                                data-dz-thumbnail=""
                                                                height="80"
                                                                className="avatar-sm rounded bg-light"
                                                                // alt={f.name}
                                                                src={f.preview}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Link
                                                                to="#"
                                                                className="text-muted font-weight-bold"
                                                            >
                                                                {f.name}
                                                            </Link>
                                                            <p className="mb-0">
                                                                <strong>{f.formattedSize}</strong>
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card>
                                            <Card
                                                className="col-5 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                key={i + "-file"}
                                            >
                                                <div className="p-2">
                                                    <Row className="align-items-center">
                                                        <Input type="text" placeholder="POC Description" onChange={(e) => {
                                                            // console.log("this is e", e.target.value)
                                                            handleDescOnchange(e.target.value, f.uid)
                                                        }} />
                                                    </Row>
                                                </div>
                                            </Card>

                                            <Card
                                                className="col-1 mt-1 mb-0 shadow-none border-white dz-processing dz-image-preview dz-success dz-complete justify-content-center d-flex align-item-center"
                                                key={i + "-file"}
                                            >
                                                <div className="p-2">

                                                    <Button className="" color="danger" onClick={() => {
                                                        // set_modal_image(f.preview)
                                                        // setmodal_standard4(true)
                                                        // console.log("delteUID", f.uid)
                                                        setCurrentIndex(i)
                                                        // setDeleteID()
                                                        deleteImage(f.uid)
                                                    }}>Delete</Button>
                                                </div>
                                            </Card>
                                        </div>
                                        )
                                    })}
                                </div>
                            </Form>

                            {/* <div className="text-center mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={sendFiles}
                                >
                                    Send Files
                                </button>
                            </div> */}
                            {/* <input type="file" onChange={(e) => console.log("main change data", e.target.files)} /> */}
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/* its modal to show image  */}
            <Modal isOpen={modal_standard4} centered toggle={() => {
                tog_standard4();
            }} className="modal-lg">
                {/* <ModalHeader >Modal title</ModalHeader> */}
                <ModalBody className="align-item-center d-flex justify-content-center cursor-pointer w-100 bg-dark border-black" style={{
                    // height: "80vh",
                    display: "flex",
                    // backgroundColor : 'black',xa
                    justifyContent: "center",
                    alignItems: "center"

                }}>
                    {/* <img
                        data-dz-thumbnail=""
                        // height="80"
                        width={"80%"}
                        className=""
                        // alt={f.name}
                        src={modal_image}
                    /> */}

                    <Carousel_OpenPOC ImageArr={selectedFiles} currentIndex={currentIndex} />
                </ModalBody>
                {/* <ModalFooter>
                    <Button color="secondary" onClick={tog_standard4}>Close</Button>
                </ModalFooter> */}
            </Modal>
        </React.Fragment>
    )
})

export default OpenPocDropZone
