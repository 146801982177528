import React, { Component, useEffect, useState } from "react"
import { Carousel, CarouselItem, CarouselControl } from "reactstrap"

// Carousel images
import img4 from "../../../assets/images/small/img-4.jpg"
import img5 from "../../../assets/images/small/img-5.jpg"
import img6 from "../../../assets/images/small/img-6.jpg"


// class Carousel_OpenPOC extends Component {
//     constructor(props) {
//         super(props)
//         this.state = { activeIndex: 0 }
//         this.next = this.next.bind(this)
//         this.previous = this.previous.bind(this)
//         this.goToIndex = this.goToIndex.bind(this)
//         this.onExiting = this.onExiting.bind(this)
//         this.onExited = this.onExited.bind(this)
//     }

//     onExiting() {
//         this.animating = true
//     }

//      onExited() {
//         this.animating = false
//     }

//     next() {
//         if (this.animating) return
//         const nextIndex =
//             this.state.activeIndex === items.length - 1
//                 ? 0
//                 : this.state.activeIndex + 1
//         this.setState({ activeIndex: nextIndex })
//     }

//     previous() {
//         if (this.animating) return
//         const nextIndex =
//             this.state.activeIndex === 0
//                 ? items.length - 1
//                 : this.state.activeIndex - 1
//         this.setState({ activeIndex: nextIndex })
//     }

//     goToIndex(newIndex) {
//         if (this.animating) return
//         this.setState({ activeIndex: newIndex })
//     }

//     render() {
//         const { activeIndex } = this.state
//         console.log("props", this.props.ImageArr)
//         const items = [
//             {
//                 src: img4,
//                 altText: "Slide 1",
//                 caption: "Slide 1",
//             },
//             {
//                 src: img5,
//                 altText: "Slide 2",
//                 caption: "Slide 2",
//             },
//             {
//                 src: img6,
//                 altText: "Slide 3",
//                 caption: "Slide 3",
//             },
//         ]




//         return (
//             <React.Fragment>
//                 <Carousel
//                     activeIndex={activeIndex}
//                     next={this.next}
//                     previous={this.previous}
//                 >
//                     {slides}
//                     <CarouselControl
//                         direction="prev"
//                         directionText="Previous"
//                         onClickHandler={this.previous}
//                     />
//                     <CarouselControl
//                         direction="next"
//                         directionText="Next"
//                         onClickHandler={this.next}
//                     />
//                 </Carousel>
//             </React.Fragment>
//         )
//     }
// }

const Carousel_OpenPOC = (props) => {
    const [activeIndex, setActiveindex] = useState(0)
    const [animating, setAnimating] = useState(true)
    const [ImagesArr, setImagesArr] = useState([])
    // const ImagesArr = props.ImageArr
    useEffect(() => {
        console.log("props", props.ImageArr)
        setImagesArr(props.ImageArr)
        setActiveindex(props.currentIndex)
    }, [])
    const onExiting = () => {
        setAnimating(true)
    }

    const onExited = () => {
        // setAnimating(false)
    }

    const next = () => {
        console.log("next")
        if (animating) return activeIndex === ImagesArr.length - 1 ? setActiveindex(0) : setActiveindex(activeIndex + 1)
        else {
            console.log("inside else next")
        }
    }

    const previous = () => {
        console.log("previous")
        if (animating) return activeIndex === 0 ? setActiveindex(ImagesArr.length - 1) : setActiveindex(activeIndex - 1)
        else {
            console.log("inside else previouss")
        }
    }

    // const goToIndex = (newIndex) => {
    //     if (animating) return setActiveindex(newIndex)
    // }
    const slides = ImagesArr.map(item => {
        return (
            <CarouselItem
                // onExiting={onExiting}
                // onExited={onExited}
                key={item.preview}
            >
                <img
                    src={item.preview || `${process.env.REACT_APP_DEFAULTPATH}/images/${item.POCFilename}`}
                    className="d-block img-fluid"
                // alt={item.altText}
                />
            </CarouselItem>
        )
    })
    return (
        <React.Fragment>
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                style={{ justifyContent: "center", alignItems: "center" }}
            >
                {slides}
                <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                />
                <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                />
            </Carousel>
        </React.Fragment>
    )
}
export default Carousel_OpenPOC
