import React, { useState, useEffect } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import CountUp from "react-countup";


import FeatherIcon from "feather-icons-react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import "react-table/react-table.css";
import {
    Badge,
    CardBody,
    Col,
    Modal,
    Row,
    Form,
    Label,
    Input,
    Card,
    Button
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

import MetaTags from "react-meta-tags";

//import images
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import swal from 'sweetalert';
import Select from "react-select"
import axios from "axios"

const UserList = (props) => {

    var pass_regx = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    // function to get uniques ID
    function uniqueIdGenerator() {
        var seq = (Math.floor(Math.random() * 100) + 100).toString().substring(1);
        const id = Date.now() + seq;
        return id;
    }


    // const organizationId = props.match.params.organizationId // to store userId from path 
    const organizationId = localStorage.getItem("orgID") // to store userId from path 
    const AuditorID = localStorage.getItem("AuditorID")

    const loginUser = localStorage.getItem("AuditorID")
    const [productData, setProductData] = useState([]);
    const [modal_standard4, setmodal_standard4] = useState(false); // View More button Modal of ExposedCredentials
    const [modal_view_user, setmodal_view_user] = useState(false); // View More button Modal of ExposedCredentials
    const [modal_edit_user, setmodal_edit_user] = useState(false)

    // fto show chart data 
    const [orgNum, setOrgNum] = useState(0);
    const [activeOrg, setActiveOrg] = useState(0);
    const [InActiveOrg, setInActiveOrg] = useState(0);

    // values stored of form
    const [fname, setfname] = useState("");
    const [lname, setlname] = useState("");
    const [jobTitle, setjobTitle] = useState("");
    const [email, setemail] = useState("");
    const [phone, setphone] = useState("");

    // change status 
    const [status, setstatus] = useState("");
    const [selectedOptions, setselectedOptions] = useState(null)

    // validation state error 
    const [fnameError, setfnameError] = useState("");
    const [lnameError, setlnameError] = useState("");
    const [emailError, setemailError] = useState("");
    const [phoneError, setphoneError] = useState("");
    const [jobTiteError, setjobTiteError] = useState("");
    const [formUserID, setformUserID] = useState(""); // used to send userID of row to backend
    const [detailBtn, setdetailBtn] = useState(true); // to disable the button on inital


    // const orgID = localStorage.getItem("orgID");
    const token = localStorage.getItem("token");


    const [companyName, setcompanyName] = useState();
    const [companyLogo, setcompanyLogo] = useState();
    // const [empSize, setempSize] = useState();
    const [firstName, setfirstName] = useState();
    const [lastName, setlastName] = useState();
    const [empEmail, setempEmail] = useState();
    const [passwordError, setpasswordError] = useState("");
    const [jobType, setjobType] = useState();
    const [empType, setempType] = useState();
    const [role, setrole] = useState();
    const [empPhone, setempPhone] = useState();
    const [gender, setgender] = useState();
    const [empStatus, setempStatus] = useState();
    const [empPassword, setempPassword] = useState();
    const [UserID, setUserID] = useState();
    // const [gender, setgender] = useState();

    const [status_empSize, setstatus_empSize] = useState("");
    const [statusCountry, setstatusCountry] = useState("");
    const [companyStatus, setcompanyStatus] = useState();


    const [status_vulnerability_status, setstatus_vulnerability_status] = useState("");
    const [selectedOptions_vulnerability_status, setselectedOptions_vulnerability_status] = useState(null);

    const [modal_add_auditee, setmodal_add_auditee] = useState(false); // View More button Modal of ExposedCredentials



    const optionGroup_jobType = [
        { label: "Chief Executive Officer", value: "Chief Executive Officer" },
        { label: "Chief Financial Officer", value: "Chief Financial Officer" },
        { label: "Chief Technology Officer", value: "Chief Technology Officer" },
        { label: "Product Manager", value: "Product Manager" },
        { label: "Chief Architect", value: "Chief Architect" },
        { label: "Software Architect", value: "Software Architect" },
        { label: "Technical Lead", value: "Technical Lead" },
        { label: "Senior Software Engineer", value: "Senior Software Engineer" },
        { label: "Senior Software Developer", value: "Senior Software Developer" },
        { label: "Software Engineer", value: "Software Engineer" },
        { label: "Software Developer", value: "Software Developer" },
    ];


    const optionGroup_role = [
        { label: "Admin", value: "Admin" },
        { label: "Procurement", value: "Procurement" },
        { label: "Technical", value: "Technical" },
        { label: "Finance", value: "Finance" },
    ];


    const optionGroup_gender = [
        { label: "Male", value: "Male" },
        { label: "Female", value: "user" },
    ];
    const optionGroup_empStatus = [
        { label: "Enabled", value: "Enabled" },
        { label: "Disabled", value: "Disabled" },
    ];
    // functions to validate regex password
    function checkPasswordValidation(password_val) {
        if (password_val === " ") {
            setpasswordError("enter password");
        } else if (pass_regx.test(password_val)) {
            setpasswordError("");
        } else if (password_val.length < 3) {
            setpasswordError(` Invalid Password - eg. "Pass@123"`);
        } else {
            setpasswordError(`Invalid Password must contain "0-9  , & @ "`);
        }
    }

    function tog_standard_add_auditee() {
        setmodal_add_auditee(!modal_add_auditee);
        // toggle for view more button 
    }
    // global error popup 
    const errorPopup = (title) => {
        // swal({
        //     title: title,
        //     icon: "warning",
        //     buttons: true,
        //     dangerMode: true,
        // });
        swal("Error!", title, "warning");
    }
    // error checker 
    const checkAddProjectData = () => {
        if (firstName == "" || firstName == undefined) {
            errorPopup("Please Enter First Name")
            return false;
        }
        if (lastName == "" || lastName == undefined) {
            errorPopup("Please enter last Name")
            return false;
        }
        if (empEmail == "" || empEmail == undefined) {
            errorPopup("Please Enter Emp. Email ")
            return false;
        }
        if (empPassword == "" || empPassword == undefined) {
            errorPopup("Please enter employee Password")
            return false;
        }
        if (jobType == "" || jobType == undefined) {
            errorPopup("Please select job designation")
            return false;
        }
        if (role == "" || role == undefined) {
            errorPopup("Please select job role")
            return false;
        }
        if (empPhone == "" || empPhone == undefined) {
            errorPopup("Please Enter employee phone")
            return false;
        }
        if (gender == "" || gender == undefined) {
            errorPopup("Please select gender")
            return false;
        }
        if (empStatus == "" || empStatus == undefined) {
            errorPopup("Please select status")
            return false;
        }
        else {
            addVulnerabilities()
            tog_standard_add_auditee();
        }
    }
    async function addVulnerabilities() {
        var formData = new FormData();
        // formData.append("companyName", companyName);
        // formData.append("companyLogo", companyLogo[0] , "companyLogo.png");
        // // formData.append("status_severity", status_severity);
        // formData.append("empSize", status_empSize);
        // formData.append("companyEmail", companyEmail);
        // formData.append("companyPhone", companyPhone);
        // formData.append("companyWebsite", companyWebsite);
        // formData.append("city", city);
        // formData.append("state", state);
        // formData.append("pincode", pincode);
        // formData.append("country", statusCountry);
        // formData.append("companyStatus", companyStatus);
        // formData.append("otherDetails", otherDetails);
        // formData.append("status_vulnerability_status", status_vulnerability_status);
        // formData.append("vulnerabilityImg1", vulnerabilityImg1[0], "vulnerability_status_img.png");
        console.log(firstName, lastName, empEmail, phone, jobType, empType, role, gender, empPhone, empStatus, organizationId, AuditorID, empPassword)

        const AuditeeID = uniqueIdGenerator()
        try {
            await fetch(`${process.env.REACT_APP_DEFAULTPATH}add-auditee-user`, {
                method: "POST",
                headers: {
                    "content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({
                    firstName, lastName, empEmail, jobType, empType, role, gender, empPhone, empStatus, organizationId, AuditeeID, empPassword,
                    userCheck: localStorage.getItem("AuditorID"),
                    orgID: localStorage.getItem("orgID")
                })
            })
                .then(res => res.json())
                .then(data => {
                    console.log("data, userlist", data)
                    if (data.status) {
                        getuserlist()
                    }
                    else {
                        swal("Error!", data.message, "warning");
                    }
                })
            // window.location.reload()
            // });

        } catch (error) {
            console.log(error)
        }
    }


    async function editUserDetails() {
        try {
            await fetch(`${process.env.REACT_APP_DEFAULTPATH}edit-auditee-user`, {
                method: "POST",
                headers: {
                    "content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({
                    firstName, lastName, jobType, empType, role, gender, empPhone, empStatus, UserID, loginUser,
                    userCheck: localStorage.getItem("AuditorID"),
                    orgID: localStorage.getItem("orgID"),
                })
            }).then(() => {
                getuserlist()
                // window.location.reload()
            });

        } catch (error) {
            console.log(error)
        }
    }

    const optionGroup = [
        { label: "Active", value: "Active" },
        { label: "InActive", value: "InActive" },
    ]
    // Columns of DataTable
    const columns = [
        {
            dataField: "UserID",
            text: "User ID",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.AuditeeID === null) {
                            return <>-</>;
                        } else {
                            return <>
                                <Badge className="me-2 bg-primary p-1 fs-6">
                                    {productData.AuditeeID}
                                </Badge></>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Email",
            text: "Email",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Email === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.Email}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "FirstName",
            text: "First Name",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.FirstName === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.FirstName}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "LastName",
            text: "Last Name",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.LastName === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.LastName}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Phone",
            text: "Phone",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Mobile === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.Mobile}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "JobTitle",
            text: "Job Role",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Designation === null) {
                            return <>-</>;
                        } else {
                            return <>{productData.Designation}</>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Status",
            text: "Status",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {(() => {
                        if (productData.Status === null) {
                            return <>-</>;
                        } else if (productData.Status === "Enabled") {
                            return <> <Badge pill className="badge-soft-success ms-1 fs-6  ">{productData.Status}</Badge></>;
                        } else if (productData.Status === "Disabled") {
                            return <> <Badge pill className="badge-soft-danger ms-1  fs-6 ">{productData.Status}</Badge></>;
                        }
                    })()}
                </>
            ),
        },
        {
            dataField: "Action",
            text: "Action",
            sort: true,
            formatter: (cellContent, productData) => (
                <>
                    {/* {console.log("without cliecekd", productData.Domains)} */}

                    {(() => {
                        if (Userrole === "Admin" || Userrole === "Project Manager") {
                            return (<Button
                                className="btn-sm me-2"
                                color="warning"
                                disabled={Userrole === "Auditor" ? true : false}
                                onClick={() => {
                                    setUserID(productData.AuditeeID)
                                    setfirstName(productData.FirstName)
                                    setlastName(productData.LastName)
                                    setempEmail(productData.Email)
                                    setempEmail(productData.Email)
                                    setjobType(productData.JobType)
                                    setempType(productData.EmployeeType)
                                    setrole(productData.Role)
                                    setempPhone(productData.Mobile)
                                    setgender(productData.Gender)
                                    setempStatus(productData.Status)
                                    tog_standard_edit_user()
                                }}
                            >
                                Edit
                            </Button>)
                        }
                    })()}


                    {(() => {
                        if (Userrole === "Admin") {
                            return (
                                <Button
                                    color="danger"
                                    className="btn-sm me-2"
                                    disabled={Userrole === "Admin" ? false : true}
                                    onClick={() => {

                                        swal({
                                            title: "Are you sure?",
                                            text: "Once deleted, you will not be able to recover this imaginary file!",
                                            icon: "warning",
                                            buttons: true,
                                            dangerMode: true,
                                        })
                                            .then((willDelete) => {
                                                if (willDelete) {
                                                    // console.log(AuditorIDForDeleteUser)
                                                    deleteUser(productData.AuditeeID);
                                                    swal("Poof! Your imaginary file has been deleted!", {
                                                        icon: "success",
                                                    })
                                                        .then((willDelete) => {
                                                            if (willDelete) {
                                                                getuserlist()
                                                                // window.location.reload()
                                                            }
                                                        })
                                                } else {
                                                    swal("Your imaginary file is safe!");
                                                }
                                            });

                                        // console.log("cliecekd", [productData]);
                                        // tog_standard4();
                                        // // setmodalPopupData(productData.UserCount)
                                        // setOrganizaionID(productData.OrganizationID)
                                        // setUserCount(productData.UserCount.length)
                                        // setOrgName(productData.OrganizationName)
                                    }}
                                >

                                    Delete

                                </Button>
                            )
                        }
                    })()}

                    <Button
                        className="btn-sm me-2"
                        color="primary"
                        onClick={() => {
                            console.log(productData)
                            setfirstName(productData.FirstName)
                            setlastName(productData.LastName)
                            setempEmail(productData.Email)
                            setjobType(productData.Designation)
                            setempType(productData.EmployeeType)
                            setrole(productData.Role)
                            setempPhone(productData.Mobile)
                            setgender(productData.Gender)
                            setempStatus(productData.Status)
                            tog_standard_view_user()
                        }}
                    >
                        View
                    </Button>
                </>
            ),
        },
        // {
        //     dataField: "More",
        //     text: "View Details",
        //     sort: true,
        //     formatter: (cellContent, productData) => (

        //         <>
        //             <AvForm>
        //                 {/* {console.log("without cliecekd", productData.Domains)} */}
        //                 <Button
        //                     color="primary"
        //                     onClick={() => {
        //                         // console.log("cliecekd", [productData]);
        //                         tog_standard4();
        //                         setmodalPopupData(productData.UserCount)
        //                     }}
        //                 >
        //                     View More
        //                 </Button>
        //             </AvForm>
        //         </>
        //     ),
        // },
    ];
    // Sorting of Data Table
    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    // Page Options in Data Table 
    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    };
    const { SearchBar } = Search; // Search Bar in Data TAble

    var mainData = [];
    var name_regx = /^[A-Za-z ]{3,20}$/;
    var phone_regx = /^\d{9,20}$/;


    //functions -----------------------------> 
    useEffect(async () => {
        await getUser();

        getuserlist();
    }, []);



    function tog_standard4() {
        setmodal_standard4(!modal_standard4);
        // toggle for view more button 
    }
    function tog_standard_view_user() {
        setmodal_view_user(!modal_view_user);
        // toggle for view more button 
    }
    function tog_standard_edit_user() {
        setmodal_edit_user(!modal_edit_user);
        // toggle for view more button 
    }


    async function deleteUser(ID) {
        // console.log(  "ID" , ID  )
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}delete-auditor-auditee-user`, {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({
                ID,
                userCheck: localStorage.getItem("AuditorID"),
                orgID: localStorage.getItem("orgID")
            })
        })
    }


    const userCheck = localStorage.getItem("AuditorID");
    const [Userrole, setUserrole] = useState()
    // to get userLSit 
    async function getUser(event) {
        // event.preventDefault();
        // console.log("token passes " , userCheck)
        const response = await fetch(`${process.env.REACT_APP_DEFAULTPATH}user-profile`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({
                userCheck: localStorage.getItem("AuditorID"),
                orgID: localStorage.getItem("orgID")
            }),
        });
        const data = await response.json();
        if (data) {
            const newdata = data.user;
            console.log("data retrived", newdata);
            setUserrole(newdata.Role)

            // setphone(newdata.Phone);
            // setjobTitle(newdata.JobTitle);
            // console.log("main profile", newdata.FirstName);
        } else {
            alert("data retreival error");
        }
    }
    // function to load data on Tables
    async function getuserlist(event) {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}get-auditee-user-list`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({
                    organizationId,
                    userCheck: localStorage.getItem("AuditorID"),
                    orgID: localStorage.getItem("orgID")
                }),
            }
        );
        const data = await response.json();
        if (data) {
            console.log("response", data);
            // mainData = await data.data[0].data.entries;
            mainData = await data;
            setProductData(mainData.data);
            setOrgNum(mainData.data.length)
            // console.log(mainData)
            var ACtiveCounter = 0;
            var InactiveCounter = 0
            for (var i = 0; i < mainData.data.length; i++) {
                if (mainData.data[i].Status === "Active") {
                    ACtiveCounter += 1;
                }
                else {
                    InactiveCounter += 1;
                }
            }
            setActiveOrg(ACtiveCounter);
            setInActiveOrg(InactiveCounter)
        } else {
            // alert("data retreival error");
        }
    }

    // function to send data to backend 
    async function updateRow(event) {
        const response = await fetch(
            `${process.env.REACT_APP_DEFAULTPATH}updateuser`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userID: formUserID,
                    fname: fname.charAt(0).toUpperCase() + fname.slice(1),
                    lname: lname.charAt(0).toUpperCase() + lname.slice(1),
                    jobTitle,
                    phone,
                    status
                }),
            }
        );
        const data = await response.json();
        if (data.status) {
        }
        else {
            setemailError("Email already exist")
        }

    }

    // Swwet alert or modal to confirm detials  should modify for perosnal Details 
    function updatePersonalDetails_sweetAlert() {
        swal({
            title: "Are you sure?",
            text: "Once done, you will update your personal details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (updateRow()) {
                        swal("Poof! Your perosnal details has been updated!", {
                            icon: "success",
                            buttons: true
                        }).then((reload) => {
                            if (reload) {
                                window.location.reload(false);
                            }
                        })
                    }
                    else {
                        swal("Reload operation has been aborted!");
                    }
                } else {
                    swal("Operation has been aborted!");
                }
            });
    }

    // Onchange functions ------------------------------------->>>>>>>>
    // functions to validate regex firstName
    function checkFnameValidation(fname_val) {
        if (fname_val === " ") {
            setfnameError("enter first name")
            setdetailBtn(true)
        }
        else if (name_regx.test(fname_val)) {
            setfnameError("")
            setdetailBtn(false)
        }
        else if (fname_val.length < 3) {
            setfnameError("name must contain 3 characters")
            setdetailBtn(true)
        }
        else {
            setfnameError(`name doesn't contain "0-9  , & @ "`)
            setdetailBtn(true)
        }
    }

    // functions to validate regex LastName
    function checklnameValidation(lname_val) {
        if (lname_val === " ") {
            setlnameError("enter first name")
            setdetailBtn(true)
        }
        else if (name_regx.test(lname_val)) {
            setlnameError("")
            setdetailBtn(false)
        }
        else if (lname_val.length < 3) {
            setlnameError("name must contain 3 characters")
            setdetailBtn(true)
        }
        else {
            setlnameError(`name doesn't contain "0-9  , & @ "`)
            setdetailBtn(true)
        }
    }

    // functions to validate regex Phone
    function checkphoneValidation(phone_val) {
        // console.log("phone val", phone_val)
        if (phone_val === " ") {
            setphoneError("enter first name")
            setdetailBtn(true)
        }
        else if (phone_regx.test(phone_val)) {
            setphoneError("")
            setdetailBtn(false)
        }
        else if (phone_val.length < 9) {
            setphoneError("minimum length 8 digit")
            setdetailBtn(true)
        }
        else {
            setphoneError("Invalid credentials - doesn't contain 'a-z , A-Z , @'")
            setdetailBtn(true)
        }
    }

    // functions to validate regex Jobtitle
    function checkjobTitleValidation(jobTitle_val) {
        if (jobTitle_val === " ") {
            setjobTiteError("enter first name")
            setdetailBtn(true)
        }
        else if (name_regx.test(jobTitle_val)) {
            setjobTiteError("")
            setdetailBtn(false)
        }
        else if (jobTitle_val.length < 3) {
            setjobTiteError(" must contain 3 characters")
            setdetailBtn(true)
        }
        else {
            setjobTiteError(`job title doesn't contain "0-9  , & @ "`)
            setdetailBtn(true)
        }
    }

    // form validatioon checkuo end ------------->


    // function to handle change in status dropdown
    function handleSelectOptions(selectedOptions) {
        setselectedOptions(selectedOptions)
        setdetailBtn(false)
    }

    return (
        <React.Fragment>
            <div className="userProfile credentialBreachProfile">
                <MetaTags>
                    <title>Auditee Users | AuditSense</title>
                </MetaTags>
                <Row>
                    <Breadcrumbs
                        title="Dashboard"
                        breadcrumbItem="Auditee User List"
                        breadcrumbFeature="Users"
                    />
                </Row>

                {(() => {
                    if (Userrole != "Auditor") {
                        return (
                            <div className="text-end">
                                <Button className="btn-success" onClick={() => {
                                    // console.log("asdas")
                                    tog_standard_add_auditee()
                                }}><i className="bx bx-user-plus"></i> &nbsp;  Add Users</Button>
                            </div>
                        )
                    }
                })()}

                {/* modal to Edit user  */}
                <Modal
                    isOpen={modal_edit_user}
                    toggle={() => {
                        tog_standard_edit_user();
                    }}
                    className="modal-lg"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Edit User
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setmodal_edit_user(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <div className="d-flex flex-column h-100">

                            <div className='webApplication'>
                                <Row>
                                    <Col lg={12}>

                                        <Row>
                                            <Col lg={12}>
                                                <div>
                                                    {/* <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Form groups</h5> */}
                                                    <Form >

                                                        <Row className='my-2'>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="firstName">First Name: </Label>
                                                                    <Input type="text" className="form-control" id="firstName"
                                                                        value={firstName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setfirstName(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="lastName">Last Name: </Label>
                                                                    <Input type="text" className="form-control" id="lastName"
                                                                        value={lastName}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setlastName(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>

                                                        </Row>


                                                        <Row className='my-2'>

                                                            {/* <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="jobType">Job Type: </Label>
                                                                    <Input type="text" className="form-control" id="jobType"
                                                                        value={jobType}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setjobType(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col> */}
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="role">Employee Type :  </Label>
                                                                    <Select
                                                                        // value={empSize}
                                                                        placeholder={empType}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setjobType(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_jobType}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="role">Role Type:  </Label>
                                                                    <Select
                                                                        // value={empSize}
                                                                        placeholder={role}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setrole(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_role}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className='my-2'>

                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="phone">Phone : </Label>
                                                                    <Input type="phone" className="form-control" id="phone"
                                                                        value={empPhone}
                                                                        onChange={(e) => {
                                                                            // console.log(e.target.value);
                                                                            setempPhone(e.target.value);
                                                                        }} />
                                                                </div>
                                                            </Col>
                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="gender">Gender : </Label>
                                                                    <Select
                                                                        // value={empSize}
                                                                        placeholder={gender}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setgender(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_gender}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="empStatus">Status : </Label>
                                                                    <Select
                                                                        // value={empSize}
                                                                        placeholder={empStatus}
                                                                        onChange={(e) => {
                                                                            // console.log(e.value)
                                                                            setempStatus(e.value);
                                                                            // handleSelectOptions_empSize(e.value);
                                                                            // console.log(selectedOptions_empSize)
                                                                        }}
                                                                        options={optionGroup_empStatus}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className='my-2'>


                                                        </Row>

                                                    </Form>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                tog_standard_edit_user();
                                setmodal_edit_user(false);
                            }}
                            className="btn btn-warning "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                // updatePersonalDetails_sweetAlert()
                                editUserDetails()
                                tog_standard_edit_user()
                            }}
                            className="btn btn-warning "
                        // data-dismiss="modal"
                        >
                            Save
                        </button>
                    </div>
                </Modal>


                {/* modal to view  user  */}
                <Modal
                    isOpen={modal_view_user}
                    toggle={() => {
                        tog_standard_view_user();
                    }}
                    className="modal-lg"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            View User
                        </h5>
                        <button
                            type="button"
                            onClick={() => {
                                setmodal_view_user(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <div className="d-flex flex-column h-100">

                            <div className='webApplication'>
                                <Form >
                                    <Row className='my-2'>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="employeeSize">FirstName *  </Label>
                                                <div className="text-secondary">{firstName}</div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="employeeSize">LastName *  </Label>
                                                <div className="text-secondary">{lastName}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='my-2'>

                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="employeeSize">Email *  </Label>
                                                <div className="text-secondary">{empEmail}</div>
                                            </div>
                                        </Col>

                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="employeeSize"> JobType *  </Label>
                                                <div className="text-secondary">{jobType}</div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='my-2'>

                                        {/* <Col md={6}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="employeeSize">Employee Type *  </Label>
                                                <div className="text-secondary">{empType}</div>
                                            </div>
                                        </Col> */}

                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="employeeSize"> Role *  </Label>
                                                <div className="text-secondary">{role}</div>
                                            </div>
                                        </Col>
                                    </Row>


                                    <Row className='my-3'>

                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="employeeSize">Phone *  </Label>
                                                <div className="text-secondary">{empPhone}</div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="employeeSize">Gender *  </Label>
                                                <div className="text-secondary">{gender}</div>
                                            </div>
                                        </Col>

                                        {/* <Col md={4}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="companyLogo">Status * </Label>
                                                <div>  {(() => {

                                                    if (empStatus === null) {
                                                        return <>-</>;
                                                    } else if (empStatus === "Enable") {
                                                        return <> <Badge pill className="badge-soft-success ms-1  fs-6">{empStatus}</Badge></>;
                                                    } else if (empStatus === "Disable") {
                                                        return <> <Badge pill className="badge-soft-danger ms-1  fs-6">{empStatus}</Badge>

                                                        </>;
                                                    } else if (empStatus === "pending") {
                                                        return <> <Badge pill className="badge-soft-warning ms-1  fs-6">{empStatus}</Badge>

                                                        </>;
                                                    }
                                                })()}</div>
                                            </div>
                                        </Col> */}
                                    </Row>



                                </Form>
                            </div>
                        </div>

                    </div>

                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => {
                                tog_standard_view_user();
                                setmodal_view_user(false);
                            }}
                            className="btn btn-warning "
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </Modal>

                <Row>
                    {/* Modal containing form  */}
                    <Modal
                        isOpen={modal_standard4}
                        toggle={() => {
                            tog_standard4();
                        }}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Edit User Details
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_standard4(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">


                            <div className="d-flex flex-column h-100">
                                <div className="auth-content my-auto">
                                    <AvForm
                                        className="needs-validation custom-form mt-4 pt-2"
                                    // onValidSubmit={registerUser}
                                    >

                                        <div className="name">
                                            <div className="mb-3 firstName">
                                                <label htmlFor="fname">First Name</label>
                                                <Input
                                                    name="firstName"
                                                    className="form-control"
                                                    placeholder="Enter First Name"
                                                    type="name"
                                                    required
                                                    value={fname}

                                                    id="validationTooltip01"
                                                    onChange={(e) => {
                                                        setfname(e.target.value)
                                                        checkFnameValidation(e.target.value)
                                                    }}
                                                />
                                                <span className="validate-span">{fnameError}</span>
                                            </div>
                                            <div className="mb-3 lastName">
                                                <label htmlFor="lname">Last Name</label>
                                                <Input
                                                    name="lastName"
                                                    className="form-control"
                                                    placeholder="Enter Last Name"
                                                    type="name"
                                                    required
                                                    value={lname}
                                                    id="validationTooltip01"
                                                    onChange={(e) => {
                                                        setlname(e.target.value)
                                                        checklnameValidation(e.target.value)
                                                    }}
                                                />
                                                <span className="validate-span">{lnameError}</span>
                                            </div>
                                        </div>
                                        <div className="contact">
                                            <div className="mb-3 contactEmail">
                                                <label htmlFor="email">Email</label>
                                                <Input
                                                    name="email"
                                                    label="Email"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    value={email}
                                                    required
                                                    disabled={true}
                                                    id="validationTooltip01"
                                                // onChange={(event) => {
                                                //     onChangeValidationEmail("email", event.target.value);
                                                // }}
                                                />
                                                <span className="validate-span">{emailError}</span>
                                            </div>
                                            <div className="mb-3 contactPhone">
                                                <label htmlFor="mobile">Mobile</label>
                                                <Input
                                                    name="mobile"
                                                    label="mobile"
                                                    className="form-control"
                                                    placeholder="Enter mobile"
                                                    type="phone"
                                                    value={phone}
                                                    required

                                                    id="validationTooltip01"
                                                    onChange={(e) => {
                                                        setphone(e.target.value)
                                                        checkphoneValidation(e.target.value)
                                                    }}
                                                />
                                                <span className="validate-span">{phoneError}</span>
                                            </div>
                                        </div>

                                        <div className="mb-3 jobName">
                                            <div className="mb-3">
                                                <label htmlFor="choices-single-default" className="form-label font-size-14 ">Status</label>
                                                <Select
                                                    value={selectedOptions}
                                                    onChange={(e) => {
                                                        // console.log(e.value)
                                                        setstatus(e.value)
                                                        handleSelectOptions()
                                                    }}
                                                    options={optionGroup}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 jobName">
                                            <label htmlFor="jobTitle">Job Title</label>
                                            <Input
                                                name="jobTitle"
                                                label="jobTitle"
                                                className="form-control"
                                                type="name"
                                                value={jobTitle}
                                                required
                                                placeholder="Enter Job Title : Software Developer , CTO ...."

                                                id="validationTooltip01"
                                                onChange={(e) => {
                                                    setjobTitle(e.target.value)
                                                    checkjobTitleValidation(e.target.value)
                                                }}
                                            />
                                            <span className="validate-span">{jobTiteError}</span>
                                        </div>
                                        {/* <div className="mb-3 orgName">
                                                <label htmlFor="organization">
                                                    Organization Name
                                                </label>
                                                <Input
                                                    name="organization"
                                                    label="organization"
                                                    className="form-control"
                                                    type="name"
                                                    value={organization}
                                                    required
                                                    placeholder="Enter Organization "

                                                    id="validationTooltip01"
                                                    onChange={(event) => {
                                                        onChangeValidationOrg("org", event.target.value);
                                                    }}
                                                    valid={validation["org"] === true}
                                                    invalid={
                                                        validation["org"] !== true &&
                                                        validation["org"] !== null
                                                    }
                                                />
                                                <span className="validate-span">{OrgError}</span>
                                            </div> */}
                                        {/* <div className="mb-3">
                                                <label htmlFor="pass">Password</label>
                                                <Input
                                                    name="password"
                                                    label="Password"
                                                    className="form-control"
                                                    type="password"
                                                    required
                                                    placeholder="Enter Password"
                                                    id="validationTooltip01"
                                                    onChange={(event) => {
                                                        onChangeValidationPassword("pass", event.target.value);
                                                    }}
                                                    valid={validation["pass"] === true}
                                                    invalid={
                                                        validation["pass"] !== true &&
                                                        validation["pass"] !== null
                                                    }
                                                />
                                                <span className="validate-span">{passError}</span>
                                            </div> */}
                                        {/* <div className="mb-4">
                                                <Input
                                                    id="check"
                                                    name="check"
                                                    label="check"
                                                    type="checkbox"
                                                    onChange={(event) => {
                                                        checkBtnStatus(event.target.value);
                                                    }}
                                                    required
                                                    placeholder="Enter Password"
                                                />
                                                <label htmlFor="check">
                                                    <p className="mb-0">
                                                        I confirm that I have read and that I agree to the {" "}
                                                        <Link to="#" className="text-primary">
                                                            Terms of Service.
                                                        </Link>
                                                    </p>
                                                </label>
                                            </div> */}
                                        {/* <div className="mb-3 w-100 text-end">
                                                <button
                                                    className="btn btn-primary w-50 waves-effect waves-light "
                                                    type="submit"
                                                    disabled={registerBtnStatus}
                                                >
                                                    SaveDetails
                                                    <FeatherIcon icon="arrow-right" size={18} className="ms-2" />
                                                </button>
                                            </div> */}
                                    </AvForm>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    tog_standard4();
                                    setmodal_standard4(false);
                                }}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    updatePersonalDetails_sweetAlert()
                                }}
                                disabled={detailBtn}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Save
                            </button>
                        </div>
                    </Modal>

                    {/* Data Table  */}
                    <Row className="content">
                        <Col
                            lg={4}
                            md={5}
                            className="col-xxl-9 userData credentialData"
                        >
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column">
                                        <div className="  text-center">
                                            <Link
                                                to="/dashboard"
                                                className="d-block auth-logo"
                                            >
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">

                                            <div className="mainTable">
                                                <CardBody>
                                                    <PaginationProvider
                                                        pagination={paginationFactory(
                                                            pageOptions
                                                        )}
                                                    >
                                                        {({
                                                            paginationProps,
                                                            paginationTableProps,
                                                        }) => (
                                                            <ToolkitProvider
                                                                keyField="id"
                                                                columns={columns}
                                                                data={productData}
                                                                search
                                                            >
                                                                {(toolkitProps) => (
                                                                    <React.Fragment>
                                                                        <Row className="mb-2">
                                                                            <Col>
                                                                                <div className="search-box me-2 mb-2 d-flex justify-content-between">
                                                                                    <div className="d-inline">
                                                                                        <SizePerPageDropdownStandalone
                                                                                            {...paginationProps}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="position-relative">
                                                                                        <SearchBar
                                                                                            {...toolkitProps.searchProps}
                                                                                        />
                                                                                        <i className="bx bx-search-alt search-icon" />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row>
                                                                            <Col xl="12">
                                                                                <div className="table-responsive">
                                                                                    <BootstrapTable
                                                                                        keyField={"id"}
                                                                                        responsive
                                                                                        bordered={false}
                                                                                        striped={false}
                                                                                        defaultSorted={
                                                                                            defaultSorted
                                                                                        }
                                                                                        classes={
                                                                                            "table align-middle table-nowrap"
                                                                                        }
                                                                                        headerWrapperClasses={
                                                                                            "thead-light"
                                                                                        }
                                                                                        {...toolkitProps.baseProps}
                                                                                        {...paginationTableProps}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row className="align-items-md-center mt-30">
                                                                            <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                                <PaginationListStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </Col>
                                                                        </Row>

                                                                    </React.Fragment>
                                                                )}
                                                            </ToolkitProvider>
                                                        )}
                                                    </PaginationProvider>
                                                </CardBody>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Row>

                <Row>
                    {/* modal to add user  */}
                    <Modal
                        isOpen={modal_add_auditee}
                        toggle={() => {
                            tog_standard_add_auditee();
                        }}
                        className="modal-xl"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Add User
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_add_auditee(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Form >

                                <Row className='my-2'>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="firstName">First Name: </Label>
                                            <Input type="text" className="form-control" id="firstName"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setfirstName(e.target.value);
                                                }} />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="lastName">Last Name: </Label>
                                            <Input type="text" className="form-control" id="lastName"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setlastName(e.target.value);
                                                }} />
                                        </div>
                                    </Col>

                                </Row>

                                <Row className='my-2'>

                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="email"> Email : </Label>
                                            <Input type="text" className="form-control" id="email"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setempEmail(e.target.value);
                                                }} />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="email"> Password : </Label>
                                            <Input type="text" className="form-control" id="email"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setempPassword(e.target.value);
                                                    checkPasswordValidation(e.target.value)
                                                }} />
                                            <span className="validate-span text-danger">
                                                {passwordError}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='my-2'>

                                    {/* <Col md={6}>
                                                                    <div className="mb-3">
                                                                        <Label className="form-label" htmlFor="jobType">Job Type: </Label>
                                                                        <Input type="text" className="form-control" id="jobType"
                                                                            onChange={(e) => {
                                                                                // console.log(e.target.value);
                                                                            }} />
                                                                    </div>
                                                                </Col> */}
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="role">Job Type :  </Label>
                                            <Select
                                                // value={empSize}
                                                onChange={(e) => {
                                                    // console.log(e.value)
                                                    // setempType(e.value);W
                                                    setjobType(e.value);
                                                    // handleSelectOptions_empSize(e.value);
                                                    // console.log(selectedOptions_empSize)
                                                }}
                                                options={optionGroup_jobType}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>

                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="role">Role Type:  </Label>
                                            <Select
                                                // value={empSize}
                                                onChange={(e) => {
                                                    // console.log(e.value)
                                                    setrole(e.value);
                                                    // handleSelectOptions_empSize(e.value);
                                                    // console.log(selectedOptions_empSize)
                                                }}
                                                options={optionGroup_role}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='my-2'>

                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="phone">Phone : </Label>
                                            <Input type="phone" className="form-control" id="phone"
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    setempPhone(e.target.value);
                                                }} />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="gender">Gender : </Label>
                                            <Select
                                                // value={empSize}
                                                onChange={(e) => {
                                                    // console.log(e.value)
                                                    setgender(e.value);
                                                    // handleSelectOptions_empSize(e.value);
                                                    // console.log(selectedOptions_empSize)
                                                }}
                                                options={optionGroup_gender}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="empStatus">Status : </Label>
                                            <Select
                                                // value={empSize}
                                                onChange={(e) => {
                                                    // console.log(e.value)
                                                    setempStatus(e.value);
                                                    // handleSelectOptions_empSize(e.value);
                                                    // console.log(selectedOptions_empSize)
                                                }}
                                                options={optionGroup_empStatus}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='my-2'>


                                </Row>

                            </Form>

                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    tog_standard_add_auditee();
                                    setmodal_add_auditee(false);
                                }}
                                className="btn btn-warning "
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    // updatePersonalDetails_sweetAlert()
                                    checkAddProjectData()
                                }}
                                className="btn btn-warning "
                            // data-dismiss="modal"
                            >
                                Save
                            </button>
                        </div>
                    </Modal>
                </Row>

            </div>
        </React.Fragment>
    );
};

export default UserList;
